import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { PasswordChangedGuard } from "src/app/guards/password-changed.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";

export const routes: Routes = [
  {
    path: 'b2b-customer/sales-orders',
    loadChildren: () => import('./b2b-customer-sales-orders/b2b-customer-sales-orders.module').then(m => m.B2bCustomerSalesOrdersModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'b2b-customer/sales-orders/create',
    loadChildren: () => import('./b2b-customer-sales-orders/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'b2b-customer/sales-orders/edit/:id',
    loadChildren: () => import('./b2b-customer-sales-orders/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'b2b-customer/sales-orders/:id',
    loadChildren: () => import('./b2b-customer-sales-orders/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
];
