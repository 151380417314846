import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';

export const routes: Routes = [
    /**
     * Start vendors route.
     * This menu moved to : Settings > Master Data.
     */
    {
        path: 'settings/vendors',
        loadChildren: () => import('./vendors/vendors.module').then(m => m.VendorsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'settings/vendors/create',
        loadChildren: () => import('./vendors/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'settings/vendors/edit/:id',
        loadChildren: () => import('./vendors/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    /**
     * End vendor route.
     */

    /**
     * Start tenants route.
     * This menu moved to : Settings > Master Data.
     * Renamed this module from Tenants to Customers
     */
    {
        path: 'settings/tenants',
        loadChildren: () => import('./tenants/tenants.module').then(m => m.TenantsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'settings/tenants/create',
        loadChildren: () => import('./tenants/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'settings/tenants/edit/:id',
        loadChildren: () => import('./tenants/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    /**
     * End tenants route.
     */

    /**
     * Start vendor-invoices route.
     * This menu moved to : Finance > documents.
     * This module name changed from : Vendor Invoice, to: Biils on Finance > documents.
     */
    {
        path: 'finance/documents/vendor-invoices',
        loadChildren: () => import('./vendor-invoices/vendor-invoices.module').then(m => m.VendorInvoicesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'finance/documents/vendor-invoices/create',
        loadChildren: () => import('./vendor-invoices/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'finance/documents/vendor-invoices/:id',
        loadChildren: () => import('./vendor-invoices/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    /**
     * End vendor-invoices route.
     */

    /**
     * Start tenant-invoices route.
     * This menu moved to : Finance > documents.
     * This module name changed from : Tenant Invoice, to: Customer Invoice on Finance > documents.
     */
    {
        path: 'finance/documents/tenant-invoices',
        loadChildren: () => import('./tenant-invoices/tenant-invoices.module').then(m => m.TenantInvoicesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'finance/documents/tenant-invoices/create',
        loadChildren: () => import('./tenant-invoices/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'finance/documents/tenant-invoices/:id',
        loadChildren: () => import('./tenant-invoices/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'finance/documents/tenant-invoices/print-receipt/:id',
        loadChildren: () => import('./tenant-invoices/print-receipt/print-receipt.module').then(m => m.PrintReceiptModule),
        data: {
            layout: 'export'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'finance/documents/tenant-invoices/print-customer-invoice/:id',
        loadChildren: () => import('./tenant-invoices/print-pdf/print-pdf.module').then(m => m.PrintPdfModule),
        data: {
          layout: 'export'
        }
      },
    /**
     * End tenant-invoices route.
     */
];
