import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';

export const routes: Routes = [
    {
        path: 'credit-debit-notes/credit-notes/:type',
        loadChildren: () => import('./credit-notes/credit-notes.module').then(m => m.CreditNotesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'credit-debit-notes/credit-notes/:type/create',
        loadChildren: () => import('./credit-notes/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'credit-debit-notes/credit-notes/:type/view/:id',
        loadChildren: () => import('./credit-notes/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'credit-debit-notes/credit-notes/:type/edit/:id',
        loadChildren: () => import('./credit-notes/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'credit-debit-notes/debit-notes/:type',
        loadChildren: () => import('./debit-notes/debit-notes.module').then(m => m.DebitNotesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'credit-debit-notes/debit-notes/:type/create',
        loadChildren: () => import('./debit-notes/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'credit-debit-notes/debit-notes/:type/view/:id',
        loadChildren: () => import('./debit-notes/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'credit-debit-notes/debit-notes/:type/edit/:id',
        loadChildren: () => import('./debit-notes/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
];
