import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcommerceLinkComponent } from './ecommerce-link.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    EcommerceLinkComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    EcommerceLinkComponent
  ]
})
export class EcommerceLinkModule { }
