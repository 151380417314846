import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';

export const routes: Routes = [
    {
        path: 'hr-platform/employees/:country',
        loadChildren: () => import('./employees/employees.module').then(m => m.EmployeesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'hr-platform/employees/:country/create',
        loadChildren: () => import('./employees/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard
        ]
    },
    {
        path: 'hr-platform/employees/:country/view/:id',
        loadChildren: () => import('./employees/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // components
    {
        path: 'hr-platform/components/:country',
        loadChildren: () => import('./component/component.module').then(m => m.ComponentModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'hr-platform/components/:country/create',
        loadChildren: () => import('./component/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'hr-platform/components/:country/edit/:id',
        loadChildren: () => import('./component/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // component-details
    {
        path: 'hr-platform/component-details/:country',
        loadChildren: () => import('./component-details/component-details.module').then(m => m.ComponentDetailsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'hr-platform/component-details/:country/create',
        loadChildren: () => import('./component-details/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'hr-platform/component-details/:country/edit/:id',
        loadChildren: () => import('./component-details/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // payroll
    {
        path: 'hr-platform/payroll/:country',
        loadChildren: () => import('./payroll/payroll.module').then(m => m.PayrollModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'hr-platform/payroll/:country/months/:user_id',
        loadChildren: () => import('./payroll/months/months.module').then(m => m.MonthsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'hr-platform/payroll/:country/details/:user_id/:date',
        loadChildren: () => import('./payroll/details/details.module').then(m => m.DetailsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'hr-platform/payroll/:country/create/:user_id/:date',
        loadChildren: () => import('./payroll/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'hr-platform/payroll/:country/edit/:id',
        loadChildren: () => import('./payroll/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'hr-platform/payroll/:country/prorate/print',
        loadChildren: () => import('./payroll/print-prorate/print-prorate.module').then(m => m.PrintProrateModule),
        data: {
            layout: 'export'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
      path: 'hr-platform/payroll/:country/payslip/:user_id',
      loadChildren: () => import('./payroll/payslip/payslip.module').then(m => m.PayslipModule),
      data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
    },
    {
      path: 'hr-platform/payroll/:country/payslip/:user_id/:year/:month/print',
      loadChildren: () => import('./payroll/print-payslip/print-payslip.module').then(m => m.PrintPayslipModule),
      data: {
          layout: 'export'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
    },

    // attendance
    {
        path: 'hr-platform/attendance/:country',
        loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendanceModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'hr-platform/expense-claim/:country',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./expense-claim/expense-claim.module').then(m => m.ExpenseClaimModule),
            },
            {
                path: 'create',
                loadChildren: () => import('./expense-claim/create/create.module').then(m => m.CreateModule),
            },
            {
                path: 'edit/:id',
                loadChildren: () => import('./expense-claim/edit/edit.module').then(m => m.EditModule),
            }
        ]
    },

    // leave
    {
        path: 'hr-platform/leave/:country',
        loadChildren: () => import('./leave/leave.module').then(m => m.LeaveModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    }

]
