import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { PasswordChangedGuard } from "src/app/guards/password-changed.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";

export const routes: Routes = [
  {
    path: 'trade/sales-orders',
    loadChildren: () => import('./sales-orders/sales-orders.module').then(m => m.SalesOrdersModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'trade/sales-orders/create',
    loadChildren: () => import('./sales-orders/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'trade/sales-orders/edit/:id',
    loadChildren: () => import('./sales-orders/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'trade/sales-orders/:id',
    loadChildren: () => import('./sales-orders/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'trade/delivery-orders',
    loadChildren: () => import('./delivery-orders/delivery-orders.module').then(m => m.DeliveryOrdersModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'trade/delivery-orders/create',
    loadChildren: () => import('./delivery-orders/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'trade/delivery-orders/edit/:id',
    loadChildren: () => import('./delivery-orders/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'trade/delivery-orders/:id',
    loadChildren: () => import('./delivery-orders/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
];
