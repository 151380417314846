<li class="group relative pb-2.5 text-sm" (click)="this.showSubmenu = !this.showSubmenu">
    <a class="flex items-center text-purple cursor-pointer">
        <div class="w-8 h-8 flex items-center justify-center mr-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
            </svg>                                                                                          
        </div>
        Partners
        <div style="flex: 1"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showSubmenu">
            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
        </svg>                                  
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showSubmenu">
            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
        </svg>                                  
    </a>
</li>
<div class="ml-8 menu__submenu" *ngIf="showSubmenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Partners - View Promoter in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Promoter
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Partners - View EDI in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    EDI
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Partners - View PIM in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    PIM
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Partners - View Vendor Portal in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="openSsoLink('TBRGVendorPortal')">
                    Vendor Portal
                    <!-- <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span> -->
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Partners - View Tenant Portal in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="openSsoLink('TBRGTenantPortal')">
                    Tenant Portal
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
