<li class="group relative pb-2.5 text-sm" (click)="this.showSubmenu = !this.showSubmenu">
    <a class="flex items-center text-purple cursor-pointer">
        <div class="w-8 h-8 flex items-center justify-center mr-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
            </svg>
        </div>
        E-Commerce
        <div style="flex: 1"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showSubmenu">
            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
        </svg>                                  
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showSubmenu">
            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
        </svg>                                  
    </a>
</li>
<div class="ml-8 menu__submenu" *ngIf="showSubmenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('E-Commerce - View Fresh in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Fresh
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('E-Commerce - View Lifestyle / General Merchandise in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Lifestyle / General Merchandise
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('E-Commerce - View B2B (Wholesale) in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    B2B (Wholesale)
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('E-Commerce - View Marketplace in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Marketplace
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
