import { Routes } from '@angular/router';
import { IsLoggedInGuard } from 'src/app/guards/is-logged-in.guard';
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';
import { SetupCompletedGuard } from 'src/app/guards/setup-completed.guard';

export const routes: Routes = [
  {
    path: 'petty-cashes',
    loadChildren: () =>
      import('./petty-cashes.module').then((m) => m.PettyCashesModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'petty-cashes/create',
    loadChildren: () =>
      import('./create/create.module').then((m) => m.CreateModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'petty-cashes/:id',
    loadChildren: () => import('./show/show.module').then((m) => m.ShowModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'petty-cashes/edit/:id',
    loadChildren: () => import('./edit/edit.module').then((m) => m.EditModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'petty-cashes/:id/print',
    loadChildren: () =>
      import('./print/print.module').then((m) => m.PrintModule),
    data: {
      layout: 'export',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
];
