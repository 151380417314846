import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { PasswordChangedGuard } from "src/app/guards/password-changed.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";

export const routes: Routes = [
    {
        path: 'finance/e-learning',
        loadChildren: () => import('./e-learning.module').then(m => m.ELearningModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
        ]
    },
];
