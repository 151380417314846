import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';

export const routes: Routes = [
    {
        path: 'automation/batch-payment/:type',
        loadChildren: () => import('./batch-payment/batch-payment.module').then(m => m.BatchPaymentModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'automation/batch-payment/:type/create',
        loadChildren: () => import('./batch-payment/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'automation/batch-payment/:type/view/:id',
        loadChildren: () => import('./batch-payment/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'automation/batch-payment/:type/edit/:id',
        loadChildren: () => import('./batch-payment/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'automation/payment-proposal',
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        children: [
            {
                path: '',
                loadChildren: () => import('./payment-proposal/payment-proposal.module').then(m => m.PaymentProposalModule),
            },
            {
                path: 'create',
                loadChildren: () => import('./payment-proposal/create/create.module').then(m => m.CreateModule)
            },
            {
                path: 'edit',
                loadChildren: () => import('./payment-proposal/edit/edit.module').then(m => m.EditModule)
            },
            {
              path: 'modify',
              loadChildren: () => import('./payment-proposal/reject-edit/reject-edit.module').then(m => m.RejectEditModule)
            },
            {
                path: 'print-payment-voucher/:paymentProposalId',
                loadChildren: () => import('./payment-proposal/print-payment-voucher/print-payment-voucher.module').then(m => m.PrintPaymentVoucherModule),
                data: {
                    layout: 'export'
                }
            }
        ]
    },
    {
        path: 'automation/payment-proposal/:paymentProposalId/generate-payment-advice',
        loadChildren: () => import('./payment-proposal/payment-advice-pdf/payment-advice-pdf.module').then(m => m.PaymentAdvicePdfModule),
        data: {
          layout: 'export'
        }
    },
    {
        path: 'automation/journal-entry-upload',
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        children: [
            {
                path: '',
                loadChildren: () => import('./journal-entry-upload/journal-entry-upload.module').then(m => m.JournalEntryUploadModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./journal-entry-upload/create/create.module').then(m => m.CreateModule)
            },
        ]
    },
    {
        path: 'automation/purchase-order-upload',
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        children: [
            {
                path: '',
                loadChildren: () => import('./purchase-order-upload/purchase-order-upload.module').then(m => m.PurchaseOrderUploadModule)
            }
        ]
    }
];
