import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';

export const routes: Routes = [
    {
        path: 'settings/cut-off-dates',
        loadChildren: () => import('./cut-off-dates/cut-off-dates.module').then(m => m.CutOffDatesModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },

    {
        path: 'settings/doa-setup',
        loadChildren: () => import('./doa-setup/doa-setup.module').then(m => m.DoaSetupModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },

    {
        path: 'settings/bank-recon-settings',
        loadChildren: () => import('./bank-recon-settings/bank-recon-settings.module').then(m => m.BankReconSettingsModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },

    {
        path: 'settings/gl-accounts',
        loadChildren: () => import('./gl-accounts/gl-accounts.module').then(m => m.GlAccountsModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/gl-accounts/create',
        loadChildren: () => import('./gl-accounts/create/create.module').then(m => m.CreateModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/gl-accounts/view/:id',
        loadChildren: () => import('./gl-accounts/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/categories',
        loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/categories/create',
        loadChildren: () => import('./categories/create/create.module').then(m => m.CreateModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/categories/view/:id',
        loadChildren: () => import('./categories/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/entities',
        loadChildren: () => import('./entities/entities.module').then(m => m.EntitiesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/entities/create',
        loadChildren: () => import('./entities/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/entities/view/:id',
        loadChildren: () => import('./entities/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/user-access-managements',
        loadChildren: () => import('./user-access-management/user-access-management.module').then(m => m.UserAccessManagementModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/user-access-managements/view/:id',
        loadChildren: () => import('./user-access-management/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/role-access-managements',
        loadChildren: () => import('./role-access-management/role-access-management.module').then(m => m.RoleAccessManagementModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/role-access-managements/create',
        loadChildren: () => import('./role-access-management/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/role-access-managements/view/:id',
        loadChildren: () => import('./role-access-management/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/users/create',
        loadChildren: () => import('./users/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/users/view/:id',
        loadChildren: () => import('./users/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/clusters',
        loadChildren: () => import('./clusters/clusters.module').then(m => m.ClustersModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ],
    },
    {
      path: 'settings/locations',
      loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
      data: {
          layout: 'app',
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
      ]
    },
    {
        path: 'settings/locations/create',
        loadChildren: () => import('./locations/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/locations/edit/:id',
        loadChildren: () => import('./locations/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/taxes',
        loadChildren: () => import('./taxes/taxes.module').then(m => m.TaxesModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/taxes/create',
        loadChildren: () => import('./taxes/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/taxes/edit/:id',
        loadChildren: () => import('./taxes/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'settings/currency',
        loadChildren: () => import('./currency/currency.module').then(m => m.CurrencyModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'settings/currency/create',
        loadChildren: () => import('./currency/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'settings/currency/edit/:id',
        loadChildren: () => import('./currency/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'settings/house-banks/house-bank-list',
        loadChildren: () => import('./house-banks/house-banks.module').then(m => m.HouseBanksModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/house-banks/house-bank-list/create',
        loadChildren: () => import('./house-banks/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
            },
            canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
            ]
    },
    {
        path: 'settings/house-banks/house-bank-list/edit/:id',
        loadChildren: () => import('./house-banks/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/house-banks/bank-accounts',
        loadChildren: () => import('./house-banks/bank-accounts/bank-accounts.module').then(m => m.BankAccountsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/house-banks/bank-accounts/create',
        loadChildren: () => import('./house-banks/bank-accounts/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
            },
            canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
            ]
    },
    {
        path: 'settings/house-banks/bank-accounts/edit/:id',
        loadChildren: () => import('./house-banks/bank-accounts/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'settings/house-banks/cheques',
        loadChildren: () => import('./house-banks/cheques/cheques.module').then(m => m.ChequesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/house-banks/cheques/create',
        loadChildren: () => import('./house-banks/cheques/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
            },
            canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
            ]
    },
    {
        path: 'settings/house-banks/cheques/edit/:id',
        loadChildren: () => import('./house-banks/cheques/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'settings/house-banks/bank-accounts',
        loadChildren: () => import('./house-banks/bank-accounts/bank-accounts.module').then(m => m.BankAccountsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/house-banks/bank-accounts/create',
        loadChildren: () => import('./house-banks/bank-accounts/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
            },
            canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
            ]
    },
    {
        path: 'settings/house-banks/bank-accounts/edit/:id',
        loadChildren: () => import('./house-banks/bank-accounts/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'settings/house-banks/cheques',
        loadChildren: () => import('./house-banks/cheques/cheques.module').then(m => m.ChequesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/house-banks/cheques/create',
        loadChildren: () => import('./house-banks/cheques/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
            },
            canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
            ]
    },
    {
        path: 'settings/house-banks/cheques/edit/:id',
        loadChildren: () => import('./house-banks/cheques/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'settings/article-master',
        loadChildren: () => import('./article-master/article-master.module').then(m => m.ArticleMasterModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/article-master/create',
        loadChildren: () => import('./article-master/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
          },
          canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
          ]
    },
    {
        path: 'settings/article-master/edit/:id',
        loadChildren: () => import('./article-master/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/article-master/edit/:id',
        loadChildren: () => import('./article-master/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/office-department',
        loadChildren: () => import('./office-department/office-departments.module').then(m => m.OfficeDepartmentsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/office-department/create',
        loadChildren: () => import('./office-department/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/office-department/edit/:id',
        loadChildren: () => import('./office-department/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-classes',
        loadChildren: () => import('./asset-classes/asset-classes.module').then(m => m.AssetClassesModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-classes/create',
        loadChildren: () => import('./asset-classes/create/create.module').then(m => m.CreateModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-classes/:id',
        loadChildren: () => import('./asset-classes/edit/edit.module').then(m => m.EditModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-categories',
        loadChildren: () => import('./asset-categories/asset-categories.module').then(m => m.AssetCategoriesModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-categories/create',
        loadChildren: () => import('./asset-categories/create/create.module').then(m => m.CreateModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-categories/:id',
        loadChildren: () => import('./asset-categories/edit/edit.module').then(m => m.EditModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/assets',
        loadChildren: () => import('./assets/assets.module').then(m => m.AssetsModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },

    {
        path: 'settings/assets/create',
        loadChildren: () => import('./assets/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'settings/assets/:id',
        loadChildren: () => import('./assets/edit/edit.module').then(m => m.EditModule),
        data: {
        layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-sublines',
        loadChildren: () => import('./asset-sublines/asset-sublines.module').then(m => m.AssetSublinesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-sublines/create',
        loadChildren: () => import('./asset-sublines/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-sublines/:id',
        loadChildren: () => import('./asset-sublines/edit/edit.module').then(m => m.EditModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-sublines/:id/reclass',
        loadChildren: () => import('./asset-sublines/edit/reclass/reclass.module').then(m => m.ReclassModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/asset-sublines/:id/add-asset-flow',
        loadChildren: () => import('./asset-sublines/edit/components/add-asset-flow/add-asset-flow.module').then(m => m.AddAssetFlowModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'settings/profit-centers',
        loadChildren: () => import('./profit-centers/profit-centers.module').then(m => m.ProfitCentersModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/profit-centers/create',
        loadChildren: () => import('./profit-centers/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/profit-centers/view/:id',
        loadChildren: () => import('./profit-centers/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/cost-centers',
        loadChildren: () => import('./cost-centers/cost-centers.module').then(m => m.CostCentersModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/cost-centers/create',
        loadChildren: () => import('./cost-centers/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/cost-centers/view/:id',
        loadChildren: () => import('./cost-centers/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-headers',
        loadChildren: () => import('./reports-builder/profit-and-loss/headers/pnl-headers.module').then(m => m.PnlHeadersModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-headers/create',
        loadChildren: () => import('./reports-builder/profit-and-loss/headers/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-headers/edit/:id',
        loadChildren: () => import('./reports-builder/profit-and-loss/headers/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-sections',
        loadChildren: () => import('./reports-builder/profit-and-loss/sections/pnl-sections.module').then(m => m.PnlSectionsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-sections/create',
        loadChildren: () => import('./reports-builder/profit-and-loss/sections/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-sections/edit/:id',
        loadChildren: () => import('./reports-builder/profit-and-loss/sections/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-subsections',
        loadChildren: () => import('./reports-builder/profit-and-loss/subsections/pnl-subsections.module').then(m => m.PnlSubsectionsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-subsections/create',
        loadChildren: () => import('./reports-builder/profit-and-loss/subsections/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-subsections/edit/:id',
        loadChildren: () => import('./reports-builder/profit-and-loss/subsections/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-categories',
        loadChildren: () => import('./reports-builder/profit-and-loss/categories/pnl-categories.module').then(m => m.PnlCategoriesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-categories/create',
        loadChildren: () => import('./reports-builder/profit-and-loss/categories/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-categories/edit/:id',
        loadChildren: () => import('./reports-builder/profit-and-loss/categories/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-sub-categories',
        loadChildren: () => import('./reports-builder/profit-and-loss/sub-categories/pnl-sub-categories.module').then(m => m.PnlSubCategoriesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-sub-categories/create',
        loadChildren: () => import('./reports-builder/profit-and-loss/sub-categories/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-sub-categories/edit/:id',
        loadChildren: () => import('./reports-builder/profit-and-loss/sub-categories/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-groups',
        loadChildren: () => import('./reports-builder/profit-and-loss/groups/pnl-groups.module').then(m => m.PnlGroupsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-groups/create',
        loadChildren: () => import('./reports-builder/profit-and-loss/groups/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/profit-and-loss/pnl-groups/edit/:id',
        loadChildren: () => import('./reports-builder/profit-and-loss/groups/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-headers',
        loadChildren: () => import('./reports-builder/balance-sheet/headers/bs-headers.module').then(m => m.BSHeadersModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-headers/create',
        loadChildren: () => import('./reports-builder/balance-sheet/headers/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-headers/edit/:id',
        loadChildren: () => import('./reports-builder/balance-sheet/headers/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-sections',
        loadChildren: () => import('./reports-builder/balance-sheet/sections/bs-sections.module').then(m => m.BSSectionsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-sections/create',
        loadChildren: () => import('./reports-builder/balance-sheet/sections/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-sections/edit/:id',
        loadChildren: () => import('./reports-builder/balance-sheet/sections/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-subsections',
        loadChildren: () => import('./reports-builder/balance-sheet/subsections/bs-subsections.module').then(m => m.BSSubsectionsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-subsections/create',
        loadChildren: () => import('./reports-builder/balance-sheet/subsections/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-subsections/edit/:id',
        loadChildren: () => import('./reports-builder/balance-sheet/subsections/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-categories',
        loadChildren: () => import('./reports-builder/balance-sheet/categories/bs-categories.module').then(m => m.BSCategoriesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-categories/create',
        loadChildren: () => import('./reports-builder/balance-sheet/categories/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-categories/edit/:id',
        loadChildren: () => import('./reports-builder/balance-sheet/categories/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-sub-categories',
        loadChildren: () => import('./reports-builder/balance-sheet/sub-categories/bs-sub-categories.module').then(m => m.BSSubCategoriesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-sub-categories/create',
        loadChildren: () => import('./reports-builder/balance-sheet/sub-categories/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-sub-categories/edit/:id',
        loadChildren: () => import('./reports-builder/balance-sheet/sub-categories/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-groups',
        loadChildren: () => import('./reports-builder/balance-sheet/groups/bs-groups.module').then(m => m.BSGroupsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-groups/create',
        loadChildren: () => import('./reports-builder/balance-sheet/groups/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/reports-builder/balance-sheet/bs-groups/edit/:id',
        loadChildren: () => import('./reports-builder/balance-sheet/groups/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/ntp-merchandising-categories',
        loadChildren: () => import('./ntp-merchandising-categories/ntp-merchandising-categories.module').then(m => m.NtpMerchandisingCategoriesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/ntp-merchandising-categories/create',
        loadChildren: () => import('./ntp-merchandising-categories/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
          },
          canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
          ]
    },
    {
        path: 'settings/ntp-merchandising-categories/edit/:id',
        loadChildren: () => import('./ntp-merchandising-categories/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'settings/io-approval-levels',
        loadChildren: () => import('./io-approval-levels/io-approval-levels.module').then(m => m.IOApprovalLevelsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/io-approval-levels/create',
        loadChildren: () => import('./io-approval-levels/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
            },
            canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
            ]
    },
    {
        path: 'settings/io-approval-levels/edit/:id',
        loadChildren: () => import('./io-approval-levels/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/shopify-mapping',
        loadChildren: () => import('./shopify-mapping/shopify-mapping.module').then(m => m.ShopifyMappingModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/shopify-mapping/create',
        loadChildren: () => import('./shopify-mapping/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'settings/shopify-mapping/view/:id',
        loadChildren: () => import('./shopify-mapping/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
];
