import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfficeAutomationLinkComponent } from './office-automation-link.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    OfficeAutomationLinkComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    OfficeAutomationLinkComponent
  ]
})
export class OfficeAutomationLinkModule { }
