import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketingCostBillboardComponent } from './marketing-cost-billboard.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'src/app/components/pagination/pagination.module';

const routes: Routes = [
  {
    path: '',
    component: MarketingCostBillboardComponent
  }
]

@NgModule({
  declarations: [
    MarketingCostBillboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    PaginationModule

  ]
})
export class MarketingCostBillboardModule { }
