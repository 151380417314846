import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';

export const routes: Routes = [
    {
        path: 'asset-management/asset-classes',
        loadChildren: () => import('./asset-classes/asset-classes.module').then(m => m.AssetClassesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'asset-management/asset-classes/create',
        loadChildren: () => import('./asset-classes/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'asset-management/asset-classes/:id',
        loadChildren: () => import('./asset-classes/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'asset-management/categories',
        loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'asset-management/categories/create',
        loadChildren: () => import('./categories/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'asset-management/categories/:id',
        loadChildren: () => import('./categories/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'asset-management/assets',
        loadChildren: () => import('./assets/assets.module').then(m => m.AssetsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'asset-management/assets/create',
        loadChildren: () => import('./assets/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'asset-management/assets/:id',
        loadChildren: () => import('./assets/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
];
