import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TradeLinkComponent } from './trade-link.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    TradeLinkComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    TradeLinkComponent
  ]
})
export class TradeLinkModule { }
