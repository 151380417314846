import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-hr-link',
  templateUrl: './hr-link.component.html',
  styleUrls: ['./hr-link.component.scss']
})
export class HrLinkComponent implements OnInit {

  @Output() clicked = new EventEmitter<string>();

  alert = alert;
  activeRoute = '';
  showSubmenu = false;
  showIndoSubmenu = false;
  showVietSubmenu = false;
  showCoreSubmenu = false;
  showIndoCoreSubmenu = false;
  showVietCoreSubmenu = false;
  showEssSubmenu = false;
  

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    public permissionService: PermissionService
  ) {}

  handleHrPlatformSubmenu() {
    this.showSubmenu = !this.showSubmenu;
    if (! this.showSubmenu) {
      this.showCoreSubmenu = false;
      this.showEssSubmenu = false;
    }
  }
  
  openSsoLink(app: string) {
    this.clicked.emit(`sso:${app}`);
  }

  ngOnInit() {
    this.activeRoute = this.router.url;

    if (this.activeRoute.startsWith('/hr-platform')) {
      this.showSubmenu = true;
      this.showCoreSubmenu = true;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(() => {
      this.activeRoute = this.router.url;
    });
  }

}
