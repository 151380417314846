import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-pos-link',
  templateUrl: './pos-link.component.html',
  styleUrls: ['./pos-link.component.scss']
})
export class PosLinkComponent implements OnInit {

  @Output() clicked = new EventEmitter<string>();

  alert = alert;
  activeRoute = '';
  showSubmenu = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public permissionService: PermissionService
  ) {}

  openPosLink() {
    this.clicked.emit('sso:TBRGPos');
  }

  ngOnInit() {
    this.activeRoute = this.router.url;

    if (this.activeRoute.startsWith('/pos')) {
      this.showSubmenu = true;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(() => {
      this.activeRoute = this.router.url;
    });
  }

}
