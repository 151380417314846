<div class="p-4">
  <div
    class="my-4 rounded-lg w-100 bg-red-100 p-5 text-base text-red-700"
    *ngIf="
      errorMessage !== null &&
      errorMessage !== undefined &&
      errorMessage.length > 0
    "
  >
    {{ errorMessage }}
  </div>

  <!-- Select Year Form -->
  <div class="pt-2" id="form">
    <!-- Form -->
    <div class="flex">
      <div class="grid gap-x-8 gap-y-6 pt-5" style="flex: 1">
        <div class="form-group relative">
          <label for="month" class="form-control-label"> Report Month </label>
          <div class="input-group input-group-merge input-group-alternative">
            <input
              type="month"
              class="form-element"
              [(ngModel)]="selectedMonth"
            />
          </div>
        </div>
      </div>
      <div class="ml-4 contains-button">
        <button
          type="submit"
          class="relative flex items-center justify-center bg-green hover:bg-green-700 text-white px-6 py-1.5 text-base rounded-lg disabled:bg-green-100 w-full"
          [class.opacity-50]="reportLoading"
          [class.cursor-not-allowed]="reportLoading"
          style="margin-top: 50px"
        >
          <span *ngIf="!reportLoading">Generate</span>
          <span *ngIf="reportLoading">Generating...</span>
        </button>
      </div>
    </div>
  </div>
  <!-- ! Select Year Form -->

  <!-- Divider -->
  <div class="py-4">
    <hr />
  </div>
  <!-- ! Divider -->

  <ng-container *ngIf="reportLoading">
    <p>Loading report...</p>
  </ng-container>

  <ng-container *ngIf="!reportLoading">
    <ng-container *ngIf="reports">
      <div class="py-4 sk-sub-header">
        <p class="uppercase" style="font-weight: bold">
          Segmental Reporting - <span>{{ getMonthToDisplay() }}</span>
        </p>
        <div style="clear: both"></div>
      </div>
      <div class="pt-2 pb-8">
        <div style="overflow-x: scroll">
          <div style="position: relative">
            <!-- Data Table -->
            <table>
              <tbody>
                <tr class="tr-hoverable">
                  <td
                    class="text-xs text-center font-bold uppercase"
                    style="min-width: 233px"
                  ></td>
                  <td
                    class="text-xs text-center font-bold uppercase text-white bg-green-600"
                    style="width: 180px"
                  >
                    Retail
                  </td>
                  <td
                    class="text-xs text-center font-bold uppercase text-white bg-blue-500"
                    style="width: 180px"
                  >
                    Franchise
                  </td>
                  <td
                    class="text-xs text-center font-bold uppercase text-white bg-yellow-500"
                    style="width: 180px"
                  >
                    E-Commerce & Marketplace
                  </td>
                  <td
                    class="text-xs text-center font-bold uppercase text-white bg-slate-700"
                    style="width: 180px"
                  >
                    Others
                  </td>
                  <td
                    class="text-xs text-center font-bold uppercase text-white bg-gray-500"
                    style="width: 180px"
                  ></td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-center">Description</td>
                  <td
                    class="text-xs text-center font-bold uppercase text-white bg-green-300"
                  >
                    Subtotal
                  </td>
                  <td
                    class="text-xs text-center font-bold uppercase text-white bg-blue-300"
                  >
                    Subtotal
                  </td>
                  <td
                    class="text-xs text-center font-bold uppercase text-white bg-yellow-200"
                  >
                    Subtotal
                  </td>
                  <td
                    class="text-xs text-center font-bold uppercase text-white bg-slate-400"
                  >
                    HQ
                  </td>
                  <td
                    class="text-xs text-center font-bold uppercase text-white bg-gray-300"
                  >
                    Grand Total
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-xs text-left font-bold bg-white"
                    colspan="7"
                  ></td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold" colspan="6">
                    Revenue
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left">
                    <span class="pl-1">Gross revenue</span>
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.revenues?.gross_revenues
                        ? (reports.retail?.revenues?.gross_revenues | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.revenues?.gross_revenues
                        ? (reports.franchise?.revenues?.gross_revenues | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.revenues?.gross_revenues
                        ? (reports.e_commerce?.revenues?.gross_revenues
                          | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.revenues?.gross_revenues
                        ? (reports.others?.revenues?.gross_revenues | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.revenues?.gross_revenues
                        ? (reports.grand_total?.revenues?.gross_revenues
                          | number)
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left">
                    <span class="pl-1">Gross revenue - srikandi</span>
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.revenues?.srikandi_gross_revenues
                        ? (reports.retail?.revenues?.srikandi_gross_revenues
                          | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.revenues?.srikandi_gross_revenues
                        ? (reports.franchise?.revenues?.srikandi_gross_revenues
                          | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.revenues?.srikandi_gross_revenues
                        ? (reports.e_commerce?.revenues?.srikandi_gross_revenues
                          | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.revenues?.srikandi_gross_revenues
                        ? (reports.others?.revenues?.srikandi_gross_revenues
                          | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.revenues?.srikandi_gross_revenues
                        ? (reports.grand_total?.revenues
                            ?.srikandi_gross_revenues | number)
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left">
                    <span class="pl-1">Discount</span>
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.revenues?.discount
                        ? "(" +
                          (reports.retail?.revenues?.discount | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.revenues?.discount
                        ? "(" +
                          (reports.franchise?.revenues?.discount | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.revenues?.discount
                        ? "(" +
                          (reports.e_commerce?.revenues?.discount | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.revenues?.discount
                        ? "(" +
                          (reports.others?.revenues?.discount | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.revenues?.discount
                        ? "(" +
                          (reports.grand_total?.revenues?.discount | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left">
                    <span class="pl-1">Discount - srikandi</span>
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.revenues?.srikandi_discount
                        ? "(" +
                          (reports.retail?.revenues?.srikandi_discount
                            | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.revenues?.srikandi_discount
                        ? "(" +
                          (reports.franchise?.revenues?.srikandi_discount
                            | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.revenues?.srikandi_discount
                        ? "(" +
                          (reports.e_commerce?.revenues?.srikandi_discount
                            | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.revenues?.srikandi_discount
                        ? "(" +
                          (reports.others?.revenues?.srikandi_discount
                            | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.revenues?.srikandi_discount
                        ? "(" +
                          (reports.grand_total?.revenues?.srikandi_discount
                            | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left">
                    <span class="pl-1">Voucher</span>
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.revenues?.voucher
                        ? "(" +
                          (reports.retail?.revenues?.voucher | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.revenues?.voucher
                        ? "(" +
                          (reports.franchise?.revenues?.voucher | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.revenues?.voucher
                        ? "(" +
                          (reports.e_commerce?.revenues?.voucher | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.revenues?.voucher
                        ? "(" +
                          (reports.others?.revenues?.voucher | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.revenues?.voucher
                        ? "(" +
                          (reports.grand_total?.revenues?.voucher | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left">
                    <span class="pl-1">Rebate</span>
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.revenues?.rebate
                        ? "(" +
                          (reports.retail?.revenues?.rebate | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.revenues?.rebate
                        ? "(" +
                          (reports.franchise?.revenues?.rebate | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.revenues?.rebate
                        ? "(" +
                          (reports.e_commerce?.revenues?.rebate | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.revenues?.rebate
                        ? "(" +
                          (reports.others?.revenues?.rebate | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.revenues?.rebate
                        ? "(" +
                          (reports.grand_total?.revenues?.rebate | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left">
                    <span class="pl-1">Return</span>
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.retail?.revenues?.return
                        ? "(" +
                          (reports.retail?.revenues?.return | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.franchise?.revenues?.return
                        ? "(" +
                          (reports.franchise?.revenues?.return | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.e_commerce?.revenues?.return
                        ? "(" +
                          (reports.e_commerce?.revenues?.return | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.others?.revenues?.return
                        ? "(" +
                          (reports.others?.revenues?.return | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.grand_total?.revenues?.return
                        ? "(" +
                          (reports.grand_total?.revenues?.return | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">
                    <span class="pl-1">Net revenue</span>
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.revenues?.net_revenue
                        ? (reports.retail?.revenues?.net_revenue | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.revenues?.net_revenue
                        ? (reports.franchise?.revenues?.net_revenue | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.revenues?.net_revenue
                        ? (reports.e_commerce?.revenues?.net_revenue | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.revenues?.net_revenue
                        ? (reports.others?.revenues?.net_revenue | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.revenues?.net_revenue
                        ? (reports.grand_total?.revenues?.net_revenue | number)
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left" colspan="6">&nbsp;</td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">COGS</td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.retail?.cogs
                        ? "(" + (reports.retail?.cogs | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.franchise?.cogs
                        ? "(" + (reports.franchise?.cogs | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.e_commerce?.cogs
                        ? "(" + (reports.e_commerce?.cogs | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.others?.cogs
                        ? "(" + (reports.others?.cogs | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.grand_total?.cogs
                        ? "(" + (reports.grand_total?.cogs | number) + ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">Gross Profit</td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.gross_profit
                        ? (reports.retail?.gross_profit | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.gross_profit
                        ? (reports.franchise?.gross_profit | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.gross_profit
                        ? (reports.e_commerce?.gross_profit | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.gross_profit
                        ? (reports.others?.gross_profit | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.gross_profit
                        ? (reports.grand_total?.gross_profit | number)
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left" colspan="6">&nbsp;</td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">Other Income</td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.other_income
                        ? (reports.retail?.other_income | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.other_income
                        ? (reports.franchise?.other_income | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.other_income
                        ? (reports.e_commerce?.other_income | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.other_income
                        ? (reports.others?.other_income | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.other_income
                        ? (reports.grand_total?.other_income | number)
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">
                    <span class="opacity-100">OPEX:</span> Employment Cost
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.opex?.employment_cost
                        ? "(" +
                          (reports.retail?.opex?.employment_cost | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.opex?.employment_cost
                        ? "(" +
                          (reports.franchise?.opex?.employment_cost | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.opex?.employment_cost
                        ? "(" +
                          (reports.e_commerce?.opex?.employment_cost | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.opex?.employment_cost
                        ? "(" +
                          (reports.others?.opex?.employment_cost | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.opex?.employment_cost
                        ? "(" +
                          (reports.grand_total?.opex?.employment_cost
                            | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">
                    <span class="opacity-0">OPEX:</span> Marketing
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.opex?.marketing
                        ? "(" + (reports.retail?.opex?.marketing | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.opex?.marketing
                        ? "(" +
                          (reports.franchise?.opex?.marketing | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.opex?.marketing
                        ? "(" +
                          (reports.e_commerce?.opex?.marketing | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.opex?.marketing
                        ? "(" + (reports.others?.opex?.marketing | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.opex?.marketing
                        ? "(" +
                          (reports.grand_total?.opex?.marketing | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">
                    <span class="opacity-0">OPEX:</span> Rental
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.opex?.rental
                        ? "(" + (reports.retail?.opex?.rental | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.opex?.rental
                        ? "(" + (reports.franchise?.opex?.rental | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.opex?.rental
                        ? "(" +
                          (reports.e_commerce?.opex?.rental | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.opex?.rental
                        ? "(" + (reports.others?.opex?.rental | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.opex?.rental
                        ? "(" +
                          (reports.grand_total?.opex?.rental | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">
                    <span class="opacity-0">OPEX:</span> Others
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.retail?.opex?.others
                        ? "(" + (reports.retail?.opex?.others | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.franchise?.opex?.others
                        ? "(" + (reports.franchise?.opex?.others | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.e_commerce?.opex?.others
                        ? "(" +
                          (reports.e_commerce?.opex?.others | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.others?.opex?.others
                        ? "(" + (reports.others?.opex?.others | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.grand_total?.opex?.others
                        ? "(" +
                          (reports.grand_total?.opex?.others | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold"></td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.retail?.opex?.total
                        ? "(" + (reports.retail?.opex?.total | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.franchise?.opex?.total
                        ? "(" + (reports.franchise?.opex?.total | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.e_commerce?.opex?.total
                        ? "(" + (reports.e_commerce?.opex?.total | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.others?.opex?.total
                        ? "(" + (reports.others?.opex?.total | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.grand_total?.opex?.total
                        ? "(" +
                          (reports.grand_total?.opex?.total | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">Pretax Profit</td>
                  <td class="text-xs text-right">
                    {{
                      reports.retail?.pretax_profit
                        ? (reports.retail?.pretax_profit | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.franchise?.pretax_profit
                        ? (reports.franchise?.pretax_profit | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.e_commerce?.pretax_profit
                        ? (reports.e_commerce?.pretax_profit | number)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.others?.pretax_profit
                        ? "(" + (reports.others?.pretax_profit | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right">
                    {{
                      reports.grand_total?.pretax_profit
                        ? (reports.grand_total?.pretax_profit | number)
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">Taxation</td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.retail?.taxation
                        ? "(" + (reports.retail?.taxation | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.franchise?.taxation
                        ? "(" + (reports.franchise?.taxation | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.e_commerce?.taxation
                        ? "(" + (reports.e_commerce?.taxation | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.others?.taxation
                        ? "(" + (reports.others?.taxation | number) + ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.grand_total?.taxation
                        ? "(" + (reports.grand_total?.taxation | number) + ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">Profit after Tax</td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.retail?.profit_after_tax
                        ? "(" +
                          (reports.retail?.profit_after_tax | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.franchise?.profit_after_tax
                        ? "(" +
                          (reports.franchise?.profit_after_tax | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.e_commerce?.profit_after_tax
                        ? "(" +
                          (reports.e_commerce?.profit_after_tax | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.others?.profit_after_tax
                        ? "(" +
                          (reports.others?.profit_after_tax | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                  <td class="text-xs text-right border-b border-black">
                    {{
                      reports.grand_total?.profit_after_tax
                        ? "(" +
                          (reports.grand_total?.profit_after_tax | number) +
                          ")"
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="padding-bottom: 1px"
                    class="py-0 text-xs text-left font-bold"
                  ></td>
                  <td
                    style="padding-bottom: 1px"
                    class="py-0 text-xs text-left font-bold"
                  ></td>
                  <td
                    colspan="5"
                    style="padding-bottom: 1px"
                    class="py-0 text-xs text-right border-b border-black"
                  ></td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left" colspan="6">&nbsp;</td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">GP %</td>
                  <td class="text-xs text-center">
                    {{
                      reports.retail?.gp_percent
                        ? reports.retail?.gp_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.franchise?.gp_percent
                        ? reports.franchise?.gp_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.e_commerce?.gp_percent
                        ? reports.e_commerce?.gp_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.others?.gp_percent
                        ? reports.others?.gp_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.grand_total?.gp_percent
                        ? reports.grand_total?.gp_percent + "%"
                        : ""
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">PBT %</td>
                  <td class="text-xs text-center">
                    {{
                      reports.retail?.pbt_percent
                        ? reports.retail?.pbt_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.franchise?.pbt_percent
                        ? reports.franchise?.pbt_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.e_commerce?.pbt_percent
                        ? reports.e_commerce?.pbt_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.others?.pbt_percent
                        ? reports.others?.pbt_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.grand_total?.pbt_percent
                        ? reports.grand_total?.pbt_percent + "%"
                        : ""
                    }}
                  </td>
                </tr>
                <tr class="tr-hoverable">
                  <td class="text-xs text-left font-bold">PAT %</td>
                  <td class="text-xs text-center">
                    {{
                      reports.retail?.pat_percent
                        ? reports.retail?.pat_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.franchise?.pat_percent
                        ? reports.franchise?.pat_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.e_commerce?.pat_percent
                        ? reports.e_commerce?.pat_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.others?.pat_percent
                        ? reports.others?.pat_percent + "%"
                        : ""
                    }}
                  </td>
                  <td class="text-xs text-center">
                    {{
                      reports.grand_total?.pat_percent
                        ? reports.grand_total?.pat_percent + "%"
                        : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- ! Data Table -->

            <!-- Colors Table -->
            <table
              style="
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
              "
            >
              <tr>
                <td style="min-width: 250px" class="bg-transparent"></td>
                <td style="width: 180px" class="bg-green-100"></td>
                <td style="width: 180px" class="bg-blue-100"></td>
                <td style="width: 180px" class="bg-yellow-50"></td>
                <td style="width: 180px" class="bg-slate-50"></td>
                <td style="width: 180px" class="bg-gray-100"></td>
              </tr>
            </table>
            <!-- ! Colors Table -->
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
