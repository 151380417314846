import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';

export const routes: Routes = [
    {
        path: 'production/internal-assembly',
        loadChildren: () => import('./internal-assembly/internal-assembly.module').then(m => m.InternalAssemblyModule),
        data: {
          layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'production/internal-assembly/create',
        loadChildren: () => import('./internal-assembly/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'production/internal-assembly/conversion',
        loadChildren: () => import('./internal-assembly/create/conversion/conversion.module').then(m => m.ConversionModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'production/internal-assembly/show/:id',
        loadChildren: () => import('./internal-assembly/show/show.module').then(m => m.ShowModule),
        data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
    },

    {
        path: 'production/job-orders',
        loadChildren: () => import('./job-orders/job-orders.module').then(m => m.JobOrdersModule),
        data: {
          layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'production/job-orders/create',
        loadChildren: () => import('./job-orders/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'production/job-orders/conversion',
        loadChildren: () => import('./job-orders/create/conversion/conversion.module').then(m => m.ConversionModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'production/job-orders/show/:id',
        loadChildren: () => import('./job-orders/show/show.module').then(m => m.ShowModule),
        data: {
          layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
];
