<li class="group relative pb-2.5 text-sm" (click)="handleTradeSubmenu()">
    <a class="flex items-center text-purple cursor-pointer">
        <div class="w-8 h-8 flex items-center justify-center mr-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
            </svg>
        </div>
        Trade
        <div style="flex: 1"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showTradeSubmenu">
            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showTradeSubmenu">
            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
        </svg>
    </a>
</li>
<ng-container *ngIf="permissionService.hasPermission('Trade - View Sales Orders in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showTradeSubmenu">
        <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/trade/sales-orders'" [class.font-semibold]="activeRoute.startsWith('/trade/sales-orders')">
            Sales Orders
            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;" [class.opacity-100]="activeRoute.startsWith('/trade/sales-orders')"></span>
        </a>
    </li>
</ng-container>
<ng-container *ngIf="permissionService.hasPermission('Trade - View Delivery Orders in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showTradeSubmenu">
        <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/trade/delivery-orders'" [class.font-semibold]="activeRoute.startsWith('/trade/delivery-orders')">
            Delivery Orders
            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;" [class.opacity-100]="activeRoute.startsWith('/trade/delivery-orders')"></span>
        </a>
    </li>
</ng-container>
