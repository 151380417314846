import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';

export const routes: Routes = [
    {
        path: 'cost-control/cost-centers/categories',
        loadChildren: () => import('./cost-centers/categories/categories.module').then(m => m.CategoriesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'cost-control/cost-centers/categories/create',
        loadChildren: () => import('./cost-centers/categories/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'cost-control/cost-centers/categories/view/:id',
        loadChildren: () => import('./cost-centers/categories/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'cost-control/profit-centers/categories',
        loadChildren: () => import('./profit-centers/categories/categories.module').then(m => m.CategoriesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'cost-control/profit-centers/categories/create',
        loadChildren: () => import('./profit-centers/categories/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'cost-control/profit-centers/categories/view/:id',
        loadChildren: () => import('./profit-centers/categories/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'cost-control/profit-cost-analytics',
        loadChildren: () => import('./profit-cost-analytics/profit-cost-analytics.module').then(m => m.ProfitCostAnalyticsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    }
];
