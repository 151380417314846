<li class="group relative pb-2.5 text-sm" (click)="handleProductionSubmenu()">
    <a class="flex items-center text-purple cursor-pointer">
        <div class="w-8 h-8 flex items-center justify-center mr-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
            </svg>              
        </div>
        Production
        <div style="flex: 1"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showProductionSubmenu">
            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showProductionSubmenu">
            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
        </svg>
    </a>
</li>
<ng-container *ngIf="permissionService.hasPermission('Production - Can See Job Orders in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showProductionSubmenu">
        <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/production/job-orders'" [class.font-semibold]="activeRoute.startsWith('/production/job-orders')">
            Job Orders
            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;" [class.opacity-100]="activeRoute.startsWith('/production/job-orders')"></span>
        </a>
    </li>
</ng-container>
<ng-container *ngIf="permissionService.hasPermission('Production - Can See Internal Assembly in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showProductionSubmenu">
        <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/production/internal-assembly'" [class.font-semibold]="activeRoute.startsWith('/production/internal-assembly')">
            Internal Assembly
            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;" [class.opacity-100]="activeRoute.startsWith('/production/internal-assembly')"></span>
        </a>
    </li>
</ng-container>
<!-- <ng-container *ngIf="permissionService.hasPermission('Production - Can See Ad-Hoc Assembly in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showProductionSubmenu">
        <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/trade/sales-orders'" [class.font-semibold]="activeRoute.startsWith('/trade/sales-orders')">
            Ad-Hoc Assembly
            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;" [class.opacity-100]="activeRoute.startsWith('/trade/sales-orders')"></span>
        </a>
    </li>
</ng-container> -->
