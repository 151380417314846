import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';
import { PurchaseOrderComponent } from "./purchase-order/purchase-order.component";

export const routes: Routes = [
    {
        path: 'purchase-order',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
            },
            {
                path: 'create',
                loadChildren: () => import('./purchase-order/create/create.module').then(m => m.CreateModule),
            },
            {
                path: 'batch-upload',
                loadChildren: () => import('./purchase-order/batch-upload/batch-upload.module').then(m => m.BatchUploadModule),
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./purchase-order/view/view.module').then(m => m.ViewModule),
            },
            {
                path: 'edit/:id',
                loadChildren: () => import('./purchase-order/edit/edit.module').then(m => m.EditModule),
            },
            {
                path: 'enquiry',
                loadChildren: () => import('./purchase-order/enquiry/enquiry.module').then(m => m.EnquiryModule)
            }
        ]
    },
    {
        path: 'purchase-request',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./purchase-request-v2/purchase-request-v2.module').then(m => m.PurchaseRequestV2Module),
            },
            {
                path: 'create',
                loadChildren: () => import('./purchase-request-v2/create/create.module').then(m => m.CreateModule),
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./purchase-request-v2/view/view.module').then(m => m.ViewModule),
            },
            {
                path: 'edit/:id',
                loadChildren: () => import('./purchase-request-v2/edit/edit.module').then(m => m.EditModule),
            },
            // {
            //     path: '',
            //     loadChildren: () => import('./purchase-request/purchase-request.module').then(m => m.PurchaseRequestModule),
            // },
            // {
            //     path: 'create',
            //     loadChildren: () => import('./purchase-request/create/create.module').then(m => m.CreateModule),
            // },
            // {
            //     path: 'view/:id',
            //     loadChildren: () => import('./purchase-request/view/view.module').then(m => m.ViewModule),
            // },
            // {
            //     path: 'edit/:id',
            //     loadChildren: () => import('./purchase-request/edit/edit.module').then(m => m.EditModule),
            // },
        ]
    },
    {
        path: 'transfer-order',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./transfer-order/transfer-order.module').then(m => m.TransferOrderModule),
            },
            {
                path: 'create',
                loadChildren: () => import('./transfer-order/create/create.module').then(m => m.CreateModule),
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./transfer-order/view/view.module').then(m => m.ViewModule),
            }
        ]
    },
    {
        path: 'transfer-order',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./transfer-order/transfer-order.module').then(m => m.TransferOrderModule),
            },
            {
                path: 'create',
                loadChildren: () => import('./transfer-order/create/create.module').then(m => m.CreateModule),
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./transfer-order/view/view.module').then(m => m.ViewModule),
            }
        ]
    },
    {
        path: 'receiving',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./receiving/receiving.module').then(m => m.ReceivingModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./receiving/create/create.module').then(m => m.CreateModule),
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./receiving/view/view.module').then(m => m.ViewModule)
            },
            {
                path: 'enquiry',
                loadChildren: () => import('./receiving/enquiry/enquiry.module').then(m => m.EnquiryModule)
            }
        ]
    },

    {
        path: 'receiving-draft',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./receiving-draft/receiving-draft.module').then(m => m.ReceivingDraftModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./receiving-draft/view/view.module').then(m => m.ViewModule)
            },
            {
                path: 'edit/:id',
                loadChildren: () => import('./receiving-draft/edit/edit.module').then(m => m.EditModule)
            }
        ]
    },

    {
        path: 'receiving-draft',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./receiving-draft/receiving-draft.module').then(m => m.ReceivingDraftModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./receiving-draft/view/view.module').then(m => m.ViewModule)
            },
            {
                path: 'edit/:id',
                loadChildren: () => import('./receiving-draft/edit/edit.module').then(m => m.EditModule)
            }
        ]
    },

    {
        path: 'returns',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./returns/returns.module').then(m => m.ReturnsModule)
            }
        ]
    },
    {
        path: 'refunds',
        loadChildren: () => import('./refunds/refunds.module').then(m => m.RefundsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'refunds/create',
        loadChildren: () => import('./refunds/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'refunds/show/:id',
        loadChildren: () => import('./refunds/show/show.module').then(m => m.ShowModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
      path: 'invoices',
      loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule),
      data: {
        layout: 'app'
      },
      canActivate: [
        IsLoggedInGuard,
        SetupCompletedGuard,
        PasswordChangedGuard,
      ],
    }


]

