import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-settings-link',
  templateUrl: './settings-link.component.html',
  styleUrls: ['./settings-link.component.scss']
})
export class SettingsLinkComponent implements OnInit {

  alert = alert;
  showNonTradeAssetSubMenu = false;
  showFinanceStatementsSubMenu = false;
  showProfitAndLossSubMenu = false;
  showBalanceSheetSubMenu = false;
  showSettingsSubmenu = false;
  showMasterDataSubMenu = false;
  showIntegrationsSubMenu = false;
  showMerchandisingHierarchySubmenu = false;
  showMerchandisingRawMaterialsHierarchySubmenu = false;
  showMerchandisingSemiFinishedGoodsHierarchySubmenu = false;
  showMerchandisingFinishedGoodsHierarchySubmenu = false;
  showSystemSubmenu = false;
  showFinanceSubmenu = false;
  showMerchandisingSubmenu = false;
  showNonTradeProcurmentSubMenu = false;
  showHouseBanksSubMenu = false;
  activeRoute = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public permissionService: PermissionService
  ) {}

  handleSettingsSubmenu() {
    this.showSettingsSubmenu = !this.showSettingsSubmenu;
  }

  logout() {
    if (confirm ('Are you sure you want to log out?')) {
      window.localStorage.removeItem(environment.api_token_identifier);
      this.permissionService.removePermissions();
      this.router.navigateByUrl('/login');
    }
  }

  showMasterDataSubmenus() {
    if (this.activeRoute.startsWith('/settings/users') || 
        this.activeRoute.startsWith('/settings/user-access-managements') || 
        this.activeRoute.startsWith('/settings/locations') ||
        this.activeRoute.startsWith('/settings/clusters') ||
        this.activeRoute.startsWith('/settings/storage-locations') ||
        this.activeRoute.startsWith('/settings/vendors') ||
        this.activeRoute.startsWith('/settings/tenants') ||
        this.activeRoute.startsWith('/settings/currency') ||
        this.activeRoute.startsWith('/settings/gl-accounts') ||
        this.activeRoute.startsWith('/settings/article-master') ||
        this.activeRoute.startsWith('/settings/user-access-managements') ||
        this.activeRoute.startsWith('/settings/asset-classes') ||
        this.activeRoute.startsWith('/settings/asset-categories') ||
        this.activeRoute.startsWith('/settings/assets') ||
        this.activeRoute.startsWith('/settings/asset-sublines') ||
        this.activeRoute.startsWith('/settings/currency') ||
        this.activeRoute.startsWith('/settings/gl-accounts') ||
        this.activeRoute.startsWith('/settings/office-department') ||
        this.activeRoute.startsWith('/settings/profit-centers') ||
        this.activeRoute.startsWith('/settings/cost-centers') ||
        this.activeRoute.startsWith('/settings/reports-builder') ||
        this.activeRoute.startsWith('/settings/cut-off-dates') ||
        this.activeRoute.startsWith('/settings/entities') ||
        this.activeRoute.startsWith('/settings/io-approval-levels') ||
        this.activeRoute.startsWith('/merchandising/raw-material') ||
        this.activeRoute.startsWith('/merchandising/semi-finished-good') ||
        this.activeRoute.startsWith('/merchandising/finished-good') ||
        this.activeRoute.startsWith('/merchandising/mdz-assortment-masters') ||
        this.activeRoute.startsWith('/merchandising/mdz-brand-masters') ||
        this.activeRoute.startsWith('/merchandising/mdz-brand-category') ||
        this.activeRoute.startsWith('/merchandising/bom-recipes') ||
        this.activeRoute.startsWith('/settings/cut-off-dates') ||
        this.activeRoute.startsWith('/settings/ntp-merchandising-categories') ||
        this.activeRoute.startsWith('/settings/article-master') ||
        this.activeRoute.startsWith('/settings/house-banks') ||
        this.activeRoute.startsWith('/settings/merchandising/stock-adjustments/stock-adjustment-reason') ||
        this.activeRoute.startsWith('/settings/doa-setup')
    ) {
      this.showSettingsSubmenu = true;
      this.showMasterDataSubMenu = true;

      if(this.activeRoute.startsWith('/merchandising/product-hierarchy')
      ) {
        this.showMerchandisingSubmenu = true;
        this.showMerchandisingHierarchySubmenu = true;
      }

      if(this.activeRoute.startsWith('/merchandising/raw-material')
      ) {
        this.showMerchandisingSubmenu = true;
        this.showMerchandisingRawMaterialsHierarchySubmenu = true;
      }

      if(this.activeRoute.startsWith('/merchandising/semi-finished-good')
      ) {
        this.showMerchandisingSubmenu = true;
        this.showMerchandisingSemiFinishedGoodsHierarchySubmenu = true;
      }

      if(this.activeRoute.startsWith('/merchandising/finished-good')
      ) {
        this.showMerchandisingSubmenu = true;
        this.showMerchandisingFinishedGoodsHierarchySubmenu = true;
      }

      if (this.activeRoute.startsWith('/merchandising/mdz-assortment-masters') ||
        this.activeRoute.startsWith('/merchandising/mdz-brand-masters') ||
        this.activeRoute.startsWith('/merchandising/mdz-brand-category') ||
        this.activeRoute.startsWith('/merchandising/bom-recipes') ||
        this.activeRoute.startsWith('/settings/merchandising/stock-adjustments/stock-adjustment-reason')) {
        this.showMerchandisingSubmenu = true;
      }

      if(this.activeRoute.startsWith('/settings/locations') ||
        this.activeRoute.startsWith('/settings/clusters') ||
        this.activeRoute.startsWith('/settings/vendors') ||
        this.activeRoute.startsWith('/settings/tenants') ||
        this.activeRoute.startsWith('/settings/currency') ||
        this.activeRoute.startsWith('/settings/users') ||
        this.activeRoute.startsWith('/settings/user-access-managements') ||
        this.activeRoute.startsWith('/settings/doa-setup')
      ) {
        this.showSystemSubmenu = true;
      }

      if(this.activeRoute.startsWith('/settings/cut-off-dates') ||
        this.activeRoute.startsWith('/settings/gl-accounts') ||
        this.activeRoute.startsWith('/settings/profit-centers') ||
        this.activeRoute.startsWith('/settings/cost-centers') ||
        this.activeRoute.startsWith('/settings/ntp-merchandising-categories') ||
        this.activeRoute.startsWith('/settings/article-master') ||
        this.activeRoute.startsWith('/settings/office-department') ||
        this.activeRoute.startsWith('/settings/io-approval-levels')
      ) {
        this.showFinanceSubmenu = true;
      }

      if (this.activeRoute.startsWith('/settings/asset')) {
        this.showFinanceSubmenu = true;
        this.showNonTradeAssetSubMenu = true;
      }
      
      if (this.activeRoute.startsWith('/settings/ntp-merchandising-categories') ||
        this.activeRoute.startsWith('/settings/article-master')) {
        this.showNonTradeProcurmentSubMenu = true;
      }

      if(this.activeRoute.startsWith('/settings/reports-builder')) {
        this.showFinanceSubmenu = true;
        this.showFinanceStatementsSubMenu = true;
      
        if(this.activeRoute.startsWith('/settings/reports-builder/balance-sheet')) {
            this.showBalanceSheetSubMenu = true;
        }

        if(this.activeRoute.startsWith('/settings/reports-builder/profit-and-loss')) {
          this.showProfitAndLossSubMenu = true;
        }
      }

      if (this.activeRoute.startsWith('/settings/storage-locations')
      ) {
        this.showMerchandisingSubmenu = true;
      }

      if (this.activeRoute.startsWith('/settings/asset-classes') ||
        this.activeRoute.startsWith('/settings/asset-categories') ||
        this.activeRoute.startsWith('/settings/assets') ||
        this.activeRoute.startsWith('/settings/asset-sublines')
      ) {
        this.showMerchandisingSubmenu = true;
      }

      if(this.activeRoute.startsWith('/settings/house-banks')) {
        this.showFinanceSubmenu = true;
        this.showHouseBanksSubMenu = true;
      }

    }
  }

  ngOnInit() {
    this.activeRoute = this.router.url;
    this.showMasterDataSubmenus();

    if (this.activeRoute.startsWith('/settings/bank-recon-settings')) {
      this.showIntegrationsSubMenu = true;
    }

    if (this.activeRoute.startsWith('/settings')) {
      this.showSettingsSubmenu = true;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(() => {
      this.activeRoute = this.router.url;
    });
  }

}
