<li class="group relative pb-2.5 text-sm" (click)="handleHrPlatformSubmenu()">
    <a class="flex items-center text-purple cursor-pointer">
        <div class="w-8 h-8 flex items-center justify-center mr-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>                                                                                                          
        </div>
        HR Platform
        <div style="flex: 1"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showSubmenu">
            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
        </svg>                                  
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showSubmenu">
            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
        </svg>                                  
    </a>
</li>

<ng-container>
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" (click)="this.showIndoSubmenu = !this.showIndoSubmenu">
        <a class="flex items-center text-purple cursor-pointer">
            Indonesia
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showIndoSubmenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>                                  
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showIndoSubmenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>                                  
        </a>
    </li>
</ng-container>


<div class="ml-8 menu__submenu" *ngIf="showIndoSubmenu">
    <ng-container *ngIf="permissionService.hasPermission('HR Platform - View Core HR in Sidebar')">
        <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showIndoSubmenu" (click)="this.showIndoCoreSubmenu = !this.showIndoCoreSubmenu">
            <a class="flex items-center text-purple cursor-pointer">
                Core HR
                <div style="flex: 1"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showIndoCoreSubmenu">
                    <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                </svg>                                  
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showIndoCoreSubmenu">
                    <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                </svg>                                  
            </a>
        </li>
    </ng-container>
    
    <div class="ml-8 menu__submenu" *ngIf="showIndoCoreSubmenu">
        <ul class="relative pb-2.5">
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Employees in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/employees/indonesia'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/employees/indonesia')">
                        Employees
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/employees/indonesia')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Components in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/components/indonesia'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/components/indonesia')">
                        Component Category
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/components/indonesia')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Component Details in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/component-details/indonesia'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/component-details/indonesia')">
                        Component
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/component-details/indonesia')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Payroll in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/payroll/indonesia'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/payroll/indonesia')">
                        Payroll
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/payroll/indonesia')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Attendance in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/attendance/indonesia'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/attendance/indonesia')">
                        Attendance
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/hr-platform/attendance/indonesia')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Leave in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/leave/indonesia'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/leave/indonesia')">
                        Leave
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/leave/indonesia')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Expense Claim in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/expense-claim/indonesia'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/expense-claim/indonesia')">
                        Expense Claim
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/expense-claim/indonesia')"
                        ></span>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>

<ng-container>
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" (click)="this.showVietSubmenu = !this.showVietSubmenu">
        <a class="flex items-center text-purple cursor-pointer">
            Vietnam
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showVietSubmenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>                                  
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showVietSubmenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>                                  
        </a>
    </li>
</ng-container>


<div class="ml-8 menu__submenu" *ngIf="showVietSubmenu">
    <ng-container *ngIf="permissionService.hasPermission('HR Platform - View Core HR in Sidebar')">
        <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showVietSubmenu" (click)="this.showVietCoreSubmenu = !this.showVietCoreSubmenu">
            <a class="flex items-center text-purple cursor-pointer">
                Core HR
                <div style="flex: 1"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showVietCoreSubmenu">
                    <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                </svg>                                  
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showVietCoreSubmenu">
                    <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                </svg>                                  
            </a>
        </li>
    </ng-container>
    
    <div class="ml-8 menu__submenu" *ngIf="showVietCoreSubmenu">
        <ul class="relative pb-2.5">
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Employees in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/employees/vietnam'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/employees/vietnam')">
                        Employees
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/employees/vietnam')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Components in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/components/vietnam'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/components/vietnam')">
                        Component Category
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/components/vietnam')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Component Details in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/component-details/vietnam'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/component-details/vietnam')">
                        Component
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/component-details/vietnam')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Payroll in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/payroll/vietnam'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/payroll/vietnam')">
                        Payroll
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/payroll/vietnam')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Attendance in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/attendance/vietnam'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/attendance/vietnam')">
                        Attendance
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/hr-platform/attendance/vietnam')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Leave in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/leave/vietnam'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/leave/vietnam')">
                        Leave
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/leave/vietnam')"
                        ></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Expense Claim in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/expense-claim/vietnam'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/expense-claim/vietnam')">
                        Expense Claim
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/hr-platform/expense-claim/vietnam')"
                        ></span>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>


<!-- <ng-container *ngIf="permissionService.hasPermission('HR Platform - View Core HR in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" (click)="this.showCoreSubmenu = !this.showCoreSubmenu">
        <a class="flex items-center text-purple cursor-pointer">
            Core HR
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showCoreSubmenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>                                  
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showCoreSubmenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>                                  
        </a>
    </li>
</ng-container>

<div class="ml-8 menu__submenu" *ngIf="showCoreSubmenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Employees in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/employees'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/employees')">
                    Employees
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/hr-platform/employees')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Components in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/components/' + country" [class.font-semibold]="activeRoute.startsWith('/hr-platform/components')">
                    Component Category
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/hr-platform/components')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Component Details in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/component-details'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/component-details')">
                    Component
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/hr-platform/component-details')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Payroll in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/payroll'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/payroll')">
                    Payroll
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Attendance in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/attendance'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/attendance')">
                    Attendance
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                    [class.opacity-0]="!activeRoute.startsWith('/hr-platform/attendance')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Leave in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/leave'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/leave')">
                    Leave
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/hr-platform/leave')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - Core HR - View Expense Claim in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/hr-platform/expense-claim'" [class.font-semibold]="activeRoute.startsWith('/hr-platform/expense-claim')">
                    Expense Claim
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div> -->


<ng-container *ngIf="permissionService.hasPermission('HR Platform - View ESS Portal in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" oldclick="openSsoLink('TBRGHris')">
        <a class="flex items-center text-purple cursor-pointer">
            ESS Portal
            <!-- <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showEssSubmenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>                                  
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showEssSubmenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>                                   -->
        </a>
    </li>
</ng-container>

<div class="ml-8 menu__submenu" *ngIf="showEssSubmenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - View Feed in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Feed
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - View Employee Feedback in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Employee Feedback
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - View Forums in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Forums
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - View E-Learning in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    E-Learning
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - View Bookings in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Bookings
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - View Gamification in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Gamification
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('HR Platform - View Surveys in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Surveys
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>