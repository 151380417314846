import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';

export const routes: Routes = [
    // Transactions
    {
        path: 'banking/transactions',
        loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/transactions/create/:type',
        loadChildren: () => import('./transactions/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/transactions/view/:id',
        loadChildren: () => import('./transactions/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/transactions/edit/:id',
        loadChildren: () => import('./transactions/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // Accrual Transaction
    {
        path: 'banking/accrual-transactions',
        loadChildren: () => import('./accrual-transactions/accrual-transactions.module').then(m => m.AccrualTransactionsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/accrual-transactions/create/:type',
        loadChildren: () => import('./accrual-transactions/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/accrual-transactions/view/:id',
        loadChildren: () => import('./accrual-transactions/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/accrual-transactions/edit/:id',
        loadChildren: () => import('./accrual-transactions/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'banking/transfers',
        loadChildren: () => import('./transfers/transfers.module').then(m => m.TransfersModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/transfers/create',
        loadChildren: () => import('./transfers/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/transfers/view/:id',
        loadChildren: () => import('./transfers/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/transfers/edit/:id',
        loadChildren: () => import('./transfers/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/reconciliation',
        loadChildren: () => import('./reconciliations/reconciliations.module').then(m => m.ReconciliationsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/reconciliation/create',
        loadChildren: () => import('./reconciliations/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'banking/reconciliation/view/:id',
        loadChildren: () => import('./reconciliations/view/view.module').then(m => m.ViewModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
];
