import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import '@selectize/selectize';

@Component({
  selector: 'app-marketing-cost',
  templateUrl: './marketing-cost.component.html',
  styleUrls: ['./marketing-cost.component.scss']
})

export class MarketingCostComponent {
  date = '';
  errorMessage = '';
  successSetup = false;
  data: any = {};

  reportGenerated = false;
  reportLoading = false;
  
  async getData() {
    if (this.reportLoading) {
      return;
    }

    this.reportGenerated = true;
    this.reportLoading = true;

    try {
      const res = await axios.get(`${environment.api_url}/reports/custom-reports/financial-results?date=${this.date}&entity_id=${$("#entities").val()}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(environment.api_token_identifier)}`
        }
      });
      const data = await res.data;

      if (data.error) {
        this.errorMessage = data.error_message;
      } else {
        this.successSetup = true;
        this.data = data.dto;

        const arrayKeys = Object.keys(this.data);
        for (const arrayKey of arrayKeys) {
          if (this.data[arrayKey].owner_of_the_parent !== undefined) {
            if (this.data[arrayKey].owner_of_the_parent.includes('(')) {
              this.data[arrayKey].owner_of_the_parent = {
                data: this.data[arrayKey].owner_of_the_parent,
                isRed: true
              }
            } else {
              this.data[arrayKey].owner_of_the_parent = {
                data: this.data[arrayKey].owner_of_the_parent,
                isRed: false
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      this.errorMessage = 'Sorry, something went wrong. Please try again later.';
    }
  }

  setupSelectize() {
    const component = this;
    const entityEl: any = $('#entities');
    entityEl.selectize({
      preload: true,
      valueField: 'id',
      labelField: 'label',
      searchField: 'label',
      load: function (query: any, callback: any) {
        $("#fetching_entities").show();
        $.ajax({
          url: `${environment.api_url}/settings/entities?keyword=${query}`,
          type: 'GET',
          error: function() {
            callback();
            $("#fetching_entities").hide();
            component.errorMessage = 'Sorry, something went wrong. Please try again later.'
          },
          success: function(res: any) {
            callback(res.entities.data);
            $("#fetching_entities").hide();
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(environment.api_token_identifier)}`,
            'Content-Type': 'application/json',
          }
        });
      }
    });
  }

  ngOnInit(): void {
    const currentMoment = moment().format('YYYY-MM-DD');
    this.date = currentMoment;

    const component = this;
    setTimeout(() => {
      component.setupSelectize();
    }, 200);
  }
}
