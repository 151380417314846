import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-finance-platform-link',
  templateUrl: './finance-platform-link.component.html',
  styleUrls: ['./finance-platform-link.component.scss']
})
export class FinancePlatformLinkComponent implements OnInit {

  @Output() clicked = new EventEmitter<string>();

  alert = alert;
  showFinanceSubmenu = false;
  showBankingSubmenu = false;
  showDoubleEntrySubMenu = false;
  showCostControlSubmenu = false;
  showCostCenterSubSubmenu = false;
  showProfitCenterSubSubMenu = false;
  showCreditDebitSubmenu = false;
  showCreditDebitNotesCreditNoteSubmenu = false;
  showCreditDebitNotesDebitNoteSubmenu = false;
  showAutomationsSubmenu = false;
  showAutomationLogSubmenu = false;
  showAssetManagementSubmenu = false;
  showReportSubmenu = false;
  showNonTradeMenu = false;
  showCommoditiesAndServicesSubmenu = false;
  showInternalOrderSubmenu = false;
  showFinanceFormsSubMenu = false;
  showB2BDocumentationSubMenu = false;
  showVendorDocumentationSubMenu = false;
  showPlanningAndForecastingSubmenu = false;

  activeRoute = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public permissionService: PermissionService
  ) {}

  openSalesReconciliation() {
    this.clicked.emit('sso:TBRGSalesReconciliation');
  }

  handleFinanceSubmenu() {
    this.showFinanceSubmenu = !this.showFinanceSubmenu;
    if (! this.showFinanceSubmenu) {
      this.showBankingSubmenu = false;
      this.showDoubleEntrySubMenu = false;
      this.showCostControlSubmenu = false;
      this.showCostCenterSubSubmenu = false;
      this.showProfitCenterSubSubMenu = false;
      this.showAutomationsSubmenu = false;
      this.showAutomationLogSubmenu = false;
      this.showAssetManagementSubmenu = false;
      this.showReportSubmenu = false;
      this.showNonTradeMenu = false;
      this.showCreditDebitSubmenu = false;
      this.showB2BDocumentationSubMenu = false;
    }
  }

  ngOnInit() {
    this.activeRoute = this.router.url;
    if (this.activeRoute.startsWith('/banking')) {
      this.showFinanceSubmenu = true;
      // this.showBankingSubmenu = true;
      this.showDoubleEntrySubMenu = true;
    }

    if (this.activeRoute.startsWith('/bank-auto-journal-entry')) {
      this.showFinanceSubmenu = true;
      this.showAutomationsSubmenu = true;
    }

    if (this.activeRoute.startsWith('/double-entry')) {
      this.showFinanceSubmenu = true;
      this.showDoubleEntrySubMenu = true;
    }
    if (this.activeRoute.startsWith('/budget-management')) {
      this.showFinanceSubmenu = true;
    }
    if (this.activeRoute.startsWith('/cost-control')) {
      this.showFinanceSubmenu = true;
      this.showCostControlSubmenu = true;
    }
    if (this.activeRoute.startsWith('/cost-control/cost-centers')) {
      this.showCostCenterSubSubmenu = true;
    }
    if (this.activeRoute.startsWith('/credit-debit-notes')) {
      this.showFinanceSubmenu = true;
      this.showCreditDebitSubmenu = true;
      this.showDoubleEntrySubMenu = true;
    }
    if (this.activeRoute.startsWith('/credit-debit-notes/credit-notes')) {
      this.showCreditDebitNotesCreditNoteSubmenu = true;
    }
    if (this.activeRoute.startsWith('/credit-debit-notes/debit-notes')) {
      this.showCreditDebitNotesDebitNoteSubmenu = true;
    }
    if (this.activeRoute.startsWith('/automation')) {
      this.showFinanceSubmenu = true;
      this.showAutomationsSubmenu = true;
      this.showDoubleEntrySubMenu = true;
    }
    if (this.activeRoute.startsWith('/automation/logs')) {
      this.showAutomationLogSubmenu = true;
    }

    if (this.activeRoute.startsWith('/asset-management')) {
      this.showFinanceSubmenu = true;
      this.showAssetManagementSubmenu = true;
    }

    if (this.activeRoute.startsWith('/cash-book')) {
      this.showFinanceSubmenu = true;
    }

    if (this.activeRoute.startsWith('/non-trade')) {
      this.showFinanceSubmenu = true;
      this.showNonTradeMenu = true;
    }

    if (this.activeRoute.startsWith('/non-trade/commodities-and-services')) {
      this.showCommoditiesAndServicesSubmenu = true;
    }

    if (this.activeRoute.startsWith('/finance/reports')) {
      this.showFinanceSubmenu = true;
      this.showReportSubmenu = true;
    }

    if (this.activeRoute.startsWith('/finance/documents')) {
      this.showFinanceSubmenu = true;
      this.showCreditDebitSubmenu = true;
      this.showDoubleEntrySubMenu = true;
    }

    if (this.activeRoute.startsWith('/non-trade/internal-order')) {
      this.showInternalOrderSubmenu = true;
    }

    if (this.activeRoute.startsWith('/cash-advance')) {
      this.showFinanceSubmenu = true;
      this.showFinanceFormsSubMenu = true;
    }

    if (this.activeRoute.startsWith('/finance/staff-claim')) {
      this.showFinanceSubmenu = true;
    }

    if (this.activeRoute.startsWith('/credit-debit-notes/debit-notes/tenant') ||
      this.activeRoute.startsWith('/credit-debit-notes/credit-notes/tenant') ||
      this.activeRoute.startsWith('/finance/documents/tenant-invoices')
    ) {
      this.showDoubleEntrySubMenu = false;
      this.showB2BDocumentationSubMenu = true;
    }

    if (this.activeRoute.startsWith('/credit-debit-notes/debit-notes/vendor') ||
      this.activeRoute.startsWith('/credit-debit-notes/credit-notes/vendor') ||
      this.activeRoute.startsWith('/finance/documents/vendor-invoices')
    ) {
      this.showDoubleEntrySubMenu = false;
      this.showVendorDocumentationSubMenu = true;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(() => {
      this.activeRoute = this.router.url;
    });
  }

}
