import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PermissionService } from './permission.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    public permissionService: PermissionService
  ) { }

  isAuthenticated(): boolean {
    const token = window.localStorage.getItem(environment.api_token_identifier);
    return token !== undefined && token !== null && token !== '';
  }

  logout(){
    window.localStorage.removeItem(environment.api_token_identifier);
    this.permissionService.removePermissions();
    this.router.navigateByUrl('/login');
  }
}
