import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-production-link',
  templateUrl: './production-link.component.html',
  styleUrls: ['./production-link.component.scss']
})
export class ProductionLinkComponent {

  @Output() clicked = new EventEmitter<string>();

  alert = alert;
  showProductionSubmenu = false;
  activeRoute = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public permissionService: PermissionService
  ) {}

  handleProductionSubmenu() {
    this.showProductionSubmenu = !this.showProductionSubmenu;
  }

  ngOnInit() {
    this.activeRoute = this.router.url;
    if (this.activeRoute.startsWith('/production')) {
      this.showProductionSubmenu = true;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(() => {
      this.activeRoute = this.router.url;
    });
  }
}
