import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { PasswordChangedGuard } from "src/app/guards/password-changed.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
// import { BudgetedActualModule } from "./budgeted-actual/budgeted-actual.module";
import { MtdPerformanceModule } from "./mtd-performance/mtd-performance.module";
import { RatioAnalysisModule } from "./ratio-analysis/ratio-analysis.module";
import { CashFlowModule } from "./cash-flow/cash-flow.module";
import { MarketingCostModule } from "./marketing-cost/marketing-cost.module";
import { MarketingCostBillboardModule } from "./marketing-cost-billboard/marketing-cost-billboard.module";
import { SegmentalReportingModule } from "./segmental-reporting/segmental-reporting.module";



export const routes: Routes = [
  {
    path: 'finance/reports',
    loadChildren: () => import('./reports.module').then(m => m.ReportsModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'finance/reports/trial-balance',
    loadChildren: () => import('./trial-balance/trial-balance.module').then(m => m.TrialBalanceModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard,
    ]
  },

  {
    path: 'finance/reports/profit-and-loss',
    loadChildren: () => import('./profit-and-loss/profit-and-loss.module').then(m => m.ProfitAndLostModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard,
    ]
  },
  {
    path: 'finance/reports/balance-sheet',
    loadChildren: () => import('./balance-sheet/balance-sheet.module').then(m => m.BalanceSheetModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard,
    ]
  },

  {
    path: 'finance/reports/journal-entry',
    loadChildren: () => import('./journal-entry/journal-entry.module').then(m => m.JournalEntryModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard,
    ]
  },

  {
    path: 'finance/reports/consolidated-financial-result',
    loadChildren: () => import('./consolidated-financial-result/consolidated-financial-result.module').then(m => m.ConsolidatedFinancialResultModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'finance/reports/financial-results',
    loadChildren: () => import('./financial-results/financial-results.module').then(m => m.FinancialResultsModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'finance/reports/opex-breakdown',
    loadChildren: () => import('./opex-breakdown/opex-breakdown.module').then(m => m.OpexBreakdownModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'finance/reports/gross-profit-analysis',
    loadChildren: () => import('./gross-profit-analysis/gross-profit-analysis.module').then(m => m.GrossProfitAnalysisModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  // {
  //   path: 'finance/reports/budgeted-actual',
  //   loadChildren: () => import('./budgeted-actual/budgeted-actual.module').then(m => BudgetedActualModule),
  //   data: {
  //     layout: 'app'
  //   },
  //   canActivate: [
  //     IsLoggedInGuard,
  //     SetupCompletedGuard,
  //     PasswordChangedGuard
  //   ]
  // },
  {
    path: 'finance/reports/mtd-performance',
    loadChildren: () => import('./mtd-performance/mtd-performance.module').then(m => MtdPerformanceModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'finance/reports/ratio-analysis',
    loadChildren: () => import('./ratio-analysis/ratio-analysis.module').then(m => RatioAnalysisModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'finance/reports/cash-flow',
    loadChildren: () => import('./cash-flow/cash-flow.module').then(m => CashFlowModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'finance/reports/marketing-cost',
    loadChildren: () => import('./marketing-cost/marketing-cost.module').then(m => MarketingCostModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'finance/reports/marketing-cost-billboard',
    loadChildren: () => import('./marketing-cost-billboard/marketing-cost-billboard.module').then(m => MarketingCostBillboardModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'finance/reports/segmental-reporting',
    loadChildren: () => import('./segmental-reporting/segmental-reporting.module').then(m => SegmentalReportingModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'finance/reports/gl-ledger',
    loadChildren: () => import('./gl-ledger/gl-ledger.module').then(m => m.GlLedgerModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'finance/reports/ytd-opex',
    loadChildren: () => import('./ytd-opex/ytd-opex.module').then(m => m.YtdOpexModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'finance/reports/receipt-payment',
    loadChildren: () => import('./receipt-payment/receipt-payment.module').then(m => m.receiptPayment),
    data: {
      layout: 'export'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
];
