import { Routes } from '@angular/router';
import { IsLoggedInGuard } from 'src/app/guards/is-logged-in.guard';
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';
import { SetupCompletedGuard } from 'src/app/guards/setup-completed.guard';

export const routes: Routes = [
  {
    path: 'planning-and-forecasting/cash-flow',
    loadChildren: () =>
      import('./cash-flow/cash-flow.module').then((m) => m.CashFlowModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'planning-and-forecasting/cash-flow/:cashFlowPlanId',
    loadChildren: () => import('./cash-flow/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  }
];
