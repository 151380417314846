<li class="group relative pb-2.5 text-sm" (click)="this.showSubmenu = !this.showSubmenu">
    <a class="flex items-center text-purple cursor-pointer">
        <div class="w-8 h-8 flex items-center justify-center mr-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
            </svg>              
        </div>
        Loyalty
        <div style="flex: 1"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showSubmenu">
            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
        </svg>                                  
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showSubmenu">
            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
        </svg>                                  
    </a>
</li>
<div class="ml-8 menu__submenu" *ngIf="showSubmenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Loyalty - View Customer Accounts in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Customer Accounts
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Loyalty - View Announcements in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Announcements
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Loyalty - View Points Multiplier in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Points Multiplier
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Loyalty - View E-Stamp in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    E-Stamp
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Loyalty - View Personal Shopper in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Personal Shopper
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Loyalty - View E-Voucher in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    E-Voucher
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Loyalty - View Events in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Events
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Loyalty - View Customer Engagement in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="openSsoLink('TBRGCustomerEngagement')">
                    Customer Engagement
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Loyalty - View More in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    More
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
