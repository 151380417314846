<div class="p-4">
    <div class="my-4 rounded-lg w-100 bg-red-100 p-5 text-base text-red-700" *ngIf="errorMessage !== null && errorMessage !== undefined && errorMessage.length > 0">
        {{ errorMessage }}
    </div>

    <div class="pt-2" id="form">
        <!-- Form -->
        <div class="flex">
            <div class="grid grid-cols-2 gap-x-8 gap-y-6 pt-5" style="flex: 1">
                <div class="form-group relative required">
                    <label for="entity" class="form-control-label">
                        Entity
                    </label>
                    <div class="input-group input-group-merge input-group-alternative">
                        <p class="pt-2 text-sm" id="fetching_entities" style="display: none">Fetching Entities...</p>
                        <div class="pt-1">
                            <select name="entity_id" id="entities">
                                <option value="">Select Entity</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group relative">
                    <label for="date" class="form-control-label">
                        Date
                    </label>
                    <div class="input-group input-group-merge input-group-alternative">
                        <input type="date" class="form-element" [(ngModel)]="date" (change)="getData()">
                    </div>
                </div>
            </div>
            <div class="ml-4 contains-button">
                <button type="submit" class="relative flex items-center justify-center bg-green hover:bg-green-700 text-white px-6 py-1.5 text-base rounded-lg disabled:bg-green-100 w-full"
                    [class.opacity-50]="reportLoading"
                    [class.cursor-not-allowed]="reportLoading"
                    style="margin-top: 50px"
                >
                    <span *ngIf="!reportLoading">Generate</span>
                    <span *ngIf="reportLoading">Generating...</span>
                </button>
            </div>
        </div>
    </div>

    <div class="py-4">
        <hr>
    </div>

    <ng-container *ngIf="reportGenerated">
        <ng-container *ngIf="reportLoading">
            <p>Loading report...</p>
        </ng-container>
    </ng-container>

    <div style="overflow-x: scroll;">
        <table class="table">
            <tbody>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">[[xx]] month</div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-left: 2px dashed transparent; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">[[xx]] month</div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;"></td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">YTD [[month]] [[year]]</div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-left: 2px dashed transparent; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">YTD [[month]] [[year]]</div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px; font-weight: bold;">Variance</div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>RM ('000)</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-left: 2px dashed transparent; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>RM ('000)</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="font-weight: bold; padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>RM ('000)</div>
                                <div>%</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="text-xs" style="font-weight: bold;">Revenue (Gross)</td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td colspan="7" style="height: 16px;"></td>
                </tr>
                <tr>
                    <td class="text-xs">SL</td>
                    <td class="text-xs" style="font-weight: bold;">Discount</td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs">SL</td>
                    <td class="text-xs" style="font-weight: bold;">Voucher</td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs">SL</td>
                    <td class="text-xs" style="font-weight: bold;">Rebate</td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs">SL</td>
                    <td class="text-xs" style="font-weight: bold;">Return</td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs">CO</td>
                    <td class="text-xs" style="font-weight: bold;">Give away items</td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs">EP</td>
                    <td class="text-xs" style="font-weight: bold;">Marketing - Commisions</td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs">EP</td>
                    <td class="text-xs" style="font-weight: bold;">Marketing - Advertisement</td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="text-xs">% of Gross Revenue</td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center; background-color: grey;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>