import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-merchandising-inventory-link',
  templateUrl: './merchandising-inventory-link.component.html',
  styleUrls: ['./merchandising-inventory-link.component.scss']
})
export class MerchandisingInventoryLinkComponent implements OnInit {

  alert = alert;
  activeRoute = '';
  showSubmenu = false;
  showHierarchySubmenu = false;
  showPurchasingSubmenu = false;
  showPromotionPlanningSubmenu = false;
  showStoreOperationsSubmenu = false;
  showInventoryMenu = false;
  showPlanogramMenu = false;
  showTradingTermMenu = false;
  showMerchandisingFormMenu = false;
  public showDisposalMenu:boolean = false;
  showStockTakeMenu:boolean = false;
  showMaterialRequestMenu = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public permissionService: PermissionService
  ) {}

  handleSubmenu() {
    this.showSubmenu = !this.showSubmenu;
    if (! this.showSubmenu) {
      this.showHierarchySubmenu = false;
      this.showPurchasingSubmenu = false;
      this.showStoreOperationsSubmenu = false;
      this.showPromotionPlanningSubmenu = false;
      this.showInventoryMenu = false;
      this.showDisposalMenu = false;
      this.showStockTakeMenu = false;
      this.showPlanogramMenu = false;
    }
  }

  ngOnInit() {
    this.activeRoute = this.router.url;

    if (this.activeRoute.startsWith('/merchandising')) {
      this.showSubmenu = true;
    }

    if (this.activeRoute.startsWith('/merchandising/product-hierarchy') ||
      this.activeRoute.startsWith('/merchandising/mdz-assortment-masters') ||
      this.activeRoute.startsWith('/merchandising/mdz-brand-masters') ||
      this.activeRoute.startsWith('/merchandising/bom-recipes')
    ) {
      this.showSubmenu = false;
      this.showHierarchySubmenu = false;
    }

    if (this.activeRoute.startsWith('/merchandising/promotions')) {
      this.showPromotionPlanningSubmenu = true;
    }

    if (
      this.activeRoute.startsWith('/merchandising/inventory') ||
      this.activeRoute.startsWith('/merchandising/disposal') ||
      this.activeRoute.startsWith('/merchandising/stock-adjustments') ||
      this.activeRoute.startsWith('/merchandising/cycle-count') ||
      this.activeRoute.startsWith('/merchandising/stock-take') ||
      this.activeRoute.startsWith('/merchandising/stock-take-submissions') ||
      this.activeRoute.startsWith('/merchandising/stock-transfer-storage') ||
      this.activeRoute.startsWith('/merchandising/interlocation-transfer-item') ||
      this.activeRoute.startsWith('/merchandising/receiving-stock-transfer') ||
      this.activeRoute.startsWith('/merchandising/material-request') ||
      this.activeRoute.startsWith('/merchandising/bill-of-materials') ||
      this.activeRoute.startsWith('/merchandising/production-order') ||
      this.activeRoute.startsWith('/merchandising/pa-zeros') ||
      this.activeRoute.startsWith('/merchandising/stock-reserves')
    ) {
      this.showInventoryMenu = true;
    }

    if(this.activeRoute.startsWith('/merchandising/disposal')) {
      this.showDisposalMenu = true;
    }

    if(this.activeRoute.startsWith('/merchandising/stock-take')) {
      this.showStockTakeMenu = true;
    }

    if(this.activeRoute.startsWith('/merchandising/stock-take-submission')) {
      this.showStockTakeMenu = true;
    }

    if(this.activeRoute.startsWith('/merchandising/material-request/')) {
      this.showMaterialRequestMenu = true;
    }

    if(this.activeRoute.startsWith('/merchandising/purchasing')) {
      this.showPurchasingSubmenu = true;
    }

    if (this.activeRoute.startsWith('/merchandising/planograms')) {
      this.showPlanogramMenu = true;
    }

    if (this.activeRoute.startsWith('/merchandising/trading-terms/supplier-rebates')
      || this.activeRoute.startsWith('/merchandising/trading-terms/supplier-discounts')) {
      this.showTradingTermMenu = true;
    }
    
    if (this.activeRoute.startsWith('/merchandising/merchandising-forms')) {
      this.showTradingTermMenu = true;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(() => {
      this.activeRoute = this.router.url;
    });
  }

  openReceiving() {
    this.router.navigateByUrl('/merchandising/purchasing/receiving?tab=receivings&page=1');
  }
}
