import { Component } from '@angular/core';
import * as moment from 'moment';

const dummyReports = {
  retail: {
    revenues: {
      gross_revenues: 64643864,
      srikandi_gross_revenues: 11307366,
      discount: 13666602,
      srikandi_discount: 1180262,
      voucher: 349997,
      rebate: null,
      return: null,
      net_revenue: 61754368,
    },
    cogs: 32846878,
    gross_profit: 28907490,
    other_income: null,
    opex: {
      employee_cost: 2795202,
      marketing: 7788440,
      rental: 871678,
      others: 4703157,
      total: 16158477,
    },
    pretax_profit: 12749013,
    taxation: null,
    profit_after_tax: 12749013,
    gp_percent: 47,
    pbt_percent: 21,
    pat_percent: null,
  },
  franchise: {
    revenues: {
      gross_revenues: 11505714,
      srikandi_gross_revenues: null,
      discount: 4088565,
      srikandi_discount: null,
      voucher: 1320,
      rebate: null,
      return: null,
      net_revenue: 7145830,
    },
    cogs: 6827801,
    gross_profit: 588029,
    other_income: null,
    opex: {
      employee_cost: null,
      marketing: null,
      rental: null,
      others: 4110,
      total: 4110,
    },
    pretax_profit: 583919,
    taxation: null,
    profit_after_tax: 583919,
    gp_percent: 8,
    pbt_percent: 8,
    pat_percent: null,
  },
  e_commerce: {
    revenues: {
      gross_revenues: 16171737,
      srikandi_gross_revenues: null,
      discount: 398252,
      srikandi_discount: null,
      voucher: 9319,
      rebate: null,
      return: null,
      net_revenue: 15773167,
    },
    cogs: 8389678,
    gross_profit: 7383488,
    other_income: null,
    opex: {
      employee_cost: 363174,
      marketing: 1729944,
      rental: null,
      others: 1447435,
      total: 3570554,
    },
    pretax_profit: 3812934,
    taxation: null,
    profit_after_tax: 3812934,
    gp_percent: 47,
    pbt_percent: 25,
    pat_percent: null,
  },
  others: {
    revenues: {
      gross_revenues: 1526645,
      srikandi_gross_revenues: null,
      discount: null,
      srikandi_discount: null,
      voucher: null,
      rebate: null,
      return: null,
      net_revenue: 1526645,
    },
    cogs: 812016,
    gross_profit: 714629,
    other_income: 719700,
    opex: {
      employee_cost: 4771214,
      marketing: 227627,
      rental: 44238,
      others: 6553339,
      total: 11596418,
    },
    pretax_profit: 10162089,
    taxation: 1676000,
    profit_after_tax: 11383089,
    gp_percent: 47,
    pbt_percent: -666,
    pat_percent: null,
  },
  grand_total: {
    revenues: {
      gross_revenues: 94847960,
      srikandi_gross_revenues: 11307366,
      discount: 18144419,
      srikandi_discount: 1180262,
      voucher: 360639,
      rebate: null,
      return: null,
      net_revenue: 86470009,
    },
    cogs: 48876374,
    gross_profit: 37593636,
    other_income: 719700,
    opex: {
      employee_cost: 7929591,
      marketing: 9746011,
      rental: 915916,
      others: 12738041,
      total: 31329558,
    },
    pretax_profit: 6983778,
    taxation: 1676000,
    profit_after_tax: 5307778,
    gp_percent: 43,
    pbt_percent: -8,
    pat_percent: 6,
  },
};

@Component({
  selector: 'app-segmental-reporting',
  templateUrl: './segmental-reporting.component.html',
  styleUrls: ['./segmental-reporting.component.scss'],
})
export class SegmentalReportingComponent {
  errorMessage = '';
  successSetup = false;

  selectedMonth: string = moment().format('YYYY-MM');
  reportMonth: string = moment().format('YYYY-MM');
  reports: any = {};
  reportLoading = false;

  constructor() {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.reportMonth = this.selectedMonth;
    this.reportLoading = true;
    setTimeout(() => {
      this.reportLoading = false;
      this.reports = dummyReports;
    }, 1000);
  }

  getMonthToDisplay() {
    if (!this.reportMonth) {
      return 'N/A';
    }

    return moment(this.reportMonth).format('MMMM YYYY');
  }
}
