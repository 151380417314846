import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { PermissionService } from './services/permission.service';

import axios from 'axios';
import { environment } from "src/environments/environment";
import { IdleService } from './services/activities/idle.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  layout = 'login';
  activeRoute = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public permissionService: PermissionService,
    private idleService: IdleService,
    private authService: AuthService
  ) {
    /* idleService.idle$.subscribe(s => {
      if(this.authService.isAuthenticated()){
        alert('Your session is about to expire due to inactivity');
        this.authService.logout();
      }
    }); */
  }

  openLink (page: any) {
    if (page.indexOf("sso:") == 0) {
      this.openSsoLink(page.replace('sso:', ''));
    }
  }

  async openSsoLink(shortcutLink: any) {
    try {
      const res = await axios.post(`${environment.api_url}/get-sso-link`, {
        app_key: shortcutLink
      }, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(environment.api_token_identifier)}`
        }
      });
      const data = await res.data;
      if (data.error) {
        alert(data.error_message);
        return;
      }

      window.location.href = data.redirect_url;
    } catch (error) {
      alert('Sorry, something went wrong performing SSO to this application. Please try again later.');
    }
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
      this.layout = data['layout'];
      this.activeRoute = this.router.url;
      this.permissionService.getPermissions();
    });
  }
}
