<li class="group relative pb-2.5 text-sm" (click)="handleFinanceSubmenu()">
    <a class="flex items-center text-purple cursor-pointer">
        <div class="w-8 h-8 flex items-center justify-center mr-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                <path fill-rule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z" clip-rule="evenodd" />
                <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z" />
            </svg>
        </div>
        Finance
        <div style="flex: 1"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showFinanceSubmenu">
            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showFinanceSubmenu">
            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
        </svg>
    </a>
</li>
<ng-container *ngIf="permissionService.hasPermission('Finance - View Core Finance in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showFinanceSubmenu" (click)="this.showDoubleEntrySubMenu = !this.showDoubleEntrySubMenu" >
        <a class="flex items-center text-purple cursor-pointer">
            Core Finance
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showDoubleEntrySubMenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showDoubleEntrySubMenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>
<div class="ml-8 menu__submenu" *ngIf="showDoubleEntrySubMenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Finance - Core Finance - View Journal Entry in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple" [routerLink]="'/double-entry/journal-entries'" [class.font-semibold]="activeRoute.startsWith('/double-entry/journal-entries') || activeRoute.startsWith('/double-entry/journal-entry-drafts')">
                    Journal Entry
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="permissionService.hasPermission('Finance - Core Finance - Can View Journal Entry Upload Processing Statuses')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple cursor-pointer" [routerLink]="'automation/journal-entry-upload'" [class.font-semibold]="activeRoute.startsWith('/automation/journal-entry-upload')">
                    Journal Entry Upload
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('automation/journal-entry-upload')" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="permissionService.hasPermission('Finance - Treasury - View Reconciliation in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple" [class.font-semibold]="activeRoute.startsWith('/banking/reconciliation')" [routerLink]="'/banking/reconciliation'">
                    Bank Recon
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="permissionService.hasPermission('Finance - Automation - Payment Proposal - View Payment Proposal in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple cursor-pointer" [routerLink]="'automation/payment-proposal'" [class.font-semibold]="activeRoute.startsWith('/automation/payment-proposal')">
                    Payment List
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('automation/payment-proposal')" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
<div class="ml-8 menu__submenu" *ngIf="showCostControlSubmenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Finance - Profit/Cost Centers - View Profit Centers in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <div class="flex cursor-pointer items-center text-purple" [class.font-semibold]="activeRoute.startsWith('/cost-control/profit-centers')"
                    (click)="this.showProfitCenterSubSubMenu = !this.showProfitCenterSubSubMenu"
                >
                    Profit Centers
                    <div style="flex: 1"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showProfitCenterSubSubMenu">
                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showProfitCenterSubSubMenu">
                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
        </ng-container>
        <ng-container *ngIf="showProfitCenterSubSubMenu">
            <ng-container *ngIf="permissionService.hasPermission('Finance - Profit/Cost Centers - Profit Centers - View Categories in Sidebar')">
                <li class="pl-4 group relative pb-2.5 text-sm" >
                    <a class="flex items-center text-purple cursor-pointer" (click)="alert('Please complete demo first to unlock')" [class.font-semibold]="activeRoute.startsWith('/cost-control/profit-centers/categories')"> <!--  [routerLink]="'/cost-control/profit-centers/categories'" -->
                        Categories
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/cost-control/profit-centers/categories')" style="width: 5px;"></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('Finance - Profit/Cost Centers - Profit Centers - View Profit Centers in Sidebar')">
                <li class="pl-4 group relative pb-2.5 text-sm">
                    <a class="flex items-center text-purple cursor-pointer" (click)="alert('Please complete demo first to unlock')" [class.font-semibold]="activeRoute.startsWith('/cost-control/profit-centers/profit-centers')"> <!--  [routerLink]="'/cost-control/profit-centers/profit-centers'"-->
                        Profit Centers
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/cost-control/profit-centers/profit-centers')" style="width: 5px;"></span>
                    </a>
                </li>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Finance - Profit/Cost Centers - View Cost Centers in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <div class="flex cursor-pointer items-center text-purple" [class.font-semibold]="activeRoute.startsWith('/cost-control/cost-centers')"
                    (click)="this.showCostCenterSubSubmenu = !this.showCostCenterSubSubmenu"
                >
                    Cost Centers
                    <div style="flex: 1"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showCostCenterSubSubmenu">
                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showCostCenterSubSubmenu">
                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
        </ng-container>
        <ng-container *ngIf="showCostCenterSubSubmenu">
            <ng-container *ngIf="permissionService.hasPermission('Finance - Profit/Cost Centers - Cost Centers - View Categories in Sidebar')">
                <li class="pl-4 group relative pb-2.5 text-sm">
                    <a class="flex items-center text-purple cursor-pointer" (click)="alert('Please complete demo first to unlock')" [class.font-semibold]="activeRoute.startsWith('/cost-control/cost-centers/categories')"> <!--  [routerLink]="'/cost-control/cost-centers/categories'"-->
                        Categories
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/cost-control/cost-centers/categories')" style="width: 5px;"></span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="permissionService.hasPermission('Finance - Profit/Cost Centers - Cost Centers - View Cost Centers in Sidebar')">
                <li class="pl-4 group relative pb-2.5 text-sm">
                    <a class="flex items-center text-purple cursor-pointer" (click)="alert('Please complete demo first to unlock')" [class.font-semibold]="activeRoute.startsWith('/cost-control/cost-centers/cost-centers')"> <!--  [routerLink]="'/cost-control/cost-centers/cost-centers'" -->
                        Cost Centers
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/cost-control/cost-centers/cost-centers')" style="width: 5px;"></span>
                    </a>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</div>
<!-- <ng-container *ngIf="permissionService.hasPermission('Finance - View Sales Reconciliation in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showFinanceSubmenu" >
        <a class="flex items-center text-purple cursor-pointer" (click)="openSalesReconciliation()">
            Sales Reconciliation
            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"></span>
        </a>
    </li>
</ng-container> -->
<div class="ml-8 menu__submenu" *ngIf="showAssetManagementSubmenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Finance - Asset Management - View Categories in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/asset-management/categories'" [class.font-semibold]="activeRoute.startsWith('/asset-management/categories')">
                    Categories
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/asset-management/categories')" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="permissionService.hasPermission('Finance - Asset Management - View Assets in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/asset-management/assets'" [class.font-semibold]="activeRoute.startsWith('/asset-management/assets')">
                    Assets
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/asset-management/assets')" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>

<ng-container *ngIf="permissionService.hasPermission('Finance - Documents - Credit Notes - View Vendor in Sidebar') || permissionService.hasPermission('Finance - Documents - Debit Notes - View Customer in Sidebar') || permissionService.hasPermission('Finance - View Bills in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showFinanceSubmenu" (click)="this.showVendorDocumentationSubMenu= !this.showVendorDocumentationSubMenu">
        <a class="flex items-center text-purple cursor-pointer">
            Vendor Docs.
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showVendorDocumentationSubMenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showVendorDocumentationSubMenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>

<div class="ml-8 menu__submenu" *ngIf="showVendorDocumentationSubMenu">
    <ng-container *ngIf="permissionService.hasPermission('Finance - Documents - Debit Notes - View Vendor in Sidebar')">
        <li class="group relative pb-2.5 text-sm">
            <a class="flex items-center text-purple cursor-pointer"  [class.font-semibold]="activeRoute.startsWith('/credit-debit-notes/debit-notes/vendor')" [routerLink]="'/credit-debit-notes/debit-notes/vendor'">
                Debit Notes
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/credit-debit-notes/debit-notes/vendor')" style="width: 5px;"></span>
            </a>
        </li>
    </ng-container>

    <ng-container *ngIf="permissionService.hasPermission('Finance - Documents - Credit Notes - View Vendor in Sidebar')">
        <li class="group relative pb-2.5 text-sm">
            <a class="flex items-center text-purple cursor-pointer"  [class.font-semibold]="activeRoute.startsWith('/credit-debit-notes/credit-notes/vendor')" [routerLink]="'/credit-debit-notes/credit-notes/vendor'">
                Credit Notes
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/credit-debit-notes/credit-notes/vendor')" style="width: 5px;"></span>
            </a>
        </li>
    </ng-container>

    <ng-container *ngIf="permissionService.hasPermission('Finance - View Bills in Sidebar')">
        <li class="group relative pb-2.5 text-sm">
            <a class="flex items-center text-purple cursor-pointer" [class.font-semibold]="activeRoute.startsWith('/finance/documents/vendor-invoices')" [routerLink]="'/finance/documents/vendor-invoices'">
                Invoices
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/finance/documents/vendor-invoices')" style="width: 5px;"></span>
            </a>
        </li>
    </ng-container>
</div>

<ng-container *ngIf="permissionService.hasPermission('Finance - View B2B Customer Documents in Sidebar') || permissionService.hasPermission('Finance - Documents - Credit Notes - View Customer in Sidebar') || permissionService.hasPermission('Finance - View Customer Invoices in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showFinanceSubmenu" (click)="this.showB2BDocumentationSubMenu = !this.showB2BDocumentationSubMenu">
        <a class="flex items-center text-purple cursor-pointer">
            B2B Cust. Docs.
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showB2BDocumentationSubMenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showB2BDocumentationSubMenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>

<div class="ml-8 menu__submenu" *ngIf="showB2BDocumentationSubMenu">
    <ng-container *ngIf="permissionService.hasPermission('Finance - Documents - Debit Notes - View Customer in Sidebar')">
        <li class="group relative pb-2.5 text-sm">
            <a class="flex items-center text-purple cursor-pointer"  [class.font-semibold]="activeRoute.startsWith('/credit-debit-notes/debit-notes/tenant')" [routerLink]="'/credit-debit-notes/debit-notes/tenant'">
                Debit Notes
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/credit-debit-notes/debit-notes/tenant')" style="width: 5px;"></span>
            </a>
        </li>
    </ng-container>
    <ng-container *ngIf="permissionService.hasPermission('Finance - Documents - Credit Notes - View Customer in Sidebar')">
        <li class="group relative pb-2.5 text-sm">
            <a class="flex items-center text-purple cursor-pointer"  [class.font-semibold]="activeRoute.startsWith('/credit-debit-notes/credit-notes/tenant')" [routerLink]="'/credit-debit-notes/credit-notes/tenant'">
                Credit Notes
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/credit-debit-notes/credit-notes/tenant')" style="width: 5px;"></span>
            </a>
        </li>
    </ng-container>
    <ng-container *ngIf="permissionService.hasPermission('Finance - View Customer Invoices in Sidebar')">
        <li class="group relative pb-2.5 text-sm">
            <a class="flex items-center text-purple cursor-pointer" [class.font-semibold]="activeRoute.startsWith('/finance/documents/tenant-invoices')" [routerLink]="'/finance/documents/tenant-invoices'">
                Invoices
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/finance/documents/tenant-invoices')" style="width: 5px;"></span>
            </a>
        </li>
    </ng-container>
</div>

<ng-container *ngIf="permissionService.hasPermission('Finance - View Non-Trade in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showFinanceSubmenu" (click)="this.showNonTradeMenu = !this.showNonTradeMenu">
        <a class="flex items-center text-purple cursor-pointer">
            Procurement
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showNonTradeMenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showNonTradeMenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>

<ng-container *ngIf="showNonTradeMenu">
    <div class="ml-8 menu__submenu">
        <ul class="relative pb-2.5">
            <ng-container *ngIf="permissionService.hasPermission('Finance - Core Finance - Can View Purchase Order Upload Processing Statuses')">
                <li class="group relative pb-2.5 text-sm">
                    <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/automation/purchase-order-upload'" [class.font-semibold]="activeRoute.startsWith('/automation/purchase-order-upload')">
                        PO Batch Upload
                        <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('automation/purchase-order-upload')" style="width: 5px;"></span>
                    </a>
                </li>
            </ng-container>

            <ng-container *ngIf="permissionService.hasPermission('Finance - Non-Trade - View Commodities and Services in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <div class="flex cursor-pointer items-center text-purple" [class.font-semibold]="activeRoute.startsWith('/non-trade/commodities-and-services')"
                        (click)="this.showCommoditiesAndServicesSubmenu = !this.showCommoditiesAndServicesSubmenu"
                    >
                        OPEX
                        <div style="flex: 1"></div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showCommoditiesAndServicesSubmenu">
                            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showCommoditiesAndServicesSubmenu">
                            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </li>

                <ng-container *ngIf="showCommoditiesAndServicesSubmenu">
                    <li class="pl-4 group relative pb-2.5 text-sm" *ngIf="permissionService.hasPermission('Finance - Non-Trade - Commodities and Services - View Purchase Requests in Sidebar')">
                        <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/non-trade/commodities-and-services/purchase-requests'" [class.font-semibold]="activeRoute.startsWith('/non-trade/commodities-and-services/purchase-requests')">
                            Purchase Requests
                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/non-trade/commodities-and-services/purchase-requests')" style="width: 5px;"></span>
                        </a>
                    </li>
                    <li class="pl-4 group relative pb-2.5 text-sm" *ngIf="permissionService.hasPermission('Finance - Non-Trade - Commodities and Services - View Purchase Orders in Sidebar')">
                        <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/non-trade/commodities-and-services/purchase-orders'" [class.font-semibold]="activeRoute.startsWith('/non-trade/commodities-and-services/purchase-orders')">
                            Purchase Orders
                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/non-trade/commodities-and-services/purchase-orders')" style="width: 5px;"></span>
                        </a>
                    </li>
                    <li class="pl-4 group relative pb-2.5 text-sm" >
                        <a class="flex items-center text-purple cursor-pointer" *ngIf="permissionService.hasPermission('Finance - Non-Trade - Commodities and Services - View GRN in Sidebar')" [routerLink]="'/non-trade/commodities-and-services/goods-receiving-notes'" [class.font-semibold]="activeRoute.startsWith('/non-trade/commodities-and-services/goods-receiving-notes')">
                            Goods Receiving Notes
                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/non-trade/commodities-and-services/goods-receiving-notes')" style="width: 5px;"></span>
                        </a>
                    </li>
                    <li class="pl-4 group relative pb-2.5 text-sm" >
                        <a class="flex items-center text-purple cursor-pointer" *ngIf="permissionService.hasPermission('Finance - Non-Trade - Commodities and Services - View Invoice in Sidebar')" [routerLink]="'/non-trade/commodities-and-services/invoices'" [class.font-semibold]="activeRoute.startsWith('/non-trade/commodities-and-services/invoices')">
                            Invoices
                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/non-trade/commodities-and-services/invoices')" style="width: 5px;"></span>
                        </a>
                    </li>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="permissionService.hasPermission('Finance - Non-Trade - View Internal Order in Sidebar')">
                <li class="group relative pb-2.5 text-sm">
                    <div class="flex cursor-pointer items-center text-purple"
                        (click)="this.showInternalOrderSubmenu = !this.showInternalOrderSubmenu"
                    >
                        CAPEX
                        <div style="flex: 1"></div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showInternalOrderSubmenu">
                            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showInternalOrderSubmenu">
                            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </li>

                <ng-container *ngIf="showInternalOrderSubmenu">
                    <ng-container *ngIf="permissionService.hasPermission('Finance - Non-Trade - Internal Order - View Internal Orders in Sidebar')">
                        <li class="pl-4 group relative pb-2.5 text-sm" >
                            <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/non-trade/internal-order/internal-orders'" [class.font-semibold]="activeRoute.startsWith('/non-trade/internal-order/internal-orders')">
                                Purchase Requests
                                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/non-trade/internal-order/internal-orders')" style="width: 5px;"></span>
                            </a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="permissionService.hasPermission('Finance - Non-Trade - Internal Order - View Purchase Orders in Sidebar')">
                        <li class="pl-4 group relative pb-2.5 text-sm" >
                            <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/non-trade/internal-order/purchase-orders'" [class.font-semibold]="activeRoute.startsWith('/non-trade/internal-order/purchase-orders')">
                                Purchase Orders
                                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/non-trade/internal-order/purchase-orders')" style="width: 5px;"></span>
                            </a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="permissionService.hasPermission('Finance - Non-Trade - Internal Order - View Goods Receiving Note in Sidebar')">
                        <li class="pl-4 group relative pb-2.5 text-sm" >
                            <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/non-trade/internal-order/goods-receiving-notes'" [class.font-semibold]="activeRoute.startsWith('/non-trade/internal-order/goods-receiving-notes')">
                                Goods Receiving Notes
                                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/non-trade/internal-order/goods-receiving-notes')" style="width: 5px;"></span>
                            </a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="permissionService.hasPermission('Finance - Non-Trade - Internal Order - View Invoices in Sidebar')">
                        <li class="pl-4 group relative pb-2.5 text-sm" >
                            <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/non-trade/internal-order/invoices'" [class.font-semibold]="activeRoute.startsWith('/non-trade/internal-order/invoices')">
                                Invoices
                                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/non-trade/internal-order/invoices')" style="width: 5px;"></span>
                            </a>
                        </li>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</ng-container>

<ng-container *ngIf="permissionService.hasPermission('Finance - View Planning and Forecasting in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showFinanceSubmenu" (click)="this.showPlanningAndForecastingSubmenu = !this.showPlanningAndForecastingSubmenu" >
        <a class="flex items-center text-purple cursor-pointer">
            Planning and Forecasting
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showPlanningAndForecastingSubmenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showPlanningAndForecastingSubmenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>
<div class="ml-8 menu__submenu" *ngIf="showFinanceSubmenu && showPlanningAndForecastingSubmenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Finance - Planning and Forecasting - View Cash Flow in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple" [routerLink]="'/planning-and-forecasting/cash-flow'" [class.font-semibold]="activeRoute.startsWith('/planning-and-forecasting/cash-flow')">
                    Cash Flow Planning
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>

<ng-container *ngIf="permissionService.hasPermission('Finance - View Finance Forms in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showFinanceSubmenu" (click)="this.showFinanceFormsSubMenu = !this.showFinanceFormsSubMenu" >
        <a class="flex items-center text-purple cursor-pointer">
            Finance Forms
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showFinanceFormsSubMenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showFinanceFormsSubMenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>
<div class="ml-8 menu__submenu" *ngIf="showFinanceSubmenu && showFinanceFormsSubMenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Finance - Travel Request - View Travel Requests in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple" [routerLink]="'/travel-requests'" [class.font-semibold]="activeRoute.startsWith('/travel-requests')">
                    Travel Requests
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="permissionService.hasPermission('Finance - Petty Cash - View Petty Cashes in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple" [routerLink]="'/petty-cashes'" [class.font-semibold]="activeRoute.startsWith('/petty-cashes')">
                    Petty Cash Claims
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>
        
        <ng-container *ngIf="permissionService.hasPermission('Finance - View Staff Claim in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple" [routerLink]="'/finance/staff-claim'" [class.font-semibold]="activeRoute.startsWith('/finance/staff-claim')">
                    Staff Claims
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="permissionService.hasPermission('Finance - Cash Advance - View Cash Advance Request in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple" [routerLink]="'/cash-advance/cash-advance-requests'" [class.font-semibold]="activeRoute.startsWith('/cash-advance/cash-advance-requests')">
                    Cash Advance Request
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="permissionService.hasPermission('Finance - Cash Advance - View Cash Advance Clearance in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple" [routerLink]="'/cash-advance/cash-advance-clearances'" [class.font-semibold]="activeRoute.startsWith('/cash-advance/cash-advance-clearances')">
                    Cash Advance Clearance
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>

<ng-container *ngIf="permissionService.hasPermission('Finance - View Automations in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showFinanceSubmenu" (click)="this.showAutomationsSubmenu = !this.showAutomationsSubmenu" >
        <a class="flex items-center text-purple cursor-pointer">
            Automations
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showAutomationsSubmenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showAutomationsSubmenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>

<div class="ml-8 menu__submenu" *ngIf="showAutomationsSubmenu">
    <ul class="relative pb-2.5">
        <li class="group relative pb-2.5 text-sm" *ngIf="permissionService.hasPermission('Finance - Perform Bank Journal Entry Automation')">
            <a class="flex items-center text-purple" [routerLink]="'/bank-auto-journal-entry'" [class.font-semibold]="activeRoute.startsWith('/bank-auto-journal-entry')">
                Bank Float Knock-Off
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;" [class.opacity-100]="activeRoute.startsWith('/bank-auto-journal-entry')"></span>
            </a>
        </li>
    </ul>
</div>


<ng-container *ngIf="permissionService.hasPermission('Cash Books - Can List Cash Books')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showFinanceSubmenu">
        <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/cash-book/cash-books'" [class.font-semibold]="activeRoute.startsWith('/cash-book')">
            Cash Books
            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/cash-book')" style="width: 5px;"></span>
        </a>
    </li>
</ng-container>

<ng-container *ngIf="permissionService.hasPermission('Finance - View Reports in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showFinanceSubmenu" [routerLink]="'/finance/reports'" [class.font-semibold]="activeRoute.startsWith('/finance/reports')">
        <a class="flex items-center text-purple cursor-pointer">
            Reports
            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg opacity-0 group-hover:opacity-100 transition-all pointer-events-none" [class.opacity-100]="activeRoute.startsWith('/finance/reports')" style="width: 5px;"></span>
        </a>
    </li>
</ng-container>
