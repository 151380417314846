<div *ngIf="layout === 'login' || layout === 'wizard' || layout === 'export' || layout === 'electronic'">
    <router-outlet></router-outlet>
</div>
<div *ngIf="layout === 'app'">
    <div class="w-70 h-screen flex fixed top-0 js-menu z-20 xl:z-10 transition-all ltr:-left-80 rtl:-right-80 xl:ltr:left-0 xl:rtl:right-0">
        <nav class="menu-list js-main-menu">
            <div class="main-menu transform">
                <div class="navbar-inner">
                    <img src="/assets/images/client.png" class="m-auto mb-5">
                    <ul class="flex flex-col justify-center">
                        <li class="group relative pb-2.5 text-sm">
                            <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/home'" [class.font-semibold]="activeRoute.startsWith('/home')">
                                <div class="w-8 h-8 flex items-center justify-center mr-3 pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                        <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                                        <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                                    </svg>                                      
                                </div>
                                Home
                                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                    [class.opacity-0]="!activeRoute.startsWith('/home')"
                                ></span>
                            </a>
                        </li>
                        <app-finance-platform-link (clicked)="openLink($event)" *ngIf="permissionService.hasPermission('View Finance in Sidebar')"></app-finance-platform-link>
                        <app-trade-link (clicked)="openLink($event)" *ngIf="permissionService.hasPermission('View Trade in Sidebar')"></app-trade-link>
                        <app-production-link (clicked)="openLink($event)" *ngIf="permissionService.hasPermission('View Production in Sidebar')"></app-production-link>
                        <app-merchandising-inventory-link *ngIf="permissionService.hasPermission('View Merchandising Inventory in Sidebar')"></app-merchandising-inventory-link>
                        <app-partners-link (clicked)="openLink($event)" *ngIf="permissionService.hasPermission('View Partners in Sidebar')"></app-partners-link>
                        <app-hr-link (clicked)="openLink($event)" *ngIf="permissionService.hasPermission('View HR Platform in Sidebar')"></app-hr-link>
                        <app-pos-link (clicked)="openLink($event)" *ngIf="permissionService.hasPermission('View Point of Sale in Sidebar')"></app-pos-link>
                        <app-unmanned-retail-link *ngIf="permissionService.hasPermission('View Unmanned Retail in Sidebar')"></app-unmanned-retail-link>
                        <app-ecommerce-link *ngIf="permissionService.hasPermission('View E-Commerce in Sidebar')"></app-ecommerce-link>
                        <app-office-automation-link *ngIf="permissionService.hasPermission('View Office Automation in Sidebar')"></app-office-automation-link>
                        <app-loyalty-link (clicked)="openLink($event)" *ngIf="permissionService.hasPermission('View Loyalty in Sidebar')"></app-loyalty-link>
                        <app-settings-link></app-settings-link>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
    <div class="main-content transition-all ease-in-out" id="panel" style="margin-left: 14rem">
        <div id="main-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
