import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';
@Component({
  selector: 'app-office-automation-link',
  templateUrl: './office-automation-link.component.html',
  styleUrls: ['./office-automation-link.component.scss']
})
export class OfficeAutomationLinkComponent implements OnInit {

  alert = alert;
  activeRoute = '';
  showSubmenu = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.activeRoute = this.router.url;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(() => {
      this.activeRoute = this.router.url;
    });
  }

}
