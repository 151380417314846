import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SetupCompletedGuard } from './guards/setup-completed.guard';
import { FourOhFourComponent } from './pages/four-oh-four/four-oh-four.component';
import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { PasswordChangedGuard } from './guards/password-changed.guard';
import { routes as bankingRoutes } from './pages/banking/banking.routes';
import { routes as doubleEntryRoutes } from './pages/double-entry/double-entry.routes';
import { routes as costControlRoutes } from './pages/cost-control/cost-control.routes';
import { routes as settingsRoutes } from './pages/settings/settings.routes';
import { routes as merchandisingRoutes } from './pages/merchandising/merchandising.routes';
import { routes as partnersRoutes } from './pages/partners/partners.routes';
import { routes as hrPlatformRoutes } from './pages/hr-platform/hr-platform.routes';
import { routes as budgetManagementRoutes } from './pages/budget-management/budget-management.routes';
import { routes as creditDebitNotesRoutes } from './pages/credit-debit-notes/credit-debit-notes.routes';
import { routes as automationRoutes } from './pages/automation/automation.routes';
import { routes as reportRoutes } from './pages/reports/reports.routes';
import { routes as AssetManagementRoutes } from './pages/asset-management/asset-management.routes';
import { routes as nonTradeRoutes } from './pages/non-trade/non-trade.routes';
import { routes as eLearningRoutes } from './pages/e-learning/e-learning.routes';
import { routes as travelRequestsRoutes } from './pages/travel-requests/travel-requests.routes';
import { routes as pettyCashesRoutes } from './pages/petty-cashes/petty-cashes.routes';
import { routes as CashAdvanceRoutes } from './pages/cash-advance/cash-advance.routes';
import { routes as shopifyOrdersRoutes } from './pages/shopify-orders/shopify-orders.routes';
import { routes as cashBookRoutes } from './pages/finance/cash-book/cash-book.routes';
import { routes as planningAndForecastingRoutes } from './pages/finance/planning-and-forecasting/planning-and-forecasting.routes';
import { routes as B2bCustomerRoutes } from './pages/b2b-customer/b2b-customer.routes';
import { routes as posRoutes } from './pages/pos/pos.routes';
import { routes as TradeRoutes } from './pages/trade/trade.routes'
import { routes as productionRoutes } from './pages/production/production.routes'

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    data: {
      layout: 'login'
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    data: {
      layout: 'login'
    }
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule),
    data: {
      layout: 'login'
    }
  },
  {
    path: 'sso/token-exchange',
    loadChildren: () => import('./pages/sso/token-exchange/token-exchange.module').then(m => m.TokenExchangeModule),
    data: {
      layout: 'login'
    }
  },
  {
    path: 'wizard',
    loadChildren: () => import('./pages/wizard/wizard.module').then(m => m.WizardModule),
    data: {
      layout: 'wizard'
    },
    canActivate: [
      IsLoggedInGuard,
      PasswordChangedGuard,
    ]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard,
    ]
  },
  {
    path: 'export-pdf-balance-sheet',
    loadChildren: () => import('./pages/export/pdf-balance-sheet/pdf-balance-sheet.module').then(m => m.PDFBalanceSheetModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'export-pdf-profit-and-loss',
    loadChildren: () => import('./pages/export/pdf-profit-and-loss/pdf-profit-and-loss.module').then(m => m.PDFProfitAndLossModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'export-pdf-cns-purchase-order',
    loadChildren: () => import('./pages/export/export-pdf-cns-purchase-order/export-pdf-cns-purchase-order.module').then(m => m.ExportPdfCnsPurchaseOrderModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'export-pdf-cns-packing-list',
    loadChildren: () => import('./pages/export/export-pdf-cns-packing-list/export-pdf-cns-packing-list.module').then(m => m.ExportPdfPackingListModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'export-pdf-io-packing-list',
    loadChildren: () => import('./pages/export/export-pdf-io-packing-list/export-pdf-io-packing-list.module').then(m => m.ExportPdfPackingListModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'export-pdf-io-customer-invoice',
    loadChildren: () => import('./pages/export/export-pdf-io-customer-invoice/export-pdf-io-customer-invoice.module').then(m => m.ExportPdfCustomerInvoiceModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'export-pdf-cns-customer-invoice',
    loadChildren: () => import('./pages/export/export-pdf-cns-customer-invoice/export-pdf-cns-customer-invoice.module').then(m => m.ExportPdfCustomerInvoiceModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'export-pdf-io-purchase-order',
    loadChildren: () => import('./pages/export/export-pdf-io-purchase-order/export-pdf-io-purchase-order.module').then(m => m.ExportPdfIoPurchaseOrderModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'export-pdf-payment-proposal',
    loadChildren: () => import('./pages/export/export-pdf-payment-proposal/export-pdf-payment-proposal.module').then(m => m.ExportPDFPaymentProposalModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'structural/export-pdf-goods-receiving-note',
    loadChildren: () => import('./pages/electronic/structural/export-pdf-goods-receiving-note/export-pdf-goods-receiving-note.module').then(m => m.ExportPDFGoodsReceivingNoteModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'commodities-and-services/export-pdf-goods-receiving-note',
    loadChildren: () => import('./pages/electronic/commodity-and-service-purchase-orders/export-pdf-goods-receiving-note/export-pdf-goods-receiving-note.module').then(m => m.ExportPDFGoodsReceivingNoteModule),
    data: {
      layout: 'export'
    }
  },
  {
    path: 'customer-e-invoice/:documentNumber',
    loadChildren: () => import('./pages/electronic/customer-e-invoice/customer-e-invoice.module').then(m => m.CustomerEInvoiceModule),
    data: {
      layout: 'electronic'
    }
  },
  {
    path: 'customer-e-invoice/print-pdf/:documentNumber',
    loadChildren: () => import('./pages/electronic/customer-e-invoice/print-pdf/print-pdf.module').then(m => m.PrintPdfModule),
    data: {
      layout: 'electronic'
    }
  },
  {
    path: 'commodities-and-services/e-po/:poNumber',
    loadChildren: () => import('./pages/electronic/commodity-and-service-purchase-orders/e-po/e-po.module').then(m => m.EPoModule),
    data: {
      layout: 'electronic'
    }
  },
  {
    path: 'commodities-and-services/e-po/print-pdf/:poNumber',
    loadChildren: () => import('./pages/electronic/commodity-and-service-purchase-orders/e-po/export-pdf/export-pdf.module').then(m => m.ExportPdfModule),
    data: {
      layout: 'electronic'
    }
  },
  {
    path: 'structural/e-po/:poNumber',
    loadChildren: () => import('./pages/electronic/structural/e-po/e-po.module').then(m => m.EPoModule),
    data: {
      layout: 'electronic'
    }
  },
  {
    path: 'structural/e-po/print-pdf/:poNumber',
    loadChildren: () => import('./pages/electronic/structural/e-po/export-pdf/export-pdf.module').then(m => m.ExportPdfModule),
    data: {
      layout: 'electronic'
    }
  },

  {
    path: 'merchandising/export-pdf-receiving-draft',
    loadChildren: () => import('./pages/electronic/merchandising/receiving/draft-receiving/export-pdf/export-pdf.module').then(m => m.ExportPdfModule),
    data: {
      layout: 'export'
    }
  },

  {
    path: 'bank-auto-journal-entry',
    loadChildren: () => import('./pages/bank-automation/bank-auto-journal-entry/bank-auto-journal-entry.module').then(m => m.BankAutoJournalEntryModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'merchandising/export-pdf-receiving',
    loadChildren: () => import('./pages/electronic/merchandising/receiving/receiving/export-pdf/export-pdf.module').then(m => m.ExportPdfModule),
    data: {
      layout: 'export'
    }
  },

  {
    path: 'merchandising/export-pdf-po',
    loadChildren: () => import('./pages/merchandising/purchasing/purchase-order/export-pdf/export-pdf.module').then(m => m.ExportPdfModule),
    data: {
      layout: 'export'
    }
  },

  {
    path: 'merchandising/export-pdf-pr',
    loadChildren: () => import('./pages/merchandising/purchasing/purchase-request-v2/export-pdf/export-pdf.module').then(m => m.ExportPdfModule),
    data: {
      layout: 'export'
    }
  },

  // Public link for download po pdf
  {
    path: 'merchandising/purchase-order/download-pdf/:poNumber',
    loadChildren: () => import('./pages/merchandising/purchasing/purchase-order/export-pdf/export-pdf.module').then(m => m.ExportPdfModule),
    data: {
      layout: 'export'
    }
  },

  {
    path: 'merchandising/export-pdf-return',
    loadChildren: () => import('./pages/merchandising/purchasing/refunds/export-pdf/export-pdf.module').then(m => m.ExportPdfModule),
    data: {
      layout: 'export'
    }
  },

  // Banking
  ...bankingRoutes,

  // Double Entry
  ...doubleEntryRoutes,

  // Cost Control
  ...costControlRoutes,

  // Asset Management
  ...AssetManagementRoutes,

  // Merchandising
  ...merchandisingRoutes,

  // Partners
  ...partnersRoutes,

  // Settings
  ...settingsRoutes,

  // HR Platform
  ...hrPlatformRoutes,

  // Budget management
  ...budgetManagementRoutes,

  // Credit debit notes
  ...creditDebitNotesRoutes,

  // Automation
  ...automationRoutes,

  // Credit debit notes
  ...reportRoutes,

  // Non Trade Routes
  ...nonTradeRoutes,

  // E-Learning
  ...eLearningRoutes,

  // Travel Request Routes
  ...travelRequestsRoutes,

  // B2B Customers Routes
  ...B2bCustomerRoutes,

  // Trade Routes
  ...TradeRoutes,

  // Staff Claim
  {
      path: 'finance/staff-claim',
      loadChildren: () => import('./pages/staff-claim/staff-claim.module').then(m => m.StaffClaimModule),
      data: {
        layout: 'app'
      },
      canActivate: [
        IsLoggedInGuard,
        SetupCompletedGuard,
        PasswordChangedGuard
      ]
  },

  // Petty Cash Routes
  ...pettyCashesRoutes,

  // CashAdvanceRoutes
  ...CashAdvanceRoutes,
  // POS
  ...posRoutes,

  // Invoice e-payment
  {
    path: 'invoice-epayment/:invoiceUuid',
    loadChildren: () => import('./pages/invoice-epayment/invoice-epayment.module').then(m => m.InvoiceEpaymentModule),
    data: {
      layout: 'login'
    },
    canActivate: [
      SetupCompletedGuard,
      PasswordChangedGuard,
    ]
  },

  // Shopify Orders Routes
  ...shopifyOrdersRoutes,

  // Cash Book
  ...cashBookRoutes,

  // Planning and Forecasting
  ...planningAndForecastingRoutes,

  // Production
  ...productionRoutes,

  { path: '**', pathMatch: 'full', component: FourOhFourComponent, data: { layout: 'login' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
