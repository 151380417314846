<div class="p-4">
    <div class="my-4 rounded-lg w-100 bg-red-100 p-5 text-base text-red-700" *ngIf="errorMessage !== null && errorMessage !== undefined && errorMessage.length > 0">
        {{ errorMessage }}
    </div>

    <div class="pt-2" id="form">
        <!-- Form -->
        <div class="flex">
            <div class="grid grid-cols-2 gap-x-8 gap-y-6 pt-5" style="flex: 1">
                <div class="form-group relative required">
                    <label for="entity" class="form-control-label">
                        Entity
                    </label>
                    <div class="input-group input-group-merge input-group-alternative">
                        <p class="pt-2 text-sm" id="fetching_entities" style="display: none">Fetching Entities...</p>
                        <div class="pt-1">
                            <select name="entity_id" id="entities">
                                <option value="">Select Entity</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group relative">
                    <label for="date" class="form-control-label">
                        Date
                    </label>
                    <div class="input-group input-group-merge input-group-alternative">
                        <input type="date" class="form-element" [(ngModel)]="date" (change)="getData()">
                    </div>
                </div>
            </div>
            <div class="ml-4 contains-button">
                <button type="submit" class="relative flex items-center justify-center bg-green hover:bg-green-700 text-white px-6 py-1.5 text-base rounded-lg disabled:bg-green-100 w-full"
                    [class.opacity-50]="reportLoading"
                    [class.cursor-not-allowed]="reportLoading"
                    style="margin-top: 50px"
                >
                    <span *ngIf="!reportLoading">Generate</span>
                    <span *ngIf="reportLoading">Generating...</span>
                </button>
            </div>
        </div>
    </div>

    <div class="py-4">
        <hr>
    </div>

    <ng-container *ngIf="reportGenerated">
        <ng-container *ngIf="reportLoading">
            <p>Loading report...</p>
        </ng-container>
    </ng-container>

    <div class="border border-black px-4" style="overflow-x: scroll;">
        <table class="table">
            <tbody>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="padding-top: 10px !important; padding-bottom: 10px !important; text-align: center; border-top: 2px dashed red; border-right: 2px dashed red; border-left: 2px dashed red">([[monthNumber]] month)</td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="padding-top: 10px !important; padding-bottom: 10px !important; text-align: center;">([[monthNumber]] month)</td>
                    <td class="text-xs"></td>
                    <td class="text-xs"></td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="padding-top: 10px !important; padding-bottom: 10px !important; text-align: center;">([[monthNumber]] month)</td>
                    <td class="text-xs" colspan="2"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">Actual</div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-left: 2px dashed transparent; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">Budget</div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-top: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;"></td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid transparent; border-left: 2px dashed transparent; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">Actual</div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; border-top: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;"></td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">YTD [[month]] [[year]]</div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-left: 2px dashed transparent; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">YTD [[month]] [[year]]</div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px; font-weight: bold;">Variance</div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; border-left: 2px dashed transparent; border-right: 2px dashed transparent; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">YTD [[month]] [[lastYear]]</div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px; font-weight: bold;">Variance</div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>RM ('000)</div>
                                <div>%</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-left: 2px dashed transparent; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>RM ('000)</div>
                                <div>%</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="font-weight: bold; padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>RM ('000)</div>
                                <div>%</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; border-left: 2px dashed transparent; border-right: 2px dashed transparent; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="background: rgb(0, 33, 96); color: white; padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>RM ('000)</div>
                                <div>%</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="display: grid; grid-template-columns: 1fr 1fr; font-weight: bold;">
                            <div>RM ('000)</div>
                            <div>%</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;" colspan="2">Sales</td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;" colspan="2">COGS</td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-bottom: 1px solid black">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-bottom: 1px solid black">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-bottom: 1px solid black">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;" colspan="2">
                        <div style="padding-bottom: 20px;">
                            Gross Profit (GP)
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;">OPEX</td>
                    <td class="text-xs">Employment</td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs"></td>
                    <td class="text-xs">Marketing</td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs"></td>
                    <td class="text-xs">Rental</td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs"></td>
                    <td class="text-xs">Others</td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-top: 1px solid black; border-bottom: 1px solid black;">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-top: 1px solid black; border-bottom: 1px solid black;">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-top: 1px solid black; border-bottom: 1px solid black;">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-top: 1px solid black; border-bottom: 1px solid black;">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;" colspan="2">
                        <div style="padding-bottom: 10px;">EBITDA</div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs"></td>
                    <td class="text-xs">Interest</td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs"></td>
                    <td class="text-xs">Depreciation</td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 5px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs"></td>
                    <td class="text-xs">
                        <div style='padding-bottom: 20px'>Amortisation</div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 20px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;" colspan="2">
                        <div style="padding-bottom: 10px;">Earning Before Tax</div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-top: 1px solid black">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-top: 1px solid black">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-top: 1px solid black">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;" colspan="2">
                        <div style="padding-bottom: 10px;">Taxation</div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;" colspan="2">
                        <div style="padding-bottom: 10px;">Profit After Tax</div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-top: 1px solid black; border-bottom: 1px double black">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-top: 1px solid black; border-bottom: 1px double black">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; border-bottom: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" colspan="2"></td>
                    <td class="text-xs" style="text-align: center; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div style="border-top: 1px solid black; border-bottom: 1px double black">N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px solid black; border-left: 2px solid black; border-bottom: 2px solid black; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div>N/A</div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;" colspan="2">
                        <div style="padding-bottom: 10px;">Gp %</div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td colspan="2"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;" colspan="2">
                        <div style="padding-bottom: 10px;">PBT %</div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td colspan="2"></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td></td>
                    <td class="text-xs" style="text-align: center; border-right: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>

                </tr>
                <tr>
                    <td class="text-xs" style="font-weight: bold;" colspan="2">
                        <div style="padding-bottom: 10px;">PAT %</div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-bottom: 2px dashed red; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs"></td>
                    <td class="text-xs" style="text-align: center; border-bottom: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-xs" style="text-align: center; border-bottom: 2px dashed red; border-right: 2px dashed red; border-left: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td colspan="2"></td>
                    <td class="text-xs" style="text-align: center; border-bottom: 2px dashed red; border-right: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>
                    <td></td>
                    <td class="text-xs" style="text-align: center; border-bottom: 2px dashed red; border-right: 2px dashed red; padding-left: 5px !important; padding-right: 5px !important; text-align: center;">
                        <div style="padding-top: 5px; padding-bottom: 10px;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                <div></div>
                                <div>N/A</div>
                            </div>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</div>