<div class="p-4">
    <div class="my-4 rounded-lg w-100 bg-red-100 p-5 text-base text-red-700" *ngIf="errorMessage !== null && errorMessage !== undefined && errorMessage.length > 0">
        {{ errorMessage }}
    </div>

    <div class="pt-2" id="form">
        <!-- Form -->
        <div class="flex">
            <div class="grid grid-cols-2 gap-x-8 gap-y-6 pt-5" style="flex: 1">
                <div class="form-group relative required">
                    <label for="entity" class="form-control-label">
                        Entity
                    </label>
                    <div class="input-group input-group-merge input-group-alternative">
                        <p class="pt-2 text-sm" id="fetching_entities" style="display: none">Fetching Entities...</p>
                        <div class="pt-1">
                            <select name="entity_id" id="entities">
                                <option value="">Select Entity</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group relative">
                    <label for="date" class="form-control-label">
                        Date
                    </label>
                    <div class="input-group input-group-merge input-group-alternative">
                        <input type="date" class="form-element" [(ngModel)]="date" (change)="getData()">
                    </div>
                </div>
            </div>
            <div class="ml-4 contains-button">
                <button type="submit" class="relative flex items-center justify-center bg-green hover:bg-green-700 text-white px-6 py-1.5 text-base rounded-lg disabled:bg-green-100 w-full"
                    [class.opacity-50]="reportLoading"
                    [class.cursor-not-allowed]="reportLoading"
                    style="margin-top: 50px"
                >
                    <span *ngIf="!reportLoading">Generate</span>
                    <span *ngIf="reportLoading">Generating...</span>
                </button>
            </div>
        </div>
    </div>

    <div class="py-4">
        <hr>
    </div>

    <ng-container *ngIf="reportGenerated">
        <ng-container *ngIf="reportLoading">
            <p>Loading report...</p>
        </ng-container>
    </ng-container>

    <div style="overflow-x: scroll;">
        <div class="container">
            <table class="table table-bordered mt-5 border-dark text-center">
                <thead>
                    <tr>
                        <th scope="col" class="bg-blue text-white">No</th>
                        <th scope="col" class="bg-blue text-white">Supplier</th>
                        <th scope="col" class="bg-blue text-white">Location</th>
                        <th scope="col" class="bg-blue text-white">Duration</th>
                        <th scope="col" class="bg-blue text-white">Amount (RM)</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td scope="row">1</td>
                    <td>Allow MTD</td>
                    <td>Petron Bangi, MMR2</td>
                    <td>12 month</td>
                    <td>23,123</td>
                </tr>
                <tr>
                    <td scope="row">2</td>
                    <td>Media House</td>
                    <td>Alor Setar, Kulim Seberang Prai</td>
                    <td>12 month</td>
                    <td>173,123</td>
                </tr>
                <tr>
                    <td scope="row">3</td>
                    <td>Toutdoors</td>
                    <td>Federal, Ipoh</td>
                    <td>12 Month</td>
                    <td>206,123</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>