import { Routes } from '@angular/router';
import { IsLoggedInGuard } from 'src/app/guards/is-logged-in.guard';
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';
import { SetupCompletedGuard } from 'src/app/guards/setup-completed.guard';

export const routes: Routes = [
  {
    path: 'cash-advance/cash-advance-requests',
    loadChildren: () =>
      import('./cash-advance-requests/cash-advance-requests.module').then((m) => m.CashAdvanceRequestsModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'cash-advance/cash-advance-requests/create',
    loadChildren: () =>
      import('./cash-advance-requests/create/create.module').then((m) => m.CreateModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'cash-advance/cash-advance-requests/:id',
    loadChildren: () =>
      import('./cash-advance-requests/show/show.module').then((m) => m.ShowModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'cash-advance/cash-advance-requests/:id/edit',
    loadChildren: () =>
      import('./cash-advance-requests/edit/edit.module').then((m) => m.EditModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'cash-advance/cash-advance-requests/:id/print',
    loadChildren: () =>
      import('./cash-advance-requests/print/print.module').then((m) => m.PrintModule),
    data: {
      layout: 'export',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'cash-advance/cash-advance-clearances',
    loadChildren: () =>
      import('./cash-advance-clearances/cash-advance-clearances.module').then((m) => m.CashAdvanceClearancesModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'cash-advance/cash-advance-clearances/create',
    loadChildren: () =>
      import('./cash-advance-clearances/create/create.module').then((m) => m.CreateModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'cash-advance/cash-advance-clearances/:id',
    loadChildren: () =>
      import('./cash-advance-clearances/show/show.module').then((m) => m.ShowModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'cash-advance/cash-advance-clearances/:id/edit',
    loadChildren: () =>
      import('./cash-advance-clearances/edit/edit.module').then((m) => m.EditModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'cash-advance/cash-advance-clearances/:id/print',
    loadChildren: () =>
      import('./cash-advance-clearances/print/print.module').then((m) => m.PrintModule),
    data: {
      layout: 'export',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
];
