import { Routes } from '@angular/router';
import { IsLoggedInGuard } from 'src/app/guards/is-logged-in.guard';
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';
import { SetupCompletedGuard } from 'src/app/guards/setup-completed.guard';

export const routes: Routes = [
  {
    path: 'cash-book/cash-books',
    loadChildren: () =>
      import('./cash-book.module').then((m) => m.CashBookModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
  {
    path: 'cash-book/cash-books/:cashBookId',
    loadChildren: () => import('./view-cash-book/view-cash-book.module').then(m => m.ViewCashBookModule),
    data: {
      layout: 'app'
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  }
];
