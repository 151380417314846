import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-trade-link',
  templateUrl: './trade-link.component.html',
  styleUrls: ['./trade-link.component.scss']
})
export class TradeLinkComponent {

  @Output() clicked = new EventEmitter<string>();

  alert = alert;
  showTradeSubmenu = false;
  activeRoute = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public permissionService: PermissionService
  ) {}

  handleTradeSubmenu() {
    this.showTradeSubmenu = !this.showTradeSubmenu;
    if (! this.showTradeSubmenu) {
      
    }
  }

  ngOnInit() {
    this.activeRoute = this.router.url;
    if (this.activeRoute.startsWith('/trade')) {
      this.showTradeSubmenu = true;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(() => {
      this.activeRoute = this.router.url;
    });
  }
}
