import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { PasswordChangedGuard } from "src/app/guards/password-changed.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";

export const routes: Routes = [
    {
        path: 'double-entry/journal-entries',
        loadChildren: () => import('./journal-entry/journal-entry.module').then(m => m.JournalEntryModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'double-entry/journal-entries/create',
        loadChildren: () => import('./journal-entry/create/create.module').then(m => m.CreateModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'double-entry/journal-entries/view/:id',
        loadChildren: () => import('./journal-entry/view/view.module').then(m => m.ViewModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'double-entry/journal-entries/edit/:id',
        loadChildren: () => import('./journal-entry/edit/edit.module').then(m => m.EditModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },

	{
        path: 'double-entry/journal-entry-drafts',
        loadChildren: () => import('./journal-entry-draft/journal-entry-draft.module').then(m => m.JournalEntryDraftModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'double-entry/journal-entry-drafts/create',
        loadChildren: () => import('./journal-entry-draft/create/create.module').then(m => m.CreateModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'double-entry/journal-entry-drafts/view/:id',
        loadChildren: () => import('./journal-entry-draft/view/view.module').then(m => m.ViewModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'double-entry/journal-entry-drafts/edit/:id',
        loadChildren: () => import('./journal-entry-draft/edit/edit.module').then(m => m.EditModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },

    {
        path: 'double-entry/chart-of-accounts',
        loadChildren: () => import('./chart-of-accounts/chart-of-accounts.module').then(m => m.ChartOfAccountsModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
      path: 'double-entry/chart-of-accounts/create',
      loadChildren: () => import('./chart-of-accounts/create/create.module').then(m => m.CreateModule),
      data: {
        layout: 'app'
      },
      canActivate: [
        IsLoggedInGuard,
        SetupCompletedGuard,
        PasswordChangedGuard
      ]
    },
    {
      path: 'double-entry/chart-of-accounts/view/:id',
      loadChildren: () => import('./chart-of-accounts/view/view.module').then(m => m.ViewModule),
      data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
      ]
    },
    {
        path: 'double-entry/profit-and-loss',
        loadChildren: () => import('./profit-and-loss/profit-and-loss.module').then(m => m.ProfitAndLossModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },
    {
        path: 'double-entry/balance-sheet',
        loadChildren: () => import('./balance-sheet/balance-sheet.module').then(m => m.BalanceSheetModule),
        data: {
          layout: 'app'
        },
        canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
        ]
    },

    {
      path: 'finance/reports/accounts-payable',
      loadChildren: () => import('./accounts-payable/accounts-payable.module').then(m => m.AccountsPayableModule),
      data: {
        layout: 'app'
      },
      canActivate: [
        IsLoggedInGuard,
        SetupCompletedGuard,
        PasswordChangedGuard
      ]
    },

    {
      path: 'finance/reports/accounts-receivable',
      loadChildren: () => import('./accounts-receivable/accounts-receivable.module').then(m => m.AccountsReceivableModule),
      data: {
        layout: 'app'
      },
      canActivate: [
        IsLoggedInGuard,
        SetupCompletedGuard,
        PasswordChangedGuard
      ]
    },

    {
      path: 'double-entry/segments',
      loadChildren: () => import('./segments/segments.module').then(m => m.SegmentsModule),
      data: {
        layout: 'app'
      },
      canActivate: [
        IsLoggedInGuard,
        SetupCompletedGuard,
        PasswordChangedGuard
      ]
    },

    {
      path: 'double-entry/segments/create',
      loadChildren: () => import('./segments/create/create.module').then(m => m.CreateModule),
      data: {
        layout: 'app'
      },
      canActivate: [
        IsLoggedInGuard,
        SetupCompletedGuard,
        PasswordChangedGuard
      ]
    },

    {
      path: 'double-entry/segments/view/:id',
      loadChildren: () => import('./segments/view/view.module').then(m => m.ViewModule),
      data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard
      ]
    },
];
