import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FinancePlatformLinkModule } from './components/sidebar-menu/links/finance-platform-link/finance-platform-link.module';
import { TradeLinkModule } from './components/sidebar-menu/links/trade-link/trade-link.module';
import { SettingsLinkModule } from './components/sidebar-menu/links/settings-link/settings-link.module';
import { MerchandisingInventoryLinkModule } from './components/sidebar-menu/links/merchandising-inventory-link/merchandising-inventory-link.module';
import { PartnersLinkModule } from './components/sidebar-menu/links/partners-link/partners-link.module';
import { OfficeAutomationLinkModule } from './components/sidebar-menu/links/office-automation-link/office-automation-link.module';
import { HrLinkModule } from './components/sidebar-menu/links/hr-link/hr-link.module';
import { PosLinkModule } from './components/sidebar-menu/links/pos-link/pos-link.module';
import { EcommerceLinkModule } from './components/sidebar-menu/links/ecommerce-link/ecommerce-link.module';
import { LoyaltyLinkModule } from './components/sidebar-menu/links/loyalty-link/loyalty-link.module';
import { UnmannedRetailLinkModule } from './components/sidebar-menu/links/unmanned-retail-link/unmanned-retail-link.module';
import { HttpClientModule } from '@angular/common/http';
import { ProductionLinkModule } from './components/sidebar-menu/links/production-link/production-link.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FinancePlatformLinkModule,
    TradeLinkModule,
    SettingsLinkModule,
    MerchandisingInventoryLinkModule,
    PartnersLinkModule,
    OfficeAutomationLinkModule,
    HrLinkModule,
    PosLinkModule,
    EcommerceLinkModule,
    LoyaltyLinkModule,
    UnmannedRetailLinkModule,
    ProductionLinkModule,
    HttpClientModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
