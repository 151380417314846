import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosLinkComponent } from './pos-link.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    PosLinkComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    PosLinkComponent
  ]
})
export class PosLinkModule { }
