<div class="p-4">
    <div class="my-4 rounded-lg w-100 bg-red-100 p-5 text-base text-red-700" *ngIf="errorMessage !== null && errorMessage !== undefined && errorMessage.length > 0">
        {{ errorMessage }}
    </div>

    <div class="pt-2" id="form">
        <!-- Form -->
        <div class="flex">
            <div class="grid grid-cols-2 gap-x-8 gap-y-6 pt-5" style="flex: 1">
                <div class="form-group relative required">
                    <label for="entity" class="form-control-label">
                        Entity
                    </label>
                    <div class="input-group input-group-merge input-group-alternative">
                        <p class="pt-2 text-sm" id="fetching_entities" style="display: none">Fetching Entities...</p>
                        <div class="pt-1">
                            <select name="entity_id" id="entities">
                                <option value="">Select Entity</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group relative">
                    <label for="date" class="form-control-label">
                        Date
                    </label>
                    <div class="input-group input-group-merge input-group-alternative">
                        <input type="date" class="form-element" [(ngModel)]="date" (change)="getData()">
                    </div>
                </div>
            </div>
            <div class="ml-4 contains-button">
                <button type="submit" class="relative flex items-center justify-center bg-green hover:bg-green-700 text-white px-6 py-1.5 text-base rounded-lg disabled:bg-green-100 w-full"
                    [class.opacity-50]="reportLoading"
                    [class.cursor-not-allowed]="reportLoading"
                    style="margin-top: 50px"
                >
                    <span *ngIf="!reportLoading">Generate</span>
                    <span *ngIf="reportLoading">Generating...</span>
                </button>
            </div>
        </div>
    </div>

    <div class="py-4">
        <hr>
    </div>

    <ng-container *ngIf="reportGenerated">
        <ng-container *ngIf="reportLoading">
            <p>Loading report...</p>
        </ng-container>
    </ng-container>

    <div style="overflow-x: scroll;">
        <table class="table">
            <tbody>
                <tr>
                    <td rowspan="2" style="text-align: center;"><strong style="text-decoration: underline;">TBRG</strong></td>
                    <td rowspan="2" style="text-align: center; background-color: #CAFECA">YTD <br /> Dec-20</td>
                    <td rowspan="2" style="text-align: center; background-color: #B4C4E7;">YTD <br /> Dec-21</td>
                    <td rowspan="2" style="text-align: center; background-color: #CBFFFF;">YTD <br /> Dec-22</td>
                    <td rowspan="2" style="text-align: center; background-color: #F8CBAC;">YTD <br /> Dec-23</td>
                </tr>
                <tr></tr>
                <tr>
                    <td style="background-color: #00215C; color: white;"><strong>RATIOS</strong></td>
                    <td style="text-align: right; background-color: #CAFECA;"></td>
                    <td style="text-align: right; background-color: #B4C4E7;"></td>
                    <td style="text-align: right; background-color: #CBFFFF;"></td>
                    <td style="text-align: right; background-color: #F8CBAC;"></td>
                </tr>
                <tr>
                    <td colspan="5" style="height: 25px !important;"></td>
                </tr>
                <tr>
                    <td><strong>EBITDA</strong></td>
                    <td style="text-align: right; background-color: #CAFECA"><strong>13578513</strong></td>
                    <td style="text-align: right; background-color: #B4C4E7;"><strong>17756502</strong></td>
                    <td style="text-align: right; background-color: #CBFFFF;"><strong>22128605</strong></td>
                    <td style="text-align: right; background-color: #F8CBAC;"><strong>13507374</strong></td>
                </tr>
                <tr>
                    <td>Depreciation</td>
                    <td style="text-align: right; background-color: #CAFECA">(1243097)</td>
                    <td style="text-align: right; background-color: #B4C4E7;">(913972)</td>
                    <td style="text-align: right; background-color: #CBFFFF;">(1141013)</td>
                    <td style="text-align: right; background-color: #F8CBAC;">(1233829)</td>
                </tr>
                <tr>
                    <td>Amortisation</td>
                    <td style="text-align: right; background-color: #CAFECA;">(23842)</td>
                    <td style="text-align: right; background-color: #B4C4E7;">(25951)</td>
                    <td style="text-align: right; background-color: #CBFFFF;">(23100)</td>
                    <td style="text-align: right; background-color: #F8CBAC;">(4311034)</td>
                </tr>
                <tr>
                    <td>EBIT</td>
                    <td style="text-align: right; background-color: #CAFECA; border-top: 1px solid black;">12311275</td>
                    <td style="text-align: right; background-color: #B4C4E7; border-top: 1px solid black;">16816940</td>
                    <td style="text-align: right; background-color: #CBFFFF; border-top: 1px solid black;">20964232</td>
                    <td style="text-align: right; background-color: #F8CBAC; border-top: 1px solid black;">7962510</td>
                </tr>
                <tr>
                    <td>Interest</td>
                    <td style="text-align: right; background-color: #CAFECA">(372764)</td>
                    <td style="text-align: right; background-color: #B4C4E7;">(556716)</td>
                    <td style="text-align: right; background-color: #CBFFFF;">(997943)</td>
                    <td style="text-align: right; background-color: #F8CBAC;">(979832)</td>
                </tr>
                <tr>
                    <td>Earning Before Tax</td>
                    <td style="text-align: right; background-color: #CAFECA; border-top: 1px solid black;">11938811</td>
                    <td style="text-align: right; background-color: #B4C4E7; border-top: 1px solid black;">11602242</td>
                    <td style="text-align: right; background-color: #CBFFFF; border-top: 1px solid black;">19660556</td>
                    <td style="text-align: right; background-color: #F8CBAC; border-top: 1px solid black;">6982676</td>
                </tr>
                <tr>
                    <td>Taxation</td>
                    <td style="text-align: right; background-color: #CAFECA">(2827852)</td>
                    <td style="text-align: right; background-color: #B4C4E7;">(5165392)</td>
                    <td style="text-align: right; background-color: #CBFFFF;">(7082757)</td>
                    <td style="text-align: right; background-color: #F8CBAC;">(1676600)</td>
                </tr>
                <tr>
                    <td>Earning After Tax</td>
                    <td style="text-align: right; background-color: #CAFECA; border-top: 1px solid black;">9110959</td>
                    <td style="text-align: right; background-color: #B4C4E7; border-top: 1px solid black;">11094832</td>
                    <td style="text-align: right; background-color: #CBFFFF; border-top: 1px solid black;">12883793</td>
                    <td style="text-align: right; background-color: #F8CBAC; border-top: 1px solid black;">5306578</td>
                </tr>
                <tr>
                    <td></td>
                    <td style="text-align: right; background-color: #CAFECA; border-top: 5px double black;">-</td>
                    <td style="text-align: right; background-color: #B4C4E7; border-top: 5px double black;">-</td>
                    <td style="text-align: right; background-color: #CBFFFF; border-top: 5px double black;">-</td>
                    <td style="text-align: right; background-color: #F8CBAC; border-top: 5px double black;">-</td>
                </tr>
                <tr>
                    <td colspan="5" style="height: 25px !important;"></td>
                </tr>
                <tr>
                    <td>ST Debts</td>
                    <td style="text-align: right; background-color: #CAFECA">1694854</td>
                    <td style="text-align: right; background-color: #B4C4E7;">1131855</td>
                    <td style="text-align: right; background-color: #CBFFFF;">14169378</td>
                    <td style="text-align: right; background-color: #F8CBAC;">12185668</td>
                </tr>
                <tr>
                    <td>LT Debts</td>
                    <td style="text-align: right; background-color: #CAFECA">10722761</td>
                    <td style="text-align: right; background-color: #B4C4E7;">10024228</td>
                    <td style="text-align: right; background-color: #CBFFFF;">9754904</td>
                    <td style="text-align: right; background-color: #F8CBAC;">37955743</td>
                </tr>
                <tr>
                    <td>Total Debts</td>
                    <td style="text-align: right; background-color: #CAFECA; border-top: 1px solid black;">12417615</td>
                    <td style="text-align: right; background-color: #B4C4E7; border-top: 1px solid black;">11146082</td>
                    <td style="text-align: right; background-color: #CBFFFF; border-top: 1px solid black;">23764083</td>
                    <td style="text-align: right; background-color: #F8CBAC; border-top: 1px solid black;">50141110</td>
                </tr>
                <tr>
                    <td style="height: 25px !important;"></td>
                    <td colspan="4" style="height: 25px !important; border-top: 5px double black;"></td>
                </tr>
                <tr>
                    <td>ST Debts</td>
                    <td style="text-align: right; background-color: #CAFECA">14%</td>
                    <td style="text-align: right; background-color: #B4C4E7;">10%</td>
                    <td style="text-align: right; background-color: #CBFFFF;">60%</td>
                    <td style="text-align: right; background-color: #F8CBAC;">24%</td>
                </tr>
                <tr>
                    <td>LT Debts</td>
                    <td style="text-align: right; background-color: #CAFECA">86%</td>
                    <td style="text-align: right; background-color: #B4C4E7;">90%</td>
                    <td style="text-align: right; background-color: #CBFFFF;">40%</td>
                    <td style="text-align: right; background-color: #F8CBAC;">76%</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>