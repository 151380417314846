import { Routes } from '@angular/router';
import { IsLoggedInGuard } from 'src/app/guards/is-logged-in.guard';
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';
import { SetupCompletedGuard } from 'src/app/guards/setup-completed.guard';

export const routes: Routes = [
  {
    path: 'shopify-orders',
    loadChildren: () =>
      import('./shopify-orders.module').then((m) => m.shopifyOrdersModule),
    data: {
      layout: 'app',
    },
    canActivate: [IsLoggedInGuard, SetupCompletedGuard, PasswordChangedGuard],
  },
];
