<li class="group relative pb-2.5 text-sm" (click)="handleSubmenu()">
    <a class="flex items-center text-purple cursor-pointer">
        <div class="w-8 h-8 flex items-center justify-center mr-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
        </div>
        Merchandising
        <div style="flex: 1"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showSubmenu">
            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showSubmenu">
            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
        </svg>
    </a>
</li>
<ng-container *ngIf="permissionService.hasPermission('Merchandising - View Product Hierarchy in Sidebar') && false">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" (click)="this.showHierarchySubmenu = !this.showHierarchySubmenu">
        <a class="flex items-center text-purple cursor-pointer">
            Product Hierarchy
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showHierarchySubmenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showHierarchySubmenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>
<div class="ml-8 menu__submenu" *ngIf="showHierarchySubmenu && false">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Divisions in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/product-hierarchy/divisions'" [class.font-semibold]="activeRoute.startsWith('/merchandising/product-hierarchy/divisions')">
                    Divisions
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/product-hierarchy/divisions')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Departments in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/product-hierarchy/departments'" [class.font-semibold]="activeRoute.startsWith('/merchandising/product-hierarchy/departments')">
                    Departments
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/product-hierarchy/departments')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Category in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/product-hierarchy/category'" [class.font-semibold]="activeRoute.startsWith('/merchandising/product-hierarchy/category')">
                    Category
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/product-hierarchy/category')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Subcategory in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/product-hierarchy/sub-category'" [class.font-semibold]="activeRoute.startsWith('/merchandising/product-hierarchy/sub-category')">
                    Subcategory
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/product-hierarchy/sub-category')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Product Class in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/product-hierarchy/product-classes'" [class.font-semibold]="activeRoute.startsWith('/merchandising/product-hierarchy/product-classes')">
                    Class
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/product-hierarchy/product-classes')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Product Subclasses in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/product-hierarchy/product-subclasses'" [class.font-semibold]="activeRoute.startsWith('/merchandising/product-hierarchy/product-subclasses')">
                    Subclasses
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/product-hierarchy/product-subclasses')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <!-- <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Items in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/product-hierarchy/items'" [class.font-semibold]="activeRoute.startsWith('/merchandising/product-hierarchy/items')">
                    Items
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/product-hierarchy/items')"
                    ></span>
                </a>
            </li>
        </ng-container> -->
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Items in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/product-hierarchy/mdz-articles'" [class.font-semibold]="activeRoute.startsWith('/merchandising/product-hierarchy/mdz-articles')">
                    Purchasing Items
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/product-hierarchy/mdz-articles')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Items in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/mdz-brand-masters'" [class.font-semibold]="activeRoute.startsWith('/merchandising/mdz-brand-masters')">
                    Brand Master
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/mdz-brand-masters')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Assortment in Sidebar')">
              <li class="group relative pb-2.5 text-sm">
                  <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/mdz-assortment-masters'" [class.font-semibold]="activeRoute.startsWith('/merchandising/mdz-assortment-masters')">
                      Assortment
                      <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                          [class.opacity-0]="!activeRoute.startsWith('/merchandising/mdz-assortment-masters')"
                      ></span>
                  </a>
              </li>
        </ng-container>
    </ul>
</div>
<ng-container *ngIf="permissionService.hasPermission('Merchandising - View Promotion Planning in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" (click)="this.showPromotionPlanningSubmenu = !this.showPromotionPlanningSubmenu">
        <a class="flex items-center text-purple cursor-pointer">
            Promotion Planning
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showPromotionPlanningSubmenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showPromotionPlanningSubmenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>
<div class="ml-8 menu__submenu" *ngIf="showPromotionPlanningSubmenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Promotion Planning - View Price Change in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/promotions/price-changes'" [class.font-semibold]="activeRoute.startsWith('/merchandising/promotions/price-changes')">
                    Price Change
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/promotions/price-changes')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Promotion Planning - View Mix and Match in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/promotions/mix-and-match'" [class.font-semibold]="activeRoute.startsWith('/merchandising/promotions/mix-and-match')">
                    Mix and Match
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/promotions/mix-and-match')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Promotion Planning - View Buy X get Y Promotions in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/promotions/buy-x-get-y-promotions'" [class.font-semibold]="activeRoute.startsWith('/merchandising/promotions/buy-x-get-y-promotions')">
                    Buy X get Y Promotions
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/promotions/buy-x-get-y-promotions')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Promotion Planning - View Buy X get Y Percentages in Sidebar')">
          <li class="group relative pb-2.5 text-sm">
              <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/promotions/buy-x-get-y-percentages'" [class.font-semibold]="activeRoute.startsWith('/merchandising/promotions/buy-x-get-y-percentages')">
                  Buy X get Y %
                  <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                      [class.opacity-0]="!activeRoute.startsWith('/merchandising/promotions/buy-x-get-y-percentages')"
                  ></span>
              </a>
          </li>
      </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Promotion Planning - View Bundles in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/promotions/bundle-promotions'" [class.font-semibold]="activeRoute.startsWith('/merchandising/promotions/bundle-promotions')">
                    Bundles
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/promotions/bundle-promotions')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Promotion Planning - View Purchase with Purchase in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/promotions/purchase-with-purchase'" [class.font-semibold]="activeRoute.startsWith('/merchandising/promotions/purchase-with-purchase')">
                    Purchase with Purchase
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/promotions/purchase-with-purchase')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Promotion Planning - View Multi Buy in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/promotions/multi-buy'" [class.font-semibold]="activeRoute.startsWith('/merchandising/promotions/multi-buy')">
                    Multi Buy
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/promotions/multi-buy')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Promotion Planning - External Voucher')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/promotions/external-voucher'" [class.font-semibold]="activeRoute.startsWith('/merchandising/promotions/external-voucher')">
                    External Voucher
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/promotions/external-voucher')"
                    ></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
<ng-container *ngIf="permissionService.hasPermission('Merchandising - View Purchasing in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" (click)="this.showPurchasingSubmenu = !this.showPurchasingSubmenu">
        <a class="flex items-center text-purple cursor-pointer">
            Trade Purchasing
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showPurchasingSubmenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showPurchasingSubmenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>
<div class="ml-8 menu__submenu" *ngIf="showPurchasingSubmenu">
    <ul class="relative pb-2.5">

        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Purchasing - View Purchase Requests in Sidebar')">
        <li class="group relative pb-2.5 text-sm">
            <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/purchasing/purchase-request'" [class.font-semibold]="activeRoute.startsWith('/merchandising/purchasing/purchase-request')">
                Purchase Requests
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                    [class.opacity-0]="!activeRoute.startsWith('/merchandising/purchasing/purchase-request')"
                ></span>
            </a>
        </li>
        </ng-container>

        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Purchasing - View Purchase Orders in Sidebar')">
        <li class="group relative pb-2.5 text-sm">
            <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/purchasing/purchase-order'" [class.font-semibold]="activeRoute.startsWith('/merchandising/purchasing/purchase-order')">
                Purchase Orders
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                    [class.opacity-0]="!activeRoute.startsWith('/merchandising/purchasing/purchase-order')"
                ></span>
            </a>
        </li>
        </ng-container>
        <!-- <ng-container *ngIf="permissionService.hasPermission('Merchandising - Purchasing - View Transfer Orders in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/purchasing/transfer-order'" [class.font-semibold]="activeRoute.startsWith('/merchandising/purchasing/transfer-order')">
                    Transfer Order
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/purchasing/transfer-order')"
                    ></span>
                </a>
            </li>
        </ng-container> -->
        <!-- <ng-container *ngIf="permissionService.hasPermission('Merchandising - Purchasing - View Receiving in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/purchasing/receiving-draft'" [class.font-semibold]="activeRoute.startsWith('/merchandising/purchasing/receiving-draft')">
                    Batch Receiving
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/purchasing/receiving')"
                    ></span>
                </a>
            </li>
        </ng-container> -->
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Purchasing - View Receiving in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="openReceiving()" [class.font-semibold]="activeRoute.startsWith('/merchandising/purchasing/receiving')">
                    Receiving
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/purchasing/receiving') || activeRoute.startsWith('/merchandising/purchasing/receiving-draft')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <!-- <li class="group relative pb-2.5 text-sm">
            <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/purchasing/returns'" [class.font-semibold]="activeRoute.startsWith('/merchandising/purchasing/returns')">
                Returns
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                    [class.opacity-0]="!activeRoute.startsWith('/merchandising/purchasing/returns')"
                ></span>
            </a>
        </li> -->
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Purchasing - View Invoices in Sidebar')">
        <li class="group relative pb-2.5 text-sm">
            <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/purchasing/invoices'" [class.font-semibold]="activeRoute.startsWith('/merchandising/purchasing/invoices')">
                Invoices
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                    [class.opacity-0]="!activeRoute.startsWith('/merchandising/purchasing/invoices')"
                ></span>
            </a>
        </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Purchasing - View Refunds in Sidebar')">
        <li class="group relative pb-2.5 text-sm">
            <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/purchasing/refunds'" [class.font-semibold]="activeRoute.startsWith('/merchandising/purchasing/refunds')">
                Returns
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                    [class.opacity-0]="!activeRoute.startsWith('/merchandising/purchasing/refunds')"
                ></span>
            </a>
        </li>
        </ng-container>
    </ul>
</div>
<!-- <ng-container *ngIf="permissionService.hasPermission('Merchandising - View Store Operations in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" (click)="this.showStoreOperationsSubmenu = !this.showStoreOperationsSubmenu">
        <a class="flex items-center text-purple cursor-pointer">
            Store Operations
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showStoreOperationsSubmenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showStoreOperationsSubmenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container> -->
<!-- <div class="ml-8 menu__submenu" *ngIf="showStoreOperationsSubmenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Store Operations - View Stock Lookup in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Stock Lookup
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Store Operations - View Stock Adjustment in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Stock Adjustment
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Store Operations - View Hand Held Terminal in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" (click)="alert('Please install full version to access this module')">
                    Hand Held Terminal
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/demo-route')"
                    ></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div> -->
<ng-container *ngIf="permissionService.hasPermission('Merchandising - View Inventory in Sidebar')">
<li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" (click)="this.showInventoryMenu = !this.showInventoryMenu">
    <a class="flex items-center text-purple cursor-pointer">
        Inventory
        <div style="flex: 1"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showInventoryMenu">
            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showInventoryMenu">
            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
        </svg>
    </a>
</li>
</ng-container>
<div class="ml-8 menu__submenu" *ngIf="showInventoryMenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - View Inventory Explorer in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/inventory'" [class.font-semibold]="activeRoute == '/merchandising/inventory'">
                    Inventory Explorer
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="activeRoute != '/merchandising/inventory'"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - View Stock Movement in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/inventory/history'" [class.font-semibold]="activeRoute.startsWith('/merchandising/inventory/history')">
                    Stock Movement
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/inventory/history')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - View Stock Adjustment in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/stock-adjustments'" [class.font-semibold]="activeRoute.startsWith('/merchandising/stock-adjustments')">
                    Stock Adjustment
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/stock-adjustments')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <!-- <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - View Stock Reserve in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/stock-reserves'" [class.font-semibold]="activeRoute.startsWith('/merchandising/stock-reserves')">
                    Stock Reserve
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/stock-reserves')"
                    ></span>
                </a>
            </li>
        </ng-container> -->
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - View Stock Take in Sidebar') || permissionService.hasPermission('Merchandising - Inventory - View Stock Take Submission in Sidebar')">
            <li class="roup relative pb-2.5 text-sm"  (click)="this.showStockTakeMenu = !this.showStockTakeMenu">
                <a class="flex items-center text-purple cursor-pointer">
                    Stock Take
                    <div style="flex: 1"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showStockTakeMenu">
                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showStockTakeMenu">
                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                    </svg>
                </a>
            </li>
        </ng-container>
        <div class="menu__submenu" *ngIf="showStockTakeMenu">
            <ul class="relative pb-2.5">
                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - View Stock Take in Sidebar')">
                    <li class="group relative pb-2.5 text-sm">
                        <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/stock-take'" [class.font-semibold]="activeRoute == '/merchandising/stock-take' || activeRoute == '/merchandising/stock-take/create' || activeRoute.startsWith('/merchandising/stock-take/')">
                            Stock Take Request
                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="activeRoute != '/merchandising/stock-take' && activeRoute != '/merchandising/stock-take/create' && !activeRoute.startsWith('/merchandising/stock-take/')"
                            ></span>
                        </a>
                    </li>
                </ng-container>
                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - View Stock Take Submission in Sidebar')">
                    <li class="group relative pb-2.5 text-sm">
                        <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/stock-take-submissions'" [class.font-semibold]="activeRoute == '/merchandising/stock-take-submissions' || activeRoute.startsWith('/merchandising/stock-take-submissions/')">
                            Stock Take Submission
                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                [class.opacity-0]="activeRoute != '/merchandising/stock-take-submissions' && !activeRoute.startsWith('/merchandising/stock-take-submissions/')"
                            ></span>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - View Disposal in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
              <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/disposal/disposals'" [class.font-semibold]="activeRoute.startsWith('/merchandising/disposal/disposals')">
                  Disposal
                  <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                      [class.opacity-0]="!activeRoute.startsWith('/merchandising/disposal/disposals')"
                  ></span>
              </a>
          </li>
        </ng-container>
        <!-- <ng-container *ngIf="permissionService.hasPermission('Merchandising - PA Zero - View PA Zero in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
              <a class="cursor-pointer flex items-center text-purple" routerLink="/merchandising/pa-zeros" [class.font-semibold]="activeRoute.startsWith('/merchandising/pa-zeros')">
                  PA Zero
                  <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                      [class.opacity-0]="!activeRoute.startsWith('/merchandising/pa-zeros')"
                  ></span>
              </a>
          </li>
        </ng-container> -->
        <!-- <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - View Cycle Count in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/cycle-count'" [class.font-semibold]="activeRoute.startsWith('/merchandising/cycle-count')">
                    Cycle Count
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/cycle-count')"
                    ></span>
                </a>
            </li>
        </ng-container> -->
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - View Transfer Item / Put Away in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/stock-transfer-storage'" [class.font-semibold]="activeRoute.startsWith('/merchandising/stock-transfer-storage')">
                    Transfer Item / Put Away
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/stock-transfer-storage')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - View Inter Location Transfers in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/merchandising/interlocation-transfer-item'" [class.font-semibold]="activeRoute.startsWith('/merchandising/interlocation-transfer-item')">
                    Inter Location Transfers
                    <span
                      class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                      [class.opacity-0]="!activeRoute.startsWith('/merchandising/interlocation-transfer-item')">
                    </span>
                </a>
              </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - View Receiving Stock Transfer in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/merchandising/receiving-stock-transfer'" [class.font-semibold]="activeRoute.startsWith('/merchandising/receiving-stock-transfer')">
                    Receiving Stock Transfer
                    <span
                      class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                      [class.opacity-0]="!activeRoute.startsWith('/merchandising/receiving-stock-transfer')">
                    </span>
                </a>
              </li>
        </ng-container>
        <!-- <ng-container *ngIf="permissionService.hasPermission('Merchandising - View Material Request in Sidebar')">
            <li class="roup relative pb-2.5 text-sm"  (click)="this.showMaterialRequestMenu = !this.showMaterialRequestMenu">
                <a class="flex items-center text-purple cursor-pointer">
                    Material Request
                    <div style="flex: 1"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showMaterialRequestMenu">
                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showMaterialRequestMenu">
                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                    </svg>
                </a>
            </li>
        </ng-container> -->
        <!-- <div class="menu__submenu" *ngIf="showMaterialRequestMenu">
            <ul class="relative pb-2.5">
                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Can View My Material Request in Sidebar')">
                    <li class="group relative pb-2.5 text-sm">
                        <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/material-request/my-request'" [class.font-semibold]="activeRoute.startsWith('/merchandising/material-request/my-request')">
                            My Request
                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                            [class.opacity-0]="!activeRoute.startsWith('/merchandising/material-request/my-request')"
                            ></span>
                        </a>
                    </li>
                </ng-container>
                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Can View Open Material Request in Sidebar')">
                    <li class="group relative pb-2.5 text-sm">
                        <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/material-request/open-request'" [class.font-semibold]="activeRoute.startsWith('/merchandising/material-request/open-request')">
                            Open Request
                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/material-request/open-request')"
                            ></span>
                        </a>
                    </li>
                </ng-container>
                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Can View All Material Request in Sidebar')">
                    <li class="group relative pb-2.5 text-sm">
                        <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/material-request/all-request'" [class.font-semibold]="activeRoute.startsWith('/merchandising/material-request/all-request')">
                            All Request
                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/material-request/my-request')"
                            ></span>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div> -->
        <!-- <ng-container *ngIf="permissionService.hasPermission('Merchandising - View Bill Of Materials in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/merchandising/bill-of-materials'" [class.font-semibold]="activeRoute.startsWith('/merchandising/bill-of-materials')">
                    Perform BOM
                    <span
                      class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                      [class.opacity-0]="!activeRoute.startsWith('/merchandising/bill-of-materials')">
                    </span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - View Production Order in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/merchandising/production-order'" [class.font-semibold]="activeRoute.startsWith('/merchandising/production-order')">
                    Production Order
                    <span
                      class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                      [class.opacity-0]="!activeRoute.startsWith('/merchandising/production-order')">
                    </span>
                </a>
            </li>
        </ng-container> -->
    </ul>
</div>
<ng-container *ngIf="permissionService.hasPermission('Merchandising - View Trading Terms in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" (click)="this.showTradingTermMenu = !this.showTradingTermMenu">
        <a class="flex items-center text-purple cursor-pointer">
            Trading Terms
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showTradingTermMenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showTradingTermMenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>
<div class="ml-8 menu__submenu" *ngIf="showTradingTermMenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Trading Terms - View Supplier Rebates in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/trading-terms/supplier-rebates'" [class.font-semibold]="activeRoute.startsWith('/merchandising/trading-terms/supplier-rebates')">
                    Supplier Rebates
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/trading-terms/supplier-rebates')"
                    ></span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Trading Terms - View Supplier Discount in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/trading-terms/supplier-discounts'" [class.font-semibold]="activeRoute.startsWith('/merchandising/trading-terms/supplier-discounts')">
                    Supplier Discounts
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/trading-terms/supplier-discounts')"
                    ></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
<ng-container *ngIf="permissionService.hasPermission('Merchandising - View Merchandising Forms in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu" (click)="this.showMerchandisingFormMenu = !this.showMerchandisingFormMenu">
        <a class="flex items-center text-purple cursor-pointer">
            Merchandising Forms
            <div style="flex: 1"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showMerchandisingFormMenu">
                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showMerchandisingFormMenu">
                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
            </svg>
        </a>
    </li>
</ng-container>
<div class="ml-8 menu__submenu" *ngIf="showMerchandisingFormMenu">
    <ul class="relative pb-2.5">
        <ng-container *ngIf="permissionService.hasPermission('Merchandising - View Merchandising Forms in Sidebar')">
            <li class="group relative pb-2.5 text-sm" *ngIf="showSubmenu">
                <a class="ml-4 cursor-pointer flex items-center text-purple"  [routerLink]="'/merchandising/merchandising-forms/product-specification'" [class.font-semibold]="activeRoute.startsWith('/merchandising/merchandising-forms/product-specification')">
                    Product Specification
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/merchandising-forms/product-specification')"
                    ></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
<ng-container *ngIf="permissionService.hasPermission('Merchandising - View Label Printing in Sidebar')">
    <li class="group relative pb-2.5 text-sm" *ngIf="showSubmenu">
        <a class="ml-4 cursor-pointer flex items-center text-purple"  [routerLink]="'/merchandising/label-printing'" [class.font-semibold]="activeRoute.startsWith('/merchandising/label-printing')">
            Label Printing
            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                [class.opacity-0]="!activeRoute.startsWith('/merchandising/label-printing')"
            ></span>
        </a>
    </li>
</ng-container>
<!-- * Uncomment this for enable planogram list -->
<ng-container *ngIf="permissionService.hasPermission('Merchandising - View Planograms in Sidebar')">
    <li class="ml-4 group relative pb-2.5 text-sm" *ngIf="showSubmenu">
        <a class="flex items-center text-purple cursor-pointer" [routerLink]="'/merchandising/planograms'" [class.font-semibold]="activeRoute.startsWith('/merchandising/planograms')">
            Planograms
            <span
              class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
              [class.opacity-0]="!activeRoute.startsWith('/merchandising/planograms')">
            </span>
        </a>
    </li>
</ng-container>
