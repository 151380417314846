import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { PasswordChangedGuard } from "src/app/guards/password-changed.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";

export const routes: Routes = [
  {
    path: 'budget-management',
    loadChildren: () => import('./budgets/budgets.module').then(m => m.BudgetsModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard,
    ]
  },
  {
    path: 'budget-management/budgets/create',
    loadChildren: () => import('./budgets/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'budget-management/budgets/edit/:id',
    loadChildren: () => import('./budgets/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'budget-management/budgets/view/:id',
    loadChildren: () => import('./budgets/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'budget-management/budget-adjustments/:budgetAdjustmentType/:budgetId',
    loadChildren: () => import('./budget-adjustments/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'budget-management/budget-transfers/:transferType/:budgetId',
    loadChildren: () => import('./budget-transfers/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
];
