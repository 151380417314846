<li class="group relative pb-2.5 text-sm" (click)="handleSettingsSubmenu()">
    <a class="flex items-center text-purple cursor-pointer">
        <div class="w-8 h-8 flex items-center justify-center mr-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        </div>
        Settings
        <div style="flex: 1"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showSettingsSubmenu">
            <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showSettingsSubmenu">
            <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
        </svg>
    </a>
</li>

<div *ngIf="showSettingsSubmenu">
    <ul class="relative pb-2.5 ml-4">
        <ng-container *ngIf="permissionService.hasPermission('Settings - View Master Data in Sidebar')">
            <li class="group relative pb-2.5 text-sm" *ngIf="showSettingsSubmenu" (click)="this.showMasterDataSubMenu = !this.showMasterDataSubMenu">
                <a class="flex items-center text-purple cursor-pointer">
                    Master Data
                    <div style="flex: 1"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showMasterDataSubMenu">
                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showMasterDataSubMenu">
                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                    </svg>
                </a>
            </li>
        </ng-container>
        <div class="ml-4 menu__submenu" *ngIf="showMasterDataSubMenu">
            <ul class="relative pb-2.5">
                <!-- Systems -->
                <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Systems in Sidebar')">
                    <li class="group relative pb-2.5 text-sm" (click)="this.showSystemSubmenu = !this.showSystemSubmenu">
                        <a class="flex items-center text-purple cursor-pointer">
                            System
                            <div style="flex: 1"></div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="!showSystemSubmenu">
                                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="showSystemSubmenu">
                                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                            </svg>
                        </a>
                    </li>
                </ng-container>
                <div class="menu__submenu" *ngIf="showSystemSubmenu">
                    <ul class="relative pb-2.5">
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Entity Management in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 flex items-center text-purple" [routerLink]="'/settings/entities'" [class.font-semibold]="activeRoute.startsWith('/settings/entities')">
                                    Entities
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/entities')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Cluster in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a [routerLink]="'/settings/clusters'" class="ml-2 cursor-pointer flex items-center text-purple" [class.font-semibold]="activeRoute.startsWith('/settings/clusters')">
                                    Clusters
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/clusters')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Location Master in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a [routerLink]="'/settings/locations'" class="ml-2 cursor-pointer flex items-center text-purple" [class.font-semibold]="activeRoute.startsWith('/settings/locations')">
                                    Locations
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/locations')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Vendors in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/vendors'" [class.font-semibold]="activeRoute.startsWith('/settings/vendors')">
                                    Vendors
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/vendors')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Customers in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/tenants'" [class.font-semibold]="activeRoute.startsWith('/settings/tenants')">
                                    B2B Customers
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/tenants')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View DOA Approval in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/doa-setup'" [class.font-semibold]="activeRoute.startsWith('/settings/doa-setup')">
                                    DOA Setup
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/doa-setup')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Currency in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 flex items-center text-purple" [routerLink]="'/settings/currency'" [class.font-semibold]="activeRoute.startsWith('/settings/currency')">
                                    Currency
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/currency')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View User Management in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/users'" [class.font-semibold]="activeRoute.startsWith('/settings/users')">
                                    User Management
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/users')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Role Management in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/role-access-managements'" [class.font-semibold]="activeRoute.startsWith('/settings/role-access-managements')">
                                    Role Management
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/role-access-managements')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <!-- <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Access Management in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/user-access-managements'" [class.font-semibold]="activeRoute.startsWith('/settings/user-access-managements')">
                                    Access Management
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/user-access-managements')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container> -->
                    </ul>
                </div>


                <!-- Finance -->
                <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Finance in Sidebar')">
                    <li class="group relative pb-2.5 text-sm" (click)="this.showFinanceSubmenu = !this.showFinanceSubmenu">
                        <a class="flex items-center text-purple cursor-pointer">
                            Finance
                            <div style="flex: 1"></div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="!showFinanceSubmenu">
                                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="showFinanceSubmenu">
                                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                            </svg>
                        </a>
                    </li>
                </ng-container>
                <div class="menu__submenu" *ngIf="showFinanceSubmenu">
                    <ul class="relative pb-2.5">
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Cut Off Dates in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/cut-off-dates'" [class.font-semibold]="activeRoute.startsWith('/settings/cut-off-dates')">
                                    Cut Off Dates
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/cut-off-dates')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View GL Accounts in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/gl-accounts'" [class.font-semibold]="activeRoute.startsWith('/settings/gl-accounts')">
                                    GL Accounts
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/gl-accounts')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Profit Centers Master in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a [routerLink]="'/settings/profit-centers'" class="ml-2 cursor-pointer flex items-center text-purple" [class.font-semibold]="activeRoute.startsWith('/settings/profit-centers')">
                                    Profit Centers
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/profit-centers')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Cost Centers Master in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a [routerLink]="'/settings/cost-centers'" class="ml-2 cursor-pointer flex items-center text-purple" [class.font-semibold]="activeRoute.startsWith('/settings/cost-centers')">
                                    Cost Centers
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/cost-centers')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View House Banks in Sidebar')">
                            <li class="group relative pb-2.5 text-sm" (click)="this.showHouseBanksSubMenu = !this.showHouseBanksSubMenu">
                                <a class="ml-2 cursor-pointer flex items-center text-purple">
                                    House Banks
                                    <div style="flex: 1"></div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showHouseBanksSubMenu">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showHouseBanksSubMenu">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </li>
                            <div class="menu__submenu" *ngIf="showHouseBanksSubMenu">
                                <ul class="relative pb-2.5">
                                    <ng-container>
                                        <li class="group relative pb-2.5 text-sm" *ngIf="permissionService.hasPermission('Settings - Master Data - View House Banks in Sidebar')">
                                            <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/house-banks/house-bank-list'" [class.font-semibold]="activeRoute.startsWith('/settings/house-banks/house-bank-list')">
                                                House Banks
                                                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                    [class.opacity-0]="!activeRoute.startsWith('/settings/house-banks/house-bank-list')"></span>
                                            </a>
                                        </li>
                                        <li class="group relative pb-2.5 text-sm" *ngIf="permissionService.hasPermission('Settings - Master Data - House Banks - View Bank Accounts in Sidebar')">
                                            <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/house-banks/bank-accounts'" [class.font-semibold]="activeRoute.startsWith('/settings/house-banks/bank-accounts')">
                                                Bank Accounts
                                                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                    [class.opacity-0]="!activeRoute.startsWith('/settings/house-banks/bank-accounts')"></span>
                                            </a>
                                        </li>
                                        <li class="group relative pb-2.5 text-sm" *ngIf="permissionService.hasPermission('Settings - Master Data - House Banks - View Cheques in Sidebar')">
                                            <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/house-banks/cheques'" [class.font-semibold]="activeRoute.startsWith('/settings/house-banks/cheques')">
                                                Cheques
                                                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                    [class.opacity-0]="!activeRoute.startsWith('/settings/house-banks/cheques')"></span>
                                            </a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Purchasing Organization in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/office-department'" [class.font-semibold]="activeRoute.startsWith('/settings/office-department')">
                                    Purchasing Organizations
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/office-department')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>

                        <li *ngIf="permissionService.hasPermission('Settings - Master Data - View Non-Trade Procurement in Sidebar')" class="group relative pb-2.5 text-sm" (click)="this.showNonTradeProcurmentSubMenu = !this.showNonTradeProcurmentSubMenu">
                            <a class="ml-2 cursor-pointer flex items-center text-purple">
                                OPEX Master
                                <div style="flex: 1"></div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showNonTradeProcurmentSubMenu">
                                    <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showNonTradeProcurmentSubMenu">
                                    <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                                </svg>
                            </a>
                        </li>
                        <div class="menu__submenu" *ngIf="showNonTradeProcurmentSubMenu">
                            <ul class="relative pb-2.5">
                                <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View NTP Merchandising Category in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/ntp-merchandising-categories'" [class.font-semibold]="activeRoute.startsWith('/settings/ntp-merchandising-categories')">
                                            Purchasing Categories
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/settings/ntp-merchandising-categories')"></span>
                                        </a>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Article Master in Sidebar')">
                                    <li class="group relative text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/article-master'" [class.font-semibold]="activeRoute.startsWith('/settings/article-master')">
                                            Purchasing Items
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/settings/article-master')"
                                            ></span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>

                        <ng-container *ngIf="permissionService.hasPermission('Settings - View Non Trade Asset in Sidebar')">
                            <li class="group relative pb-2.5 text-sm" (click)="this.showNonTradeAssetSubMenu = !this.showNonTradeAssetSubMenu">
                                <a class="ml-2 cursor-pointer flex items-center text-purple">
                                    CAPEX Master
                                    <div style="flex: 1"></div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showNonTradeAssetSubMenu">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showNonTradeAssetSubMenu">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </li>
                        </ng-container>
                        <div class="menu__submenu" *ngIf="showNonTradeAssetSubMenu">
                            <ul class="relative">
                                <ng-container *ngIf="permissionService.hasPermission('Settings - Non Trade Asset - View Asset Class in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm" >
                                        <a class="ml-4 flex items-center text-purple" [routerLink]="'/settings/asset-classes'" [class.font-semibold]="activeRoute.startsWith('/settings/asset-classes')">
                                            Classes
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;" [class.opacity-0]="!activeRoute.startsWith('/settings/asset-classes')"></span>
                                        </a>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="permissionService.hasPermission('Settings - Non Trade Asset - View Asset Categories in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm" >
                                        <a class="ml-4 flex items-center text-purple" [routerLink]="'/settings/asset-categories'" [class.font-semibold]="activeRoute.startsWith('/settings/asset-categories')">
                                            Categories
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;" [class.opacity-0]="!activeRoute.startsWith('/settings/asset-categories')"></span>
                                        </a>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="permissionService.hasPermission('Settings - Non Trade Asset - View Assets in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm" >
                                        <a class="ml-4 flex items-center text-purple" [routerLink]="'/settings/assets'" [class.font-semibold]="activeRoute.startsWith('/settings/assets')">
                                            Assets
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;" [class.opacity-0]="!activeRoute.startsWith('/settings/assets')"></span>
                                        </a>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="permissionService.hasPermission('Settings - Non Trade Asset - View Asset Sublines in Sidebar')">
                                    <li class="group relative text-sm" >
                                        <a class="ml-4 flex items-center text-purple" [routerLink]="'/settings/asset-sublines'" [class.font-semibold]="activeRoute.startsWith('/settings/asset-sublines')">
                                            Asset Sublines
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;" [class.opacity-0]="!activeRoute.startsWith('/settings/asset-sublines')"></span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>

                        <!-- <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View IO Approval Level in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/io-approval-levels'" [class.font-semibold]="activeRoute.startsWith('/settings/io-approval-levels')">
                                    IO Approval Level
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/io-approval-levels')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container> -->
                    </ul>
                </div>

                <!-- Merchandising -->
                <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Merchandising in Sidebar')">
                    <li class="group relative pb-2.5 text-sm" (click)="this.showMerchandisingSubmenu = !this.showMerchandisingSubmenu">
                    <!-- <li class="group relative pb-2.5 text-sm" (click)="alert('Please enable module to use master data')"> -->
                        <a class="flex items-center text-purple cursor-pointer">
                            Merchandising
                            <div style="flex: 1"></div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="!showMerchandisingSubmenu">
                                <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="showMerchandisingSubmenu">
                                <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                            </svg>
                        </a>
                    </li>
                </ng-container>

                <div class="menu__submenu" *ngIf="showMerchandisingSubmenu">
                    <ul class="relative pb-2.5">

                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Purchasing Group in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/purchasing-group'" [class.font-semibold]="activeRoute.startsWith('/settings/purchasing-group')">
                                    Purchasing Group
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/purchasing-group')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="permissionService.hasPermission('Settings - Master Data - View Storage Locations in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/settings/storage-locations'" [class.font-semibold]="activeRoute.startsWith('/settings/storage-locations')">
                                    Storage Locations
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/storage-locations')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Disposal - View Disposal Reason in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple"  [routerLink]="'/merchandising/disposal/disposal-reason'" [class.font-semibold]="activeRoute.startsWith('/merchandising/disposal/disposal-reason')">
                                    Disposal Reason Master
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/disposal/disposal-reason')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Inventory - Stock Adjustment - View Stock Adjustment Reason in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple"  [routerLink]="'/settings/merchandising/stock-adjustments/stock-adjustment-reason'" [class.font-semibold]="activeRoute.startsWith('/settings/merchandising/stock-adjustments/stock-adjustment-reason')">
                                    Stock Adjustment Reason Master
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/merchandising/stock-adjustments/stock-adjustment-reason')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Brand in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/mdz-brand-masters'" [class.font-semibold]="activeRoute.startsWith('/merchandising/mdz-brand-masters')">
                                    Brand Master
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/mdz-brand-masters')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Brand Category in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/mdz-brand-category'" [class.font-semibold]="activeRoute.startsWith('/merchandising/mdz-brand-category')">
                                    Brand Category Master
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/mdz-brand-category')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                        <!-- * Uncomment this line for enable assortment list -->
                        <ng-container *ngIf="permissionService.hasPermission('Merchandising - Product Hierarchy - View Assortment in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/mdz-assortment-masters'" [class.font-semibold]="activeRoute.startsWith('/merchandising/mdz-assortment-masters')">
                                    Assortment
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/merchandising/mdz-assortment-masters')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="permissionService.hasPermission('Merchandising - View BOM Recipes in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="ml-2 flex items-center text-purple cursor-pointer" [routerLink]="'/merchandising/bom-recipes'" [class.font-semibold]="activeRoute.startsWith('/merchandising/bom-recipes')">
                                    BOM Recipes
                                    <span
                                    class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                    [class.opacity-0]="!activeRoute.startsWith('/merchandising/bom-recipes')">
                                    </span>
                                </a>
                            </li>
                        </ng-container>
                        <!-- Merchandising Raw Materials -->
                        <ng-container *ngIf="permissionService.hasPermission('Merchandising - View Raw Materials in Sidebar')">
                            <li class="group relative pb-2.5 text-sm" (click)="this.showMerchandisingRawMaterialsHierarchySubmenu = !this.showMerchandisingRawMaterialsHierarchySubmenu">
                                <a class="ml-2 flex items-center text-purple cursor-pointer">
                                    Raw Materials
                                    <div style="flex: 1"></div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="!showMerchandisingRawMaterialsHierarchySubmenu">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="showMerchandisingRawMaterialsHierarchySubmenu">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </li>
                        </ng-container>
                        <div class="menu__submenu" *ngIf="showMerchandisingRawMaterialsHierarchySubmenu">
                            <ul class="relative pb-2.5">
                                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Raw Materials - View Product Class in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/raw-material/product-classes'" [class.font-semibold]="activeRoute.startsWith('/merchandising/raw-material/product-classes')">
                                            Class
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/raw-material/product-classes')"
                                            ></span>
                                        </a>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Raw Materials - View Product Subclasses in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/raw-material/product-subclasses'" [class.font-semibold]="activeRoute.startsWith('/merchandising/raw-material/product-subclasses')">
                                            Subclasses
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/raw-material/product-subclasses')"
                                            ></span>
                                        </a>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Raw Materials - View Items in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/raw-material/purchasing-items'" [class.font-semibold]="activeRoute.startsWith('/merchandising/raw-material/purchasing-items')">
                                            Purchasing Items
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/raw-material/purchasing-items')"
                                            ></span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                        <!-- Merchandising Semi-Finished Goods -->
                        <ng-container *ngIf="permissionService.hasPermission('Merchandising - View Semi-Finished Goods in Sidebar')">
                            <li class="group relative pb-2.5 text-sm" (click)="this.showMerchandisingSemiFinishedGoodsHierarchySubmenu = !this.showMerchandisingSemiFinishedGoodsHierarchySubmenu">
                                <a class="ml-2 flex items-center text-purple cursor-pointer">
                                    Semi-Finished Goods
                                    <div style="flex: 1"></div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="!showMerchandisingSemiFinishedGoodsHierarchySubmenu">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="showMerchandisingSemiFinishedGoodsHierarchySubmenu">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </li>
                        </ng-container>
                        <div class="menu__submenu" *ngIf="showMerchandisingSemiFinishedGoodsHierarchySubmenu">
                            <ul class="relative pb-2.5">
                                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Semi-Finished Goods - View Product Class in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/semi-finished-good/product-classes'" [class.font-semibold]="activeRoute.startsWith('/merchandising/semi-finished-good/product-classes')">
                                            Class
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/semi-finished-good/product-classes')"
                                            ></span>
                                        </a>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Semi-Finished Goods - View Product Subclasses in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/semi-finished-good/product-subclasses'" [class.font-semibold]="activeRoute.startsWith('/merchandising/semi-finished-good/product-subclasses')">
                                            Subclasses
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/semi-finished-good/product-subclasses')"
                                            ></span>
                                        </a>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Semi-Finished Goods - View Items in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/semi-finished-good/purchasing-items'" [class.font-semibold]="activeRoute.startsWith('/merchandising/semi-finished-good/purchasing-items')">
                                            Purchasing Items
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/semi-finished-good/purchasing-items')"
                                            ></span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                        <!-- Merchandising Finished Goods -->
                        <ng-container *ngIf="permissionService.hasPermission('Merchandising - View Finished Goods in Sidebar')">
                            <li class="group relative pb-2.5 text-sm" (click)="this.showMerchandisingFinishedGoodsHierarchySubmenu = !this.showMerchandisingFinishedGoodsHierarchySubmenu">
                                <a class="ml-2 flex items-center text-purple cursor-pointer">
                                    Finished Goods
                                    <div style="flex: 1"></div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="!showMerchandisingFinishedGoodsHierarchySubmenu">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 shrink-0" *ngIf="showMerchandisingFinishedGoodsHierarchySubmenu">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </li>
                        </ng-container>
                        <div class="menu__submenu" *ngIf="showMerchandisingFinishedGoodsHierarchySubmenu">
                            <ul class="relative pb-2.5">
                                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Finished Goods - View Product Class in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/finished-good/product-classes'" [class.font-semibold]="activeRoute.startsWith('/merchandising/finished-good/product-classes')">
                                            Class
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/finished-good/product-classes')"
                                            ></span>
                                        </a>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Finished Goods - View Product Subclasses in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/finished-good/product-subclasses'" [class.font-semibold]="activeRoute.startsWith('/merchandising/finished-good/product-subclasses')">
                                            Subclasses
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/finished-good/product-subclasses')"
                                            ></span>
                                        </a>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="permissionService.hasPermission('Merchandising - Finished Goods - View Items in Sidebar')">
                                    <li class="group relative pb-2.5 text-sm">
                                        <a class="ml-4 cursor-pointer flex items-center text-purple" [routerLink]="'/merchandising/finished-good/purchasing-items'" [class.font-semibold]="activeRoute.startsWith('/merchandising/finished-good/purchasing-items')">
                                            Purchasing Items
                                            <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                                [class.opacity-0]="!activeRoute.startsWith('/merchandising/finished-good/purchasing-items')"
                                            ></span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </ul>
                </div>
            </ul>
        </div>
        <!-- <ng-container *ngIf="permissionService.hasPermission('Settings - View Tax Setup in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a [routerLink]="'/settings/taxes'" class="flex items-center text-purple cursor-pointer" [class.font-semibold]="activeRoute.startsWith('/settings/taxes')">
                    Tax Setup
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/settings/taxes')"
                    ></span>
                </a>
            </li>
        </ng-container> -->
        <!-- <ng-container *ngIf="permissionService.hasPermission('Settings - View Data Categories in Sidebar')">
            <li class="group relative pb-2.5 text-sm">
                <a class="flex items-center text-purple" [routerLink]="'/settings/categories'" [class.font-semibold]="activeRoute.startsWith('/settings/categories')">
                    Data Categories
                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                        [class.opacity-0]="!activeRoute.startsWith('/settings/categories')"
                    ></span>
                </a>
            </li>
        </ng-container> -->

        <ng-container *ngIf="permissionService.hasPermission('Settings - View Integrations in Sidebar')">
            <li class="group relative pb-2.5 text-sm" *ngIf="showSettingsSubmenu" (click)="this.showIntegrationsSubMenu = !this.showIntegrationsSubMenu">
                <a class="flex items-center text-purple cursor-pointer">
                    Integrations
                    <div style="flex: 1"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="!showIntegrationsSubMenu">
                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4" *ngIf="showIntegrationsSubMenu">
                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clip-rule="evenodd" />
                    </svg>
                </a>
            </li>
        </ng-container>
        <div class="ml-4 menu__submenu" *ngIf="showIntegrationsSubMenu">
            <ul class="relative pb-2.5">
                <div class="menu__submenu">
                    <ul class="relative pb-2.5">
                        <ng-container *ngIf="permissionService.hasPermission('Settings - View Bank Recon Settings in Sidebar')">
                            <li class="group relative pb-2.5 text-sm">
                                <a class="flex items-center text-purple" [routerLink]="'/settings/bank-recon-settings'" [class.font-semibold]="activeRoute.startsWith('/settings/bank-recon-settings')">
                                    Bank Recon Settings
                                    <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                                        [class.opacity-0]="!activeRoute.startsWith('/settings/bank-recon-settings')"
                                    ></span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </ul>
        </div>

        <li class="group relative pb-2.5 text-sm">
            <a class="cursor-pointer flex items-center text-purple" (click)="logout()">
                Log Out
                <span class="bg-purple absolute h-5 -right-5 rounded-tl-lg rounded-bl-lg group-hover:opacity-100 transition-all pointer-events-none" style="width: 5px;"
                    [class.opacity-0]="!activeRoute.startsWith('/impossible-route')"
                ></span>
            </a>
        </li>
    </ul>
</div>
