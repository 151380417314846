import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { PasswordChangedGuard } from "src/app/guards/password-changed.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";

export const routes: Routes = [
  {
    path: 'non-trade/commodities-and-services/purchase-requests',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-purchase-requests/commodity-and-service-purchase-requests.module').then(m => m.CommodityAndServicePurchaseRequestsModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/purchase-requests/create',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-purchase-requests/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/purchase-requests/edit/:id',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-purchase-requests/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'non-trade/commodities-and-services/purchase-requests/:id',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-purchase-requests/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  // Supplier Reductions inside purchase request
  {
    path: 'non-trade/commodities-and-services/supplement-reductions/create',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-supplement-reductions/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/purchase-orders',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-purchase-orders/commodity-and-service-purchase-orders.module').then(m => m.CommodityAndServicePurchaseOrdersModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/purchase-orders/create',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-purchase-orders/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/purchase-orders/edit/:id',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-purchase-orders/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/purchase-orders/:id',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-purchase-orders/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'non-trade/commodities-and-services/goods-receiving-notes',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-good-receiving-notes/commodity-and-service-good-receiving-notes.module').then(m => m.CommodityAndServiceGoodReceivingNotesModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/goods-receiving-notes/create',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-good-receiving-notes/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/goods-receiving-notes/edit/:id',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-good-receiving-notes/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/goods-receiving-notes/:id',
    loadChildren: () => import('./commodities-and-services/commodity-and-service-good-receiving-notes/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'non-trade/commodities-and-services/invoices',
    loadChildren: () => import('./commodities-and-services/commodity-and-services-invoices/commodity-and-services-invoices.module').then(m => m.CommodityAndServicesInvoicesModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/invoices/create',
    loadChildren: () => import('./commodities-and-services/commodity-and-services-invoices/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/invoices/edit/:id',
    loadChildren: () => import('./commodities-and-services/commodity-and-services-invoices/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/invoices/pay/:id',
    loadChildren: () => import('./commodities-and-services/commodity-and-services-invoices/pay/pay.module').then(m => m.PayModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/commodities-and-services/invoices/:id',
    loadChildren: () => import('./commodities-and-services/commodity-and-services-invoices/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'non-trade/internal-order/internal-orders',
    loadChildren: () => import('./internal-orders/internal-orders/internal-orders.module').then(m => m.InternalOrdersModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/internal-orders/create',
    loadChildren: () => import('./internal-orders/internal-orders/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/internal-orders/edit/:id',
    loadChildren: () => import('./internal-orders/internal-orders/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'non-trade/internal-order/internal-orders/:id',
    loadChildren: () => import('./internal-orders/internal-orders/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  // Supplier Reductions
  {
    path: 'non-trade/internal-order/supplement-reductions/create',
    loadChildren: () => import('./internal-orders/supplement-reductions/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard
    ]
  },
  // {
  //   path: 'non-trade/internal-order/supplement-reductions/edit/:id',
  //   loadChildren: () => import('./internal-orders/supplement-reductions/create/').then(m => m.EditModule),
  //   data: {
  //     layout: 'app'
  //   },
  //   canActivate: [
  //     IsLoggedInGuard,
  //     SetupCompletedGuard
  //   ]
  // },

  {
    path: 'non-trade/internal-order/purchase-orders',
    loadChildren: () => import('./internal-orders/purchase-orders/purchase-orders.module').then(m => m.PurchaseOrdersModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/purchase-orders/create',
    loadChildren: () => import('./internal-orders/purchase-orders/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/purchase-orders/edit/:id',
    loadChildren: () => import('./internal-orders/purchase-orders/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/purchase-orders/:id',
    loadChildren: () => import('./internal-orders/purchase-orders/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'non-trade/internal-order/goods-receiving-notes',
    loadChildren: () => import('./internal-orders/goods-receiving-notes/goods-receiving-notes.module').then(m => m.GoodsReceivingNotesModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/goods-receiving-notes/create',
    loadChildren: () => import('./internal-orders/goods-receiving-notes/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/goods-receiving-notes/edit/:id',
    loadChildren: () => import('./internal-orders/goods-receiving-notes/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/goods-receiving-notes/edit-manual-asset/:id',
    loadChildren: () => import('./internal-orders/goods-receiving-notes/edit-manual-asset/edit-manual-asset.module').then(m => m.EditManualAssetModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/goods-receiving-notes/:id',
    loadChildren: () => import('./internal-orders/goods-receiving-notes/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },

  {
    path: 'non-trade/internal-order/invoices',
    loadChildren: () => import('./internal-orders/invoices/invoices.module').then(m => m.InvoicesModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/invoices/create',
    loadChildren: () => import('./internal-orders/invoices/create/create.module').then(m => m.CreateModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/invoices/edit/:id',
    loadChildren: () => import('./internal-orders/invoices/edit/edit.module').then(m => m.EditModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/invoices/:id',
    loadChildren: () => import('./internal-orders/invoices/view/view.module').then(m => m.ViewModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
  {
    path: 'non-trade/internal-order/invoices/pay/:id',
    loadChildren: () => import('./internal-orders/invoices/pay/pay.module').then(m => m.PayModule),
    data: {
      layout: 'app'
    },
    canActivate: [
      IsLoggedInGuard,
      SetupCompletedGuard,
      PasswordChangedGuard
    ]
  },
];
