import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';
import { routes as purchasingRoutes } from './purchasing/purchasing.routes';

export const routes: Routes = [
    /**
     * Product Hierarchy Route
     */
    // * Start Raw Material Route
    // {
    //     path: 'merchandising/raw-material/divisions',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/divisions/divisions.module').then(m => m.DivisionsModule),
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/divisions/create/create.module').then(m => m.CreateModule),
    //         },
    //         {
    //             path: 'view/:id',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/divisions/edit/edit.module').then(m => m.EditModule),
    //         }
    //     ]
    // },
    // {
    //     path: 'merchandising/raw-material/departments',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/departments/departments.module').then(m => m.DepartmentsModule),
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/departments/create/create.module').then(m => m.CreateModule),
    //         },
    //         {
    //             path: 'view/:id',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/departments/edit/edit.module').then(m => m.EditModule),
    //         }
    //     ]
    // },
    // {
    //     path: 'merchandising/raw-material/category',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/category/category.module').then(m => m.CategoryModule)
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/category/create/create.module').then(m => m.CreateModule)
    //         },
    //         {
    //             path: ':view/:id',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/category/edit/edit.module').then(m => m.EditModule)
    //         }
    //     ]
    // },
    // {
    //     path: 'merchandising/raw-material/sub-category',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/sub-category/sub-category.module').then(m => m.SubCategoryModule)
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/sub-category/create/create.module').then(m => m.CreateModule)
    //         },
    //         {
    //             path: 'view/:id',
    //             loadChildren: () => import('./product-hierarchy/raw-materials/sub-category/edit/edit.module').then(m => m.EditModule)
    //         }
    //     ]
    // },
    {
        path: 'merchandising/raw-material/product-classes',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./product-hierarchy/raw-materials/product-classes/product-classes.module').then(m => m.ProductClassesModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./product-hierarchy/raw-materials/product-classes/create/create.module').then(m => m.CreateModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./product-hierarchy/raw-materials/product-classes/edit/edit.module').then(m => m.EditModule)
            }
        ]
    },
    {
        path: 'merchandising/raw-material/product-subclasses',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./product-hierarchy/raw-materials/product-subclasses/product-subclasses.module').then(m => m.ProductSubclassesModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./product-hierarchy/raw-materials/product-subclasses/create/create.module').then(m => m.CreateModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./product-hierarchy/raw-materials/product-subclasses/edit/edit.module').then(m => m.EditModule)
            }
        ]
    },
    {
        path: 'merchandising/raw-material/purchasing-items',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./product-hierarchy/raw-materials/purchasing-items/purchasing-items.module').then(m => m.PurchasingItemsModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./product-hierarchy/raw-materials/purchasing-items/show/show.module').then(m => m.ShowModule),
            }
        ]
    },
    // * End Raw Material Route
    // * Start Semi-Finished Good Route
    // {
    //     path: 'merchandising/semi-finished-good/divisions',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/divisions/divisions.module').then(m => m.DivisionsModule),
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/divisions/create/create.module').then(m => m.CreateModule),
    //         },
    //         {
    //             path: 'view/:id',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/divisions/edit/edit.module').then(m => m.EditModule),
    //         }
    //     ]
    // },
    // {
    //     path: 'merchandising/semi-finished-good/departments',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/departments/departments.module').then(m => m.DepartmentsModule),
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/departments/create/create.module').then(m => m.CreateModule),
    //         },
    //         {
    //             path: 'view/:id',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/departments/edit/edit.module').then(m => m.EditModule),
    //         }
    //     ]
    // },
    // {
    //     path: 'merchandising/semi-finished-good/category',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/category/category.module').then(m => m.CategoryModule)
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/category/create/create.module').then(m => m.CreateModule)
    //         },
    //         {
    //             path: ':view/:id',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/category/edit/edit.module').then(m => m.EditModule)
    //         }
    //     ]
    // },
    // {
    //     path: 'merchandising/semi-finished-good/sub-category',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/sub-category/sub-category.module').then(m => m.SubCategoryModule)
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/sub-category/create/create.module').then(m => m.CreateModule)
    //         },
    //         {
    //             path: 'view/:id',
    //             loadChildren: () => import('./product-hierarchy/semi-finished-goods/sub-category/edit/edit.module').then(m => m.EditModule)
    //         }
    //     ]
    // },
    {
        path: 'merchandising/semi-finished-good/product-classes',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./product-hierarchy/semi-finished-goods/product-classes/product-classes.module').then(m => m.ProductClassesModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./product-hierarchy/semi-finished-goods/product-classes/create/create.module').then(m => m.CreateModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./product-hierarchy/semi-finished-goods/product-classes/edit/edit.module').then(m => m.EditModule)
            }
        ]
    },
    {
        path: 'merchandising/semi-finished-good/product-subclasses',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./product-hierarchy/semi-finished-goods/product-subclasses/product-subclasses.module').then(m => m.ProductSubclassesModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./product-hierarchy/semi-finished-goods/product-subclasses/create/create.module').then(m => m.CreateModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./product-hierarchy/semi-finished-goods/product-subclasses/edit/edit.module').then(m => m.EditModule)
            }
        ]
    },
    {
        path: 'merchandising/semi-finished-good/purchasing-items',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./product-hierarchy/semi-finished-goods/purchasing-items/purchasing-items.module').then(m => m.PurchasingItemsModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./product-hierarchy/semi-finished-goods/purchasing-items/show/show.module').then(m => m.ShowModule),
            }
        ]
    },
    // * End Semi-Finished Good Route
    // * Start Finished Good Route
    // {
    //     path: 'merchandising/finished-good/divisions',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/divisions/divisions.module').then(m => m.DivisionsModule),
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/divisions/create/create.module').then(m => m.CreateModule),
    //         },
    //         {
    //             path: 'view/:id',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/divisions/edit/edit.module').then(m => m.EditModule),
    //         }
    //     ]
    // },
    // {
    //     path: 'merchandising/finished-good/departments',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/departments/departments.module').then(m => m.DepartmentsModule),
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/departments/create/create.module').then(m => m.CreateModule),
    //         },
    //         {
    //             path: 'view/:id',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/departments/edit/edit.module').then(m => m.EditModule),
    //         }
    //     ]
    // },
    // {
    //     path: 'merchandising/finished-good/category',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/category/category.module').then(m => m.CategoryModule)
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/category/create/create.module').then(m => m.CreateModule)
    //         },
    //         {
    //             path: ':view/:id',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/category/edit/edit.module').then(m => m.EditModule)
    //         }
    //     ]
    // },
    // {
    //     path: 'merchandising/finished-good/sub-category',
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ],
    //     data: {
    //         layout: 'app'
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/sub-category/sub-category.module').then(m => m.SubCategoryModule)
    //         },
    //         {
    //             path: 'create',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/sub-category/create/create.module').then(m => m.CreateModule)
    //         },
    //         {
    //             path: 'view/:id',
    //             loadChildren: () => import('./product-hierarchy/finished-goods/sub-category/edit/edit.module').then(m => m.EditModule)
    //         }
    //     ]
    // },
    {
        path: 'merchandising/finished-good/product-classes',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./product-hierarchy/finished-goods/product-classes/product-classes.module').then(m => m.ProductClassesModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./product-hierarchy/finished-goods/product-classes/create/create.module').then(m => m.CreateModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./product-hierarchy/finished-goods/product-classes/edit/edit.module').then(m => m.EditModule)
            }
        ]
    },
    {
        path: 'merchandising/finished-good/product-subclasses',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./product-hierarchy/finished-goods/product-subclasses/product-subclasses.module').then(m => m.ProductSubclassesModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./product-hierarchy/finished-goods/product-subclasses/create/create.module').then(m => m.CreateModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./product-hierarchy/finished-goods/product-subclasses/edit/edit.module').then(m => m.EditModule)
            }
        ]
    },
    {
        path: 'merchandising/finished-good/purchasing-items',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./product-hierarchy/finished-goods/purchasing-items/purchasing-items.module').then(m => m.PurchasingItemsModule)
            },
            {
                path: 'view/:id',
                loadChildren: () => import('./product-hierarchy/finished-goods/purchasing-items/show/show.module').then(m => m.ShowModule),
            }
        ]
    },
    // * End Finished Good Route

    /**
     * promotions route
     */

    // price changes
    {
        path: 'merchandising/promotions/price-changes',
        loadChildren: () => import('./promotions/price-changes/price-changes.module').then(m => m.PriceChangesModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/price-changes/create',
        loadChildren: () => import('./promotions/price-changes/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/price-changes/edit/:id',
        loadChildren: () => import('./promotions/price-changes/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/price-changes/batch-upload',
        loadChildren: () => import('./promotions/price-changes/batch-upload/batch-upload.module').then(m => m.BatchUploadModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // Mix and Match
    {
        path: 'merchandising/promotions/mix-and-match',
        loadChildren: () => import('./promotions/mix-and-match/mix-and-match.module').then(m => m.MixAndMatchModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/mix-and-match/create',
        loadChildren: () => import('./promotions/mix-and-match/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/mix-and-match/edit/:id',
        loadChildren: () => import('./promotions/mix-and-match/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/mix-and-match/batch-upload',
        loadChildren: () => import('./promotions/mix-and-match/batch-upload/batch-upload.module').then(m => m.BatchUploadModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // buy X get Y promotions
    {
        path: 'merchandising/promotions/buy-x-get-y-promotions',
        loadChildren: () => import('./promotions/buy-x-get-y-promotions/buy-x-get-y-promotions.module').then(m => m.BuyXGetYPromotionsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/buy-x-get-y-promotions/create',
        loadChildren: () => import('./promotions/buy-x-get-y-promotions/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/buy-x-get-y-promotions/edit/:id',
        loadChildren: () => import('./promotions/buy-x-get-y-promotions/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/buy-x-get-y-promotions/batch-upload',
        loadChildren: () => import('./promotions/buy-x-get-y-promotions/batch-upload/batch-upload.module').then(m => m.BatchUploadModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // buy X get Y percentages
    {
      path: 'merchandising/promotions/buy-x-get-y-percentages',
      loadChildren: () => import('./promotions/buy-x-get-y-percentages/buy-x-get-y-percentages.module').then(m => m.BuyXGetYPercentagesModule),
      data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
    },
    {
      path: 'merchandising/promotions/buy-x-get-y-percentages/create',
      loadChildren: () => import('./promotions/buy-x-get-y-percentages/create/create.module').then(m => m.CreateModule),
      data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
    },
    {
      path: 'merchandising/promotions/buy-x-get-y-percentages/edit/:id',
      loadChildren: () => import('./promotions/buy-x-get-y-percentages/edit/edit.module').then(m => m.EditModule),
      data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
    },

    {
      path: 'merchandising/promotions/buy-x-get-y-percentages/create-reward/:id',
      loadChildren: () => import('./promotions/buy-x-get-y-percentages/create-reward/create-reward.module').then(m => m.CreateRewardModule),
      data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
    },

    {
      path: 'merchandising/promotions/buy-x-get-y-percentages/batch-upload',
      loadChildren: () => import('./promotions/buy-x-get-y-percentages/batch-upload/batch-upload.module').then(m => m.BatchUploadModule),
      data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
  },

    // bundle promotions
    {
        path: 'merchandising/promotions/bundle-promotions',
        loadChildren: () => import('./promotions/bundle-promotions/bundle-promotions.module').then(m => m.BundlePromotionsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/bundle-promotions/create',
        loadChildren: () => import('./promotions/bundle-promotions/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/bundle-promotions/edit/:id',
        loadChildren: () => import('./promotions/bundle-promotions/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/bundle-promotions/batch-upload',
        loadChildren: () => import('./promotions/bundle-promotions/batch-upload/batch-upload.module').then(m => m.BatchUploadModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // bundle promotion products
    {
        path: 'merchandising/promotions/bundle-promotions/create-products/:id',
        loadChildren: () => import('./promotions/bundle-promotions/create-products/create-products.module').then(m => m.CreateProductsModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // Purchase with Purchase
    {
        path: 'merchandising/promotions/purchase-with-purchase',
        loadChildren: () => import('./promotions/purchase-with-purchase/purchase-with-purchase.module').then(m => m.PurchaseWithPurchaseModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/purchase-with-purchase/create',
        loadChildren: () => import('./promotions/purchase-with-purchase/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/purchase-with-purchase/edit/:id',
        loadChildren: () => import('./promotions/purchase-with-purchase/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/purchase-with-purchase/create-reward/:id',
        loadChildren: () => import('./promotions/purchase-with-purchase/create-reward/create-reward.module').then(m => m.CreateRewardModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/purchase-with-purchase/batch-upload',
        loadChildren: () => import('./promotions/purchase-with-purchase/batch-upload/batch-upload.module').then(m => m.BatchUploadModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // Multi Buy
    {
        path: 'merchandising/promotions/multi-buy',
        loadChildren: () => import('./promotions/multi-buy/multi-buy.module').then(m => m.MultiBuyModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/multi-buy/create',
        loadChildren: () => import('./promotions/multi-buy/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/multi-buy/edit/:id',
        loadChildren: () => import('./promotions/multi-buy/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/multi-buy/batch-upload',
        loadChildren: () => import('./promotions/multi-buy/batch-upload/batch-upload.module').then(m => m.BatchUploadModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    /**
     * @TODO Add External Voucher
     */
    {
        path: 'merchandising/promotions/external-voucher',
        loadChildren: () => import('./promotions/external-voucher/external-voucher.module').then(m => m.ExternalVoucherModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/external-voucher/create',
        loadChildren: () => import('./promotions/external-voucher/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/promotions/external-voucher/edit/:id',
        loadChildren: () => import('./promotions/external-voucher/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/product-hierarchy/category',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./category/category.module').then(m => m.CategoryModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./category/create/create.module').then(m => m.CreateModule)
            },
            {
                path: ':id/edit',
                loadChildren: () => import('./category/edit/edit.module').then(m => m.EditModule)
            }
        ]
    },
    {
        path: 'merchandising/product-hierarchy/sub-category',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./sub-category/sub-category.module').then(m => m.SubCategoryModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./sub-category/create/create.module').then(m => m.CreateModule)
            },
            {
                path: ':id/edit',
                loadChildren: () => import('./sub-category/edit/edit.module').then(m => m.EditModule)
            }
        ]
    },
    {
        path: 'merchandising/inventory',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule)
            },
            {
                path: 'history',
                loadChildren: () => import('./inventory/history/history.module').then(m => m.HistoryModule)
            },
            // {
            //     path: 'create',
            //     loadChildren: () => import('./inventory/create/create.module').then(m => m.CreateModule)
            // },
            // {
            //     path: ':id/edit',
            //     loadChildren: () => import('./inventory/edit/edit.module').then(m => m.EditModule)
            // },
        ]
    },
    {
        path: 'merchandising/disposal/disposal-reason',
        loadChildren: () => import('./disposal-reason/disposal-reason.module').then(m => m.DisposalReasonModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/disposal/disposals',
        loadChildren: () => import('./disposal/disposal.module').then(m => m.DisposalModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    /**
     * Start storage-locations route.
     * This module have moved to settings > Master Data.
     */
    {
        path: 'settings/storage-locations',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',

                loadChildren: () => import('./storage-locations/storage-locations.module').then(m => m.StorageLocationsModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./storage-locations/create/create.module').then(m => m.CreateModule)
            },
            {
                path: ':id/edit',
                loadChildren: () => import('./storage-locations/edit/edit.module').then(m => m.EditModule)
            },
        ]
    },

    {
        path: 'settings/purchasing-group',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',

                loadChildren: () => import('./purchasing-group/purchasing-group.module').then(m => m.PurchasingGroupModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./purchasing-group/create/create.module').then(m => m.CreateModule)
            },
            {
                path: ':id/edit',
                loadChildren: () => import('./purchasing-group/edit/edit.module').then(m => m.EditModule)
            },
        ]
    },

    {
        path: 'merchandising/stock-take',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./inventory/stock-take/stock-take.module').then(m => m.StockTakeModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./inventory/stock-take/create/create.module').then(m => m.CreateModule)
            },
            {
                path: ':id/edit',
                loadChildren: () => import('./inventory/stock-take/edit/edit.module').then(m => m.EditModule)
            },
        ]
    },
    {
        path: 'merchandising/stock-take-submissions',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./inventory/stock-take-submissions/stock-take-submissions.module').then(m => m.StockTakeSubmissionsModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./inventory/stock-take-submissions/create/create.module').then(m => m.CreateModule)
            },
            {
                path: 'import',
                loadChildren: () => import('./inventory/stock-take-submissions/import/import.module').then(m => m.ImportModule)
            },
            {
                path: ':id/show',
                loadChildren: () => import('./inventory/stock-take-submissions/show/show.module').then(m => m.ShowModule)
            },
        ]
    },
    {
        path: 'merchandising/stock-adjustments',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
          {
            path: '',
            loadChildren: () => import('./inventory/stock-adjustment/stock-adjustment.module').then(m => m.StockAdjustmentModule)
          },
          {
            path: 'create',
            loadChildren: () => import('./inventory/stock-adjustment/create/create.module').then(m => m.CreateModule)
          },
        ]
    },
    {
        path: 'settings/merchandising/stock-adjustments/stock-adjustment-reason',
        loadChildren: () => import('./stock-adjustment-reason/stock-adjustment-reason.module').then(m => m.StockAdjustmentReasonModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'merchandising/stock-transfer-storage',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./inventory/stock-transfer-storage/stock-transfer-storage.module').then(m => m.StockTransferStorageModule)
            },
            {
                path: ':id/show',
                loadChildren: () => import('./inventory/stock-transfer-storage/show/show.module').then(m => m.ShowModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./inventory/stock-transfer-storage/create/create.module').then(m => m.CreateModule)
            }
        ]
    },

    {
        path: 'merchandising/cycle-count',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./cycle-count/cycle-count.module').then(m => m.CycleCountModule)
            },
            {
                path: ':id/show',
                loadChildren: () => import('./cycle-count/show/show.module').then(m => m.ShowModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./cycle-count/create/create.module').then(m => m.CreateModule)
            },
        ]
    },
    {
      path: 'merchandising/stock-reserves',
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ],
      data: {
          layout: 'app'
      },
      children: [
        {
          path: '',
          loadChildren: () => import('./inventory/stock-reserve/stock-reserve.module').then(m => m.StockReserveModule)
        },
        {
          path: 'create',
          loadChildren: () => import('./inventory/stock-reserve/create/create.module').then(m => m.CreateModule)
        },
      ]
    },
    {
        path: 'merchandising/purchasing',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            // Purchasing children routes
            ...purchasingRoutes
        ]
    },

    // Planograms
    {
        path: 'merchandising/planograms',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./planograms/planograms.module').then(m => m.PlanogramsModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./planograms/create/create.module').then(m => m.CreateModule)
            },
            {
                path: ':id/edit',
                loadChildren: () => import('./planograms/edit/edit.module').then(m => m.EditModule)
            },
        ]
    },

    {
        path: 'merchandising/material-request/all-request',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./material-request/material-request.module').then(m => m.MaterialRequestModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./material-request/create/create.module').then(m => m.CreateModule)
            },
            {
                path: ':id/show',
                loadChildren: () => import('./material-request/show/show.module').then(m => m.ShowModule)
            },
        ]
    },

    {
        path: 'merchandising/material-request/my-request',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./material-request/my-request/my-request.module').then(m => m.MyRequestModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./material-request/my-request/create/create.module').then(m => m.CreateModule)
            },
            {
                path: ':id/show',
                loadChildren: () => import('./material-request/my-request/show/show.module').then(m => m.ShowModule)
            },
        ]
    },

    {
        path: 'merchandising/material-request/open-request',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./material-request/open-request/open-request.module').then(m => m.OpenRequestModule)
            },
            {
                path: ':id/show',
                loadChildren: () => import('./material-request/open-request/show/show.module').then(m => m.ShowModule)
            },
        ]
    },

    {
        path: 'merchandising/interlocation-transfer-item',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',

                loadChildren: () => import('./interlocation-transfer-item/interlocation-transfer-item.module').then(m => m.InterlocationTransferItemModule)
            },
            {
                path: 'create',
                loadChildren: () => import('./interlocation-transfer-item/create/create.module').then(m => m.CreateModule)
            },
            {
                path: ':id/show',
                loadChildren: () => import('./interlocation-transfer-item/show/show.module').then(m => m.ShowModule)
            },
            {
                path: 'convert-page',
                loadChildren: () => import('./interlocation-transfer-item/convert-page/convert-page.module').then(m => m.ConvertPageModule)
            },
        ]
    },
    {
        path: 'merchandising/receiving-stock-transfer',
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ],
        data: {
            layout: 'app'
        },
        children: [
            {
                path: '',

                loadChildren: () => import('./receiving-stock-transfer/receiving-stock-transfer.module').then(m => m.ReceivingStockTransferModule)
            },
            {
                path: ':id/show',
                loadChildren: () => import('./receiving-stock-transfer/show/show.module').then(m => m.ShowModule)
            },
        ]
    },
    {
        path: 'merchandising/mdz-brand-masters',
        loadChildren: () => import('./mdz-brand-masters/mdz-brand-masters.module').then(m => m.MdzBrandMastersModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/mdz-brand-masters/create',
        loadChildren: () => import('./mdz-brand-masters/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/mdz-brand-masters/edit/:id',
        loadChildren: () => import('./mdz-brand-masters/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/mdz-brand-category',
        loadChildren: () => import('./mdz-brand-category/mdz-brand-category.module').then(m => m.MdzBrandCategoryModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/mdz-brand-category/create',
        loadChildren: () => import('./mdz-brand-category/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/mdz-brand-category/edit/:id',
        loadChildren: () => import('./mdz-brand-category/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/mdz-assortment-masters',
        loadChildren: () => import('./mdz-assortment-masters/mdz-assortment-masters.module').then(m => m.MdzAssortmentMastersModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/mdz-assortment-masters/edit/:id',
        loadChildren: () => import('./mdz-assortment-masters/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/mdz-assortment-masters/create',
        loadChildren: () => import('./mdz-assortment-masters/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
      path: 'merchandising/mdz-assortment-masters/edit/:id/assortment-article/create',
      loadChildren: () => import('./mdz-assortment-masters/edit/components/articles/create/create.module').then(m => m.CreateModule),
      data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
    },
    {
      path: 'merchandising/mdz-assortment-masters/edit/:id/assortment-article/show/:articleId',
      loadChildren: () => import('./mdz-assortment-masters/edit/components/articles/edit/edit.module').then(m => m.EditModule),
      data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
    },
    {
        path: 'merchandising/product-hierarchy/mdz-articles/view/:id/sale-masters/create',
        loadChildren: () => import('./mdz-article-master/show/components/sale-masters/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/production-order',
        loadChildren: () => import('./inventory/production-order/production-order.module').then(m => m.ProductionOrderModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/production-order/create',
        loadChildren: () => import('./inventory/production-order/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/production-order/:production_order_id',
        loadChildren: () => import('./inventory/production-order/show/show.module').then(m => m.ShowModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    {
        path: 'merchandising/trading-terms/supplier-rebates',
        loadChildren: () => import('./trading-terms/supplier-rebates/supplier-rebates.module').then(m => m.SupplierRebatesModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/trading-terms/supplier-rebates/create',
        loadChildren: () => import('./trading-terms/supplier-rebates/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/trading-terms/supplier-rebates/show/:id',
        loadChildren: () => import('./trading-terms/supplier-rebates/show/show.module').then(m => m.ShowModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'merchandising/trading-terms/supplier-discounts',
        loadChildren: () => import('./trading-terms/supplier-discounts/supplier-discounts.module').then(m => m.SupplierDiscountsModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/trading-terms/supplier-discounts/create',
        loadChildren: () => import('./trading-terms/supplier-discounts/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },
    {
        path: 'merchandising/trading-terms/supplier-discounts/edit/:id',
        loadChildren: () => import('./trading-terms/supplier-discounts/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app',
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard
        ]
    },

    {
        path: 'merchandising/bom-recipes',
        loadChildren: () => import('./inventory/bom-recipes/bom-recipes.module').then(m => m.BomRecipesModule),
        data: {
          layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/bom-recipes/create',
        loadChildren: () => import('./inventory/bom-recipes/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/bom-recipes/show/:id',
        loadChildren: () => import('./inventory/bom-recipes/show/show.module').then(m => m.ShowModule),
        data: {
          layout: 'app'
      },
      canActivate: [
          IsLoggedInGuard,
          SetupCompletedGuard,
          PasswordChangedGuard,
      ]
    },
    // * Start PA Zero
    {
      path: 'merchandising/pa-zeros',
      loadChildren: () => import('./inventory/pa-zeros/pa-zeros.module').then(m => m.PaZerosModule),
      data: {
        layout: 'app'
      },
      canActivate: [
        IsLoggedInGuard,
        SetupCompletedGuard,
        PasswordChangedGuard,
      ],
    },
    // * End PA Zero
    // * Start Label Printing
    {
        path: 'merchandising/label-printing',
        loadChildren: () => import('./label-printing/label-printing.module').then(m => m.LabelPrintingModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    {
        path: 'merchandising/label-printing/print/:id',
        loadChildren: () => import('./label-printing/print/print.module').then(m => m.PrintModule),
        data: {
            layout: 'export'
        }
    },
    {
        path: 'merchandising/label-printing/label-m-size-print/:id',
        loadChildren: () => import('./label-printing/label-m-size-print/label-m-size-print.module').then(m => m.LabelMSizePrintModule),
        data: {
            layout: 'export'
        }
    },
    {
        path: 'merchandising/weight-scale',
        loadChildren: () => import('./weight-scale/weight-scale.module').then(m => m.WeightScaleModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
    // * End Label Printing
    // {
    //     path: 'merchandising/bom-recipes/conversion',
    //     loadChildren: () => import('./inventory/bill-of-material/create/conversion/conversion.module').then(m => m.ConversionModule),
    //     data: {
    //         layout: 'app'
    //     },
    //     canActivate: [
    //         IsLoggedInGuard,
    //         SetupCompletedGuard,
    //         PasswordChangedGuard,
    //     ]
    // },
    // {
    //     path: 'merchandising/bom-recipes/show/:id',
    //     loadChildren: () => import('./inventory/bill-of-material/show/show.module').then(m => m.ShowModule),
    //     data: {
    //       layout: 'app'
    //   },
    //   canActivate: [
    //       IsLoggedInGuard,
    //       SetupCompletedGuard,
    //       PasswordChangedGuard,
    //   ]
    // },
    // * Start Product Specification
    {
        path: 'merchandising/merchandising-forms/product-specification',
        loadChildren: () => import('./merchandising-forms/product-specification/product-specification.module').then(m => m.ProductSpecificationModule),
        data: {
            layout: 'app'
        }
    },
    {
        path: 'merchandising/merchandising-forms/product-specification/create',
        loadChildren: () => import('./merchandising-forms/product-specification/create/create.module').then(m => m.CreateModule),
        data: {
            layout: 'app'
        }
    },
    {
        path: 'merchandising/merchandising-forms/product-specification/edit/:id',
        loadChildren: () => import('./merchandising-forms/product-specification/edit/edit.module').then(m => m.EditModule),
        data: {
            layout: 'app'
        }
    },
    // * End Product Specification
];
