import { Routes } from "@angular/router";
import { IsLoggedInGuard } from "src/app/guards/is-logged-in.guard";
import { SetupCompletedGuard } from "src/app/guards/setup-completed.guard";
import { PasswordChangedGuard } from 'src/app/guards/password-changed.guard';

export const routes: Routes = [

    // sales report
    {
        path: 'pos/sales-receipt-reports',
        loadChildren: () => import('./general-sales-report/general-sales-report.module').then(m => m.GeneralSalesReportModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // sales by sku
    {
        path: 'pos/sales-report-by-sku',
        loadChildren: () => import('./sales-report-by-sku/sales-report-by-sku.module').then(m => m.SalesReportBySkuModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // sales by division
    {
        path: 'pos/sales-report-by-division',
        loadChildren: () => import('./sales-report-by-division/sales-report-by-division.module').then(m => m.SalesReportByDivisionModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // Sales by Department
    {
        path: 'pos/sales-report-by-department',
        loadChildren: () => import('./sales-report-by-department/sales-report-by-department.module').then(m => m.SalesReportByDepartmentModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // sales by category
    {
        path: 'pos/sales-report-by-category',
        loadChildren: () => import('./sales-report-by-category/sales-report-by-category.module').then(m => m.SalesReportByCategoryModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },

    // sales by consignment sku
    {
        path: 'pos/sales-report-by-consignment-sku',
        loadChildren: () => import('./sales-report-by-consignment-sku/sales-report-by-consignment-sku.module').then(m => m.SalesReportByConsignmentSkuModule),
        data: {
            layout: 'app'
        },
        canActivate: [
            IsLoggedInGuard,
            SetupCompletedGuard,
            PasswordChangedGuard,
        ]
    },
];
